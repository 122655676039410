import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as BWBlueIconWithWhiteText } from "../../global-assets/logos/BWBlueLogoWithWhiteText.svg";

const BWBlueLogoWithWhiteText = (props: SvgIconProps) => {
  return (
    <SvgIcon
      component={BWBlueIconWithWhiteText}
      {...props}
      viewBox="0 0 230 50"
    />
  );
};

export default BWBlueLogoWithWhiteText;
