import axios, { AxiosResponse } from "axios";

import baseUrl from "../../../app.config";
import { getUserToken } from "../../../user-application/authentication/helpers";

export const login = async (requestBody: {
  email: string;
  password: string;
}): Promise<AxiosResponse<any>> => {
  return axios.post(`${baseUrl.server}/api/application/login`, requestBody);
};

export const forgotPassword = async (
  email: string
): Promise<AxiosResponse<any>> => {
  return axios.post(`${baseUrl.server}/api/application/forgotPassword`, {
    email,
  });
};

export const resetPassword = async (token: string, password: string) => {
  return axios.patch(
    `${baseUrl.server}/api/application/resetPassword/${token}`,
    { password },
    { headers: { Authorization: `Bearer ${getUserToken()}` } }
  );
};
