import { SvgIcon, SvgIconProps } from "@mui/material";

const ArrowRightIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 9 7">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.2054 0.239551L8.76989 2.92252C8.9233 3.07072 9 3.27259 9 3.49489C9 3.71719 8.9233 3.9165 8.76989 4.06726L6.2054 6.76045C5.90114 7.07985 5.40511 7.07985 5.10085 6.76045C4.79659 6.44105 4.79659 5.92489 5.10085 5.60549L6.33324 4.31767H0.784943C0.350284 4.31767 0 3.95227 0 3.5C0 3.04773 0.350284 2.68233 0.782386 2.68233H6.33068L5.0983 1.39451C4.79403 1.0751 4.79403 0.558952 5.0983 0.239551C5.40511 -0.0798503 5.89858 -0.0798503 6.2054 0.239551V0.239551Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
};

export default ArrowRightIcon;
