import { Box, useTheme, useMediaQuery, Theme } from "@mui/material";

import BackgroundSmall from "../assets/footer/background-sm.svg";
import BackgroundMedium from "../assets/footer/background-md.svg";
import BackgroundLarge from "../assets/footer/background-lg.svg";

const Footer = () => {
  const theme: Theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const render = (
    <Box
      sx={{
        backgroundColor: "#EBF3FF",
        ...(isSmallScreen && {
          backgroundImage: `url(${BackgroundSmall})`,
          height: "8.0625rem",
        }),
        ...(isMediumScreen && {
          backgroundImage: `url(${BackgroundMedium})`,
          height: "9.0625rem",
        }),
        ...(isLargeScreen && {
          backgroundImage: `url(${BackgroundLarge})`,
          height: "12.0625rem",
        }),
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        mt: isSmallScreen || isLargeScreen ? 4 : 6,
      }}
    ></Box>
  );

  return render;
};

export default Footer;
