import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { add, isValid, parseISO, startOfDay } from "date-fns";

import handleError from "../../../global-helpers/handleError";
import { useAppDispatch } from "../../../global-store/hooks";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { showNotification } from "../../../global-store/notificationSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CurrencyInput from "../../../global-components/inputs/CurrencyInput";
import {
  getTenantSAASFee,
} from "../../../platform-dashboard/tenants/api";
import { getProfileById, updateProfile } from "../api";
import { Profile } from "../../../../../server/src/admin/profile/entities/profile.entity";

interface BillingSettingsProps {
  platformId?: string;
  profileId?: string;
  onGoBack?: () => void;
  onNext?: (billingOptions: any) => void;
}

const ProfileSaasFeeConfiguration = ({
  profileId,
  platformId,
  onGoBack,
  onNext,
}: BillingSettingsProps) => {
  const [tenantSaasFe, setTenantSaasFee] = useState<any>({});
  const [profileData, setProfileData] = useState<Partial<Profile>>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loadState = async () => {
    if (platformId) {
      const { data } = await getTenantSAASFee(platformId);
      setTenantSaasFee(data)

      const saasSettings = data.items.find((saas) => !saas.merchant);

      // setSaasFeeSettings(saasSettings)

      if (saasSettings) {
        const { amount, enabled } = saasSettings;
        let nextPaymentDate: Date | null = saasSettings.nextPaymentDate;
        let minDate: Date = startOfDay(new Date());
        if (saasSettings.advancePaymentBalance > 0) {
          const numberOfPaidMonths = Math.abs(
            saasSettings.advancePaymentBalance / saasSettings.amount
          );
          nextPaymentDate = startOfDay(
            add(parseISO(saasSettings.nextDebitDate as unknown as string), {
              months: numberOfPaidMonths,
            })
          );
          minDate = nextPaymentDate;
        }

        await formik.setValues(
          {
            amount,
            enabled,
            nextPaymentDate,
            minDate,
            maxDate: add(minDate, { years: 1 }),
            saasSharePercentage: 0,
          },
          false
        );
      }

      return 
    } 
    if (profileId) {
      const { data } = await getProfileById(profileId);
      setProfileData(data.profile);

      if (data.profile.profileSaasFees) {
        await formik.setValues(
          {
            amount: data.profile.profileSaasFees.amount,
            enabled: data.profile.profileSaasFees.active,
            nextPaymentDate: data.profile.profileSaasFees.nextPaymentDate,
            minDate: new Date(),
            maxDate: add(new Date(), { years: 1 }),
            saasSharePercentage: +data.profile.profileSaasFees.sharePercentage,
          },
          false
        );
      }
    }
  };

  const onPaymentDateChange = async (newPaymentDate: Date | null) => {
    if (newPaymentDate && isValid(newPaymentDate)) {
      try {
        dispatch(setIsLoading(true));
        await formik.setFieldTouched("nextPaymentDate", true);
        await formik.setFieldValue("nextPaymentDate", newPaymentDate, true);

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    } else {
      await formik.setFieldTouched("paymentDate", true);
      formik.setFieldError("paymentDate", "Invalid Date");
    }
  };

  const formik = useFormik({
    initialValues: {
      amount: 0,
      enabled: false,
      nextPaymentDate: add(new Date(), { months: 1 }),
      minDate: new Date() as Date,
      maxDate: new Date() as Date,
      saasSharePercentage: 0,
    },
    validationSchema: yup.object({
      enabled: yup.boolean(),
    }),
    onSubmit: async (values) => {
      try {
        if (onNext) {
          onNext({
            enabled: values.enabled,
            amount: +values.amount,
            nextPaymentDate: values.nextPaymentDate,
            sharePercentage: values.saasSharePercentage,
          });
          return;
        }
        dispatch(setIsLoading(true));
        const requestBody = {
          ...profileData.profileSaasFees,
          active: values.enabled,
          amount: +values.amount,
          nextPaymentDate: values.nextPaymentDate,
          sharePercentage: values.saasSharePercentage,
        };
        await updateProfile(profileId, {
          saasFee: requestBody,
        })
        dispatch(setIsLoading(false));
        await loadState();
        dispatch(
          showNotification({
            message: "SAAS fee settings successfully updated",
            severity: "success",
            shouldNotify: true,
          })
        );
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));

        await loadState();

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const render = (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
              <strong>SAAS Fee Settings</strong>
            </Typography>

            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ width: "50%" }}>
                      <strong>Recurring</strong>
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        label={formik.values.enabled ? "On" : "Off"}
                        control={
                          <Switch
                            name="enabled"
                            onChange={formik.handleChange}
                            checked={formik.values.enabled}
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Amount</strong>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="amount"
                        value={formik.values.amount}
                        disabled={!formik.values.enabled}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.amount && Boolean(formik.errors.amount)
                        }
                        helperText={
                          formik.touched.amount && formik.errors.amount
                        }
                        InputProps={{
                          inputComponent: CurrencyInput as any,
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        fullWidth
                        required
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Next Payment Date</strong>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth required>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={formik.values.nextPaymentDate}
                            minDate={formik.values.minDate}
                            maxDate={formik.values.maxDate}
                            disabled={!formik.values.enabled}
                            onChange={onPaymentDateChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="nextPaymentDate"
                                error={
                                  formik.touched.nextPaymentDate &&
                                  Boolean(formik.errors.nextPaymentDate)
                                }
                                helperText={
                                  formik.touched.nextPaymentDate &&
                                  formik.errors.nextPaymentDate
                                }
                                onBlur={formik.handleBlur}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ width: "50%" }}>
                      <strong>Share Percentage</strong>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          name="saasSharePercentage"
                          value={formik.values.saasSharePercentage}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem value={0}>0%</MenuItem>
                          <MenuItem value={50}>50%</MenuItem>
                          <MenuItem value={100}>100%</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}
            >
              {onGoBack && (
                <Button
                  variant="outlined"
                  onClick={onGoBack}
                  sx={{ mr: 4, minWidth: "9.82125rem" }}
                >
                  Go Back
                </Button>
              )}
              <Button
                type="submit"
                variant="contained"
                disabled={formik.isSubmitting}
                sx={{ minWidth: "9.82125rem" }}
              >
                {profileId ? "Update" : "Create"}
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );

  return render;
};

export default ProfileSaasFeeConfiguration;
