import { configureStore } from "@reduxjs/toolkit";

import userLayoutReducer from "../user-application/layout/store/layoutSlice";
import merchantOnboardingLayoutReducer from "../merchant-onboarding/layout/store/layoutSlice";
import notificationReducer from "./notificationSlice";
import loadingReducer from "./loadingSlice";
import tokenReducer from "./tokenSlice";

const store = configureStore({
  reducer: {
    userLayout: userLayoutReducer,
    merchantOnboardingLayout: merchantOnboardingLayoutReducer,
    notification: notificationReducer,
    loading: loadingReducer,
    token: tokenReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
