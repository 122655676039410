import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import UserApplicationLayout from "./user-application/layout/components/Layout";
import MerchantOnboardingLayout from "./merchant-onboarding/layout/pages/Layout";
import UserLogin from "./user-application/authentication/pages/Login";
import RequireUserAuthentication from "./user-application/authentication/components/RequireAuthentication";
import RequireAdminAuthentication from "./admin-dashboard/authentication/components/RequireAdminAuthentication";
import RequireSuperAdminAuthentication from "./admin-dashboard/authentication/components/RequireSuperAdminAuthentication";
import RequireMerchantAuthentication from "./admin-dashboard/authentication/components/RequireMerchantAuthentication";
import RequireSuperAdminOrMerchantAuthentication from "./admin-dashboard/authentication/components/RequireSuperAdminOrMerchantAuthentication";
import RequirePlatformAdminAuthentication from "./platform-dashboard/authentication/components/RequirePlatformAdminAuthentication";
import PrintQRCode from "./merchant-onboarding/congratulations/components/PrintQRCode";
import SaveQRCode from "./merchant-onboarding/congratulations/components/SaveQRCode";
import ManageProfile from "./admin-dashboard/profile-management/pages/ManageProfile";
import AddProfile from "./admin-dashboard/profile-management/pages/AddProfile";
import EditProfile from "./admin-dashboard/profile-management/pages/EditProfile";
import ManageProducts from "./admin-dashboard/products-management/pages/ManageProducts";
import StandBy from "./user-application/application/components/StandBy";

// common
const ChangePassword = React.lazy(
  () => import("./global-components/pages/change-password/ChangePassword")
);

// merchant onboarding
const SignUp = React.lazy(
  () => import("./merchant-onboarding/sign-up/pages/SignUp")
);
const Enrollment = React.lazy(
  () => import("./merchant-onboarding/enrollment/pages/Enrollment")
);

// user application
const Apply = React.lazy(
  () => import("./user-application/application/pages/ApplyWrapper")
);
const HomeLandingPage = React.lazy(
  () => import("./user-application/landing-page/pages/Home")
);
const LearnMore = React.lazy(
  () => import("./user-application/landing-page/pages/LearnMore")
);
const OpenBanking = React.lazy(
  () => import("./user-application/offers/pages/OpenBanking").then(module => ({ default: module.OpenBanking }))
);
const Offers = React.lazy(
  () => import("./user-application/offers/pages/Offers")
);
const PendingReview = React.lazy(
  () => import("./user-application/offers/pages/Pending-Review")
);
const Denied = React.lazy(
  () => import("./user-application/offers/pages/Denied")
);
const OfferReview = React.lazy(
  () => import("./user-application/offers/pages/ReviewOffer")
);
const SignContract = React.lazy(
  () => import("./user-application/sign-contract/pages/Contract")
);
const ForgotUserPassword = React.lazy(
  () => import("./user-application/authentication/pages/ForgotPassword")
);
const Repayment = React.lazy(
  () => import("./user-application/repayment/pages/Repayment")
);
const CloseLoan = React.lazy(
  () => import("./user-application/application/pages/CloseLoanConfirmation")
);

// user dashboard
const UserDashBoardLayout = React.lazy(
  () => import("./user-application/dashboard/layout/UserDashboardLayout")
);
const Payments = React.lazy(
  () => import("./user-application/dashboard/pages/Payments")
);
const UserInfo = React.lazy(
  () => import("./user-application/dashboard/pages/UserInfo")
);

// admin dashboard
const AdminLogin = React.lazy(
  () => import("./admin-dashboard/authentication/pages/Login")
);
const ResetAdminPassword = React.lazy(
  () => import("./global-components/pages/reset-password/ResetPassword")
);
const AdminDashboard = React.lazy(
  () => import("./admin-dashboard/layout/pages")
);
const AdminDashboardHome = React.lazy(
  () => import("./admin-dashboard/home/pages/Home")
);
const CreateApplication = React.lazy(
  () => import("./admin-dashboard/application/pages/CreateApplication")
);
const Opportunities = React.lazy(
  () => import("./admin-dashboard/opportunities/pages/Opportunities")
);
const Collections = React.lazy(
  () => import("./admin-dashboard/collections/pages/Collections")
);
const LendingCenter = React.lazy(
  () => import("./admin-dashboard/lending-center/pages/LendingCenter")
);
const ManageApplicants = React.lazy(
  () => import("./admin-dashboard/applicants/pages/ManageApplicants")
);
const ManageMerchants = React.lazy(
  () => import("./admin-dashboard/merchants/pages/ManageMerchants")
);
const AuditLogs = React.lazy(
  () => import("./admin-dashboard/audit-log/pages/AuditLog")
);
const Terms = React.lazy(
  () => import("./admin-dashboard/merchants/pages/EditTerms")
);
const Reports = React.lazy(
  () => import("./admin-dashboard/reports/pages/Reports")
);
const Documents = React.lazy(
  () => import("./admin-dashboard/documents/pages/ManageDocuments")
);
const AddDocument = React.lazy(
  () => import("./admin-dashboard/documents/pages/AddDocument")
);
const AddConsent = React.lazy(
  () => import("./admin-dashboard/documents/pages/AddConsent")
);
const LogDetail = React.lazy(
  () => import("./admin-dashboard/audit-log/pages/LogDetail")
);
const LoanSettings = React.lazy(
  () => import("./admin-dashboard/settings/pages/Settings")
);
const LoanDetails = React.lazy(
  () => import("./admin-dashboard/user-details/pages/UserDetails")
);
const AddSuperAdmin = React.lazy(
  () => import("./global-components/pages/add-super-admin/AddSuperAdmin")
);
const AddMerchantStaff = React.lazy(
  () => import("./admin-dashboard/merchants/pages/AddMerchantStaff")
);
const AddMerchantUser = React.lazy(
  () => import("./admin-dashboard/settings/pages/AddMerchantUser")
);
const AddMerchant = React.lazy(
  () => import("./admin-dashboard/merchants/pages/AddMerchant")
);
const EditMerchant = React.lazy(
  () => import("./admin-dashboard/merchants/pages/EditMerchant")
);

// platform dashboard
const PlatformLogin = React.lazy(
  () => import("./platform-dashboard/authentication/pages/Login")
);
const ResetPlatformAdminPassword = React.lazy(
  () => import("./global-components/pages/reset-password/ResetPassword")
);
const PlatformDashboard = React.lazy(
  () => import("./platform-dashboard/layout/pages/PlatformDashboardLayout")
);
const ManageTenants = React.lazy(
  () => import("./platform-dashboard/tenants/pages/ManageTenants")
);
const AddTenant = React.lazy(
  () => import("./platform-dashboard/tenants/pages/AddTenant")
);
const AddSuperAdminToTenant = React.lazy(
  () => import("./global-components/pages/add-super-admin/AddSuperAdmin")
);
const EditTenant = React.lazy(
  () => import("./platform-dashboard/tenants/pages/EditTenant")
);

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to="/business-warrior/merchant-onboarding/sign-up"
              replace={true}
            />
          }
        />

        <Route
          path="/login"
          element={<Navigate to="/business-warrior/login" replace={true} />}
        />

        <Route
          path="/admin/login"
          element={
            <Navigate to="/business-warrior/admin/login" replace={true} />
          }
        />

        <Route
          path=":platformUrl/:merchantUrl"
          element={
            <React.Suspense fallback={<></>}>
              <HomeLandingPage />
            </React.Suspense>
          }
        />

       
        <Route element={<UserApplicationLayout />}>
          <Route
            path=":platformUrl/:merchantUrl/apply"
            element={
              <React.Suspense fallback={<></>}>
                <Apply />
              </React.Suspense>
            }
          />
          <Route
            path=":platformUrl/:merchantUrl/apply/link/:linkId"
            element={
              <React.Suspense fallback={<></>}>
                <Apply />
              </React.Suspense>
            }
          />
          <Route
            path=":platformUrl/:merchantUrl/open-banking"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <OpenBanking />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path=":platformUrl/:merchantUrl/offers"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <Offers />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
           <Route
            path=":platformUrl/:merchantUrl/stand-by"
            element={
              <React.Suspense fallback={<></>}>
                <StandBy />
              </React.Suspense>
            }
          />
          <Route
            path=":platformUrl/:merchantUrl/pending-review"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <PendingReview />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path=":platformUrl/:merchantUrl/denied"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <Denied />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path=":platformUrl/:merchantUrl/review-offer"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <OfferReview />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path=":platformUrl/:merchantUrl/sign-contract"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <SignContract />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path=":platformUrl/:merchantUrl/repayment"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <Repayment />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path=":platformUrl/forgot-password"
            element={
              <React.Suspense fallback={<></>}>
                <ForgotUserPassword />
              </React.Suspense>
            }
          />
          <Route
            path="close-loan/:token"
            element={
              <React.Suspense fallback={<></>}>
                <CloseLoan />
              </React.Suspense>
            }
          />
          <Route path=":platformUrl/login" element={<UserLogin />} />
        </Route>

        <Route
          path="/:platformUrl/learn-more"
          element={
            <React.Suspense fallback={<></>}>
              <LearnMore />
            </React.Suspense>
          }
        />

        <Route
          path="/dashboard"
          element={
            <RequireUserAuthentication>
              <React.Suspense fallback={<></>}>
                <UserDashBoardLayout />
              </React.Suspense>
            </RequireUserAuthentication>
          }
        >
          <Route
            index
            element={
              <RequireUserAuthentication>
                <Navigate to="/dashboard/payments" replace={true} />
              </RequireUserAuthentication>
            }
          />
          <Route
            path="payments"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <Payments />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="user-info"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <UserInfo />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
          <Route
            path="change-password"
            element={
              <RequireUserAuthentication>
                <React.Suspense fallback={<></>}>
                  <ChangePassword />
                </React.Suspense>
              </RequireUserAuthentication>
            }
          />
        </Route>

        <Route
          path=":platformUrl/merchant-onboarding"
          element={<MerchantOnboardingLayout />}
        >
          <Route
            index
            element={
              <Navigate
                to="/:platformUrl/merchant/onboarding/sign-up"
                replace={true}
              />
            }
          />
          <Route
            path="sign-up"
            element={
              <React.Suspense fallback={<></>}>
                <SignUp />
              </React.Suspense>
            }
          />
          <Route
            path="enrollment"
            element={
              <React.Suspense fallback={<></>}>
                <Enrollment />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path="/platform/login"
          element={
            <React.Suspense fallback={<></>}>
              <PlatformLogin />
            </React.Suspense>
          }
        />

        <Route
          path="/platform/dashboard"
          element={
            <RequirePlatformAdminAuthentication>
              <React.Suspense fallback={<></>}>
                <PlatformDashboard />
              </React.Suspense>
            </RequirePlatformAdminAuthentication>
          }
        >
          <Route
            index
            element={
              <RequirePlatformAdminAuthentication>
                <Navigate
                  to="/platform/dashboard/manage-tenants"
                  replace={true}
                />
              </RequirePlatformAdminAuthentication>
            }
          />
          <Route
            path="/platform/dashboard/audit-log"
            element={
              <RequirePlatformAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AuditLogs />
                </React.Suspense>
              </RequirePlatformAdminAuthentication>
            }
          />
          <Route
            path="/platform/dashboard/view-log-details/:id"
            element={
              <RequirePlatformAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <LogDetail />
                </React.Suspense>
              </RequirePlatformAdminAuthentication>
            }
          />
          <Route
            path="change-password"
            element={
              <RequirePlatformAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <ChangePassword />
                </React.Suspense>
              </RequirePlatformAdminAuthentication>
            }
          />
          <Route
            path="manage-tenants"
            element={
              <RequirePlatformAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <ManageTenants />
                </React.Suspense>
              </RequirePlatformAdminAuthentication>
            }
          />
          <Route
            path="add-tenant"
            element={
              <RequirePlatformAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddTenant />
                </React.Suspense>
              </RequirePlatformAdminAuthentication>
            }
          />
          <Route
            path="add-super-admin/:platformId"
            element={
              <RequirePlatformAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddSuperAdminToTenant />
                </React.Suspense>
              </RequirePlatformAdminAuthentication>
            }
          />
          <Route
            path="edit-tenant/:id"
            element={
              <RequirePlatformAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <EditTenant />
                </React.Suspense>
              </RequirePlatformAdminAuthentication>
            }
          />
        </Route>

        <Route
          path="/admin/dashboard"
          element={
            <RequireAdminAuthentication>
              <React.Suspense fallback={<></>}>
                <AdminDashboard />
              </React.Suspense>
            </RequireAdminAuthentication>
          }
        >
          <Route
            index
            element={
              <RequireAdminAuthentication>
                <Navigate to="/admin/dashboard/home" replace={true} />
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="home"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AdminDashboardHome />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="create-application"
            element={
              <RequireSuperAdminOrMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <CreateApplication />
                </React.Suspense>
              </RequireSuperAdminOrMerchantAuthentication>
            }
          />
          <Route
            path="change-password"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <ChangePassword />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="opportunities"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <Opportunities />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="collections"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <Collections />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="lending-center"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <LendingCenter />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="manage-users"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <ManageApplicants />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="manage-merchant"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <ManageMerchants />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="manage-products"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <ManageProducts />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="manage-profile"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <ManageProfile />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="add-profile"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddProfile />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="edit-profile/:id"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <EditProfile />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />

          <Route
            path="audit-log"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AuditLogs />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="terms"
            element={
              <RequireMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <Terms />
                </React.Suspense>
              </RequireMerchantAuthentication>
            }
          />
          <Route
            path="reports"
            element={
              <RequireSuperAdminOrMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <Reports />
                </React.Suspense>
              </RequireSuperAdminOrMerchantAuthentication>
            }
          />
          <Route
            path="view-log-details/:id"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <LogDetail />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="documents"
            element={
              <RequireSuperAdminOrMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <Documents />
                </React.Suspense>
              </RequireSuperAdminOrMerchantAuthentication>
            }
          />
          <Route
            path="documents/add-document"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddDocument />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="documents/add-document/:documentId"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddDocument />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="documents/add-consent"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddConsent />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="documents/add-consent/:documentId"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddConsent />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="settings"
            element={
              <RequireSuperAdminOrMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <LoanSettings />
                </React.Suspense>
              </RequireSuperAdminOrMerchantAuthentication>
            }
          />
          <Route
            path="loan-details/:screenTrackingId"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <LoanDetails />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="add-super-admin"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddSuperAdmin />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="edit-super-admin/:id"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddSuperAdmin />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="edit-merchant-user/:merchantId"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddMerchantStaff />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="edit-manager-staff/:id"
            element={
              <RequireSuperAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddMerchantStaff />
                </React.Suspense>
              </RequireSuperAdminAuthentication>
            }
          />
          <Route
            path="add-merchant"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddMerchant />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="edit-merchant/:id"
            element={
              <RequireAdminAuthentication>
                <React.Suspense fallback={<></>}>
                  <EditMerchant />
                </React.Suspense>
              </RequireAdminAuthentication>
            }
          />
          <Route
            path="merchant/add-user"
            element={
              <RequireMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddMerchantUser />
                </React.Suspense>
              </RequireMerchantAuthentication>
            }
          />
          <Route
            path="merchant/edit-user/:id"
            element={
              <RequireMerchantAuthentication>
                <React.Suspense fallback={<></>}>
                  <AddMerchantUser />
                </React.Suspense>
              </RequireMerchantAuthentication>
            }
          />
        </Route>

        <Route
          path="/:platformUrl/admin/login"
          element={
            <React.Suspense fallback={<></>}>
              <AdminLogin source="admin" />
            </React.Suspense>
          }
        />

        <Route
          path="/:platformUrl/merchant/login"
          element={
            <React.Suspense fallback={<></>}>
              <AdminLogin source="merchant" />
            </React.Suspense>
          }
        />

        <Route
          path="/platform/reset-password"
          element={
            <React.Suspense fallback={<></>}>
              <ResetPlatformAdminPassword source="platform" />
            </React.Suspense>
          }
        />

        <Route
          path="/:platformUrl/admin/reset-password"
          element={
            <React.Suspense fallback={<></>}>
              <ResetAdminPassword source="admin" />
            </React.Suspense>
          }
        />

        <Route
          path="/:platformUrl/merchant/reset-password"
          element={
            <React.Suspense fallback={<></>}>
              <ResetAdminPassword source="merchant" />
            </React.Suspense>
          }
        />

        <Route
          path="/reset-password/:token"
          element={
            <RequireUserAuthentication>
              <React.Suspense fallback={<></>}>
                <ChangePassword />
              </React.Suspense>
            </RequireUserAuthentication>
          }
        />

        <Route
          path="/merchant/print-qr-code/:platformUrl/:merchantUrl"
          element={<PrintQRCode />}
        />

        <Route
          path="/merchant/save-qr-code/:platformUrl/:merchantUrl"
          element={<SaveQRCode />}
        />

        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
