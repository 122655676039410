import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { getProductById, updateAdvancePaymentType } from "../api";
import * as yup from "yup";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { showNotification } from "../../../global-store/notificationSlice";
import handleError from "../../../global-helpers/handleError";
import { useAppDispatch } from "../../../global-store/hooks";
import { useNavigate } from "react-router-dom";

export enum AdvancePaymentType {
  AdvanceScheduledPayment = "Advance Scheduled Payment",
  PrepaymentOfPrincipalAmount = "Prepayment Of Principal Amount",
}

interface ProfileIdentityKycProps {
  productId?: string;
  onGoBack?: () => void;
}

const PaymentSettings = ({ productId, onGoBack }: ProfileIdentityKycProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loadState = async () => {
    if (productId) {
      const { data } = await getProductById(productId);

      await formik.setValues(
        {
          advancePaymentType: data.product.advancePaymentType,
        },
        true
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      advancePaymentType: AdvancePaymentType.PrepaymentOfPrincipalAmount,
    },
    validationSchema: yup.object({
      advancePaymentType: yup
        .mixed<AdvancePaymentType>()
        .oneOf(Object.values(AdvancePaymentType))
        .required(),
    }),
    onSubmit: async ({ advancePaymentType }) => {
      try {
        dispatch(setIsLoading(true));

        await updateAdvancePaymentType(productId, advancePaymentType);

        dispatch(
          showNotification({
            message: "Advance payment type updated successfully",
            severity: "success",
            shouldNotify: true,
          })
        );
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }
      } finally {
        dispatch(setIsLoading(false));
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));
        await loadState();
        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }
        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const render = (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel id="advance-payment-type">
              Advance Payment Type
            </InputLabel>
            <Select
              labelId="advance-payment-type"
              label="Advance Payment Type"
              name="advancePaymentType"
              value={formik.values.advancePaymentType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.advancePaymentType &&
                Boolean(formik.errors.advancePaymentType)
              }
            >
              {Object.keys(AdvancePaymentType).map((paymentType) => {
                return (
                  <MenuItem
                    value={AdvancePaymentType[paymentType]}
                    key={paymentType}
                  >
                    {AdvancePaymentType[paymentType]}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText sx={{ color: "#d32f2f" }}>
              {formik.touched.advancePaymentType &&
                formik.errors.advancePaymentType}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => onGoBack()}
            sx={{ mr: 4, minWidth: "9.82125rem" }}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting}
            sx={{ minWidth: "9.82125rem" }}
          >
            Update
          </Button>
        </Box>
      </Grid>
    </form>
  );
  return render;
};

export default PaymentSettings;
