/**
 * Steps to add new data vendor described in server/src/platform/data-vendors/entities/data-vendors.entity.ts.
 */

export enum DataVendorNames {
  Instnt = "Instnt",
  GoogleTagManager = "GoogleTagManager",
  Plaid = "Plaid",
  Finicity = "Finicity",
  Experian = "Experian",
}
