import { TagManagerArgs } from "react-gtm-module";

export interface IGtmData {
  gtm: boolean;
  config: TagManagerArgs;
}

const handleGtmConfig = (data: any): IGtmData => {
  const environment = process.env.REACT_APP_NODE_ENV;
  let gtm = false;
  const config: TagManagerArgs = { gtmId: "" };

  if (data?.enabled) {
    gtm = true;

    const { value } = data;
    config.gtmId = value?.gtmId;

    if (environment !== "production") {
      switch (environment) {
        case "staging":
        case "demo":
          config.auth = value[environment].auth;
          config.preview = value[environment].preview;
          break;
        default:
          config.auth = value.dev.auth;
          config.preview = value.dev.preview;
          break;
      }
    }
  }

  return { gtm, config };
};

export default handleGtmConfig;
