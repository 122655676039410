import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Fade,
  Modal,
  Backdrop,
  TextField,
  Slider,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
  Grid,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import * as yup from "yup";

import imageTemplate from "../../assets/image-template.svg";
import AdvertisingIcon from "../../assets/AdvertisingIcon";
import modalStyles from "../../../../global-helpers/modalStyles";
import handleError from "../../../../global-helpers/handleError";
import { useAppDispatch } from "../../../../global-store/hooks";
import { showNotification } from "../../../../global-store/notificationSlice";
import { setIsLoading } from "../../../../global-store/loadingSlice";
import {
  addPlatformCreditRule,
  addMerchantCreditRule,
  getPlatformCreditRules,
  getMerchantCreditRules,
  updatePlatformCreditRule,
  updateMerchantCreditRule,
  deletePlatformCreditRule,
  deleteMerchantCreditRule,
} from "../../api";
import { getMerchantByJWT } from "../../../../global-api";
import { PlatformCreditRules } from "../../../../../../server/src/platform/underwriting/entities/credit-rules.entity";
import capitalize from "../../../../global-helpers/capitalize";
import currencyToString from "../../../../global-helpers/currencyToString";
import isUserMerchant from "../../../../global-helpers/isMerchant";
import { CreditReportSettings } from "../../../../../../server/src/admin/merchant/entities/credit-report-settings.entity";

interface RuleTemplate {
  enabledOperators: (
    | "less than"
    | "less than or equal to"
    | "greater than"
    | "greater than or equal to"
  )[];
  disableMedicalTradeLine: boolean;
  disableStudentLoan: boolean;
  displayTimeframe: boolean;
  dollarThreshold?: number;
  includeMedicalTradeline: boolean;
  includeStudentLoan: boolean;
  longDescription: string;
  ruleType: string;
  shortDescription: string;
  threshold: number;
  thresholdMarks: { value: number; label: string }[];
  thresholdMaxValue: number;
  thresholdMinValue: number;
  thresholdStep: number;
}

const ruleTemplates: Record<string, RuleTemplate> = {
  creditScore: {
    enabledOperators: ["less than", "less than or equal to"],
    disableMedicalTradeLine: true,
    disableStudentLoan: true,
    displayTimeframe: false,
    includeMedicalTradeline: false,
    includeStudentLoan: false,
    longDescription:
      "Detailed description: A number between 300-850 based on credit history, number of open accounts, total levels of debt, repayment history and other factors.",
    ruleType: "credit score",
    shortDescription: "Credit worthiness as a score",
    threshold: 660,
    thresholdMarks: [
      { value: 300, label: "300" },
      { value: 850, label: "850" },
    ],
    thresholdMaxValue: 850,
    thresholdMinValue: 300,
    thresholdStep: 1,
  },
  income: {
    enabledOperators: ["less than or equal to", "less than"],
    disableMedicalTradeLine: true,
    disableStudentLoan: true,
    displayTimeframe: true,
    includeMedicalTradeline: false,
    includeStudentLoan: false,
    longDescription:
      "The amount of gross income is a primary driver of a borrower's ability to pay.",
    ruleType: "income",
    shortDescription: "Gross income (Before tax or other deductions)",
    threshold: 500,
    thresholdMarks: [
      { value: 0, label: "$0" },
      { value: 25000, label: "$25,000" },
    ],
    thresholdMaxValue: 25000,
    thresholdMinValue: 0,
    thresholdStep: 500,
  },
  dti: {
    enabledOperators: ["greater than", "greater than or equal to"],
    disableMedicalTradeLine: true,
    disableStudentLoan: true,
    displayTimeframe: false,
    includeMedicalTradeline: true,
    includeStudentLoan: true,
    longDescription:
      "The debt-to-income ratio is the percentage of one’s gross monthly income dedicated to pay for monthly debt payments. A low DTI ratio indicates sufficient income relative to debt servicing and demonstrates additional capacity to pay.",
    ruleType: "dti",
    shortDescription:
      "DTI is the ratio of required minimum monthly payments to gross monthly income.",
    threshold: 35,
    thresholdMarks: [
      { value: 0, label: "0%" },
      { value: 200, label: "200%" },
    ],
    thresholdMaxValue: 200,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  latePayment: {
    enabledOperators: ["greater than", "greater than or equal to"],
    disableMedicalTradeLine: false,
    disableStudentLoan: false,
    displayTimeframe: true,
    includeMedicalTradeline: false,
    includeStudentLoan: true,
    longDescription:
      "Delinquency is another word for tradelines with days past due - it occurs when one fails to make scheduled payment or minimum due on a revolving or installment tradeline.",
    ruleType: "late payment",
    shortDescription:
      "Number of tradelines that exceeds the amount of days past due",
    threshold: 0,
    thresholdMarks: [
      { value: 0, label: "0" },
      { value: 20, label: "20" },
    ],
    thresholdMaxValue: 20,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  chargeOffCount: {
    enabledOperators: ["greater than", "greater than or equal to"],
    disableMedicalTradeLine: false,
    disableStudentLoan: false,
    displayTimeframe: true,
    dollarThreshold: 200,
    includeMedicalTradeline: false,
    includeStudentLoan: true,
    longDescription:
      " A charge-off means a creditor has written off one’s account as a loss and closed it to future charges. It typically means an account is 120 to 180 days past due.",
    ruleType: "chargeoff count",
    shortDescription: "Number of charged off tradelines",
    threshold: 0,
    thresholdMarks: [
      { value: 0, label: "0" },
      { value: 100, label: "100" },
    ],
    thresholdMaxValue: 100,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  chargeOffAmount: {
    enabledOperators: ["greater than", "greater than or equal to"],
    disableMedicalTradeLine: false,
    disableStudentLoan: false,
    displayTimeframe: true,
    includeMedicalTradeline: false,
    includeStudentLoan: true,
    longDescription:
      " A charge-off means a creditor has written off one’s account as a loss and closed it to future charges. It typically means an account is 120 to 180 days past due.",
    ruleType: "chargeoff amount",
    shortDescription: "Sum of the dollar amount of charged off tradelines.",
    threshold: 500,
    thresholdMarks: [
      { value: 0, label: "$0" },
      { value: 5000, label: "$5,000" },
    ],
    thresholdMaxValue: 5000,
    thresholdMinValue: 0,
    thresholdStep: 100,
  },
  bankruptcy: {
    enabledOperators: ["greater than", "greater than or equal to"],
    disableMedicalTradeLine: true,
    disableStudentLoan: true,
    displayTimeframe: true,
    includeMedicalTradeline: false,
    includeStudentLoan: false,
    longDescription:
      "Chapter 7 and 11 bankruptcies can stay on one’s credit up to 10 years after initial filing.",
    ruleType: "bankruptcy",
    shortDescription: "Number of bankruptcies in a given period.",
    threshold: 1,
    thresholdMarks: [
      { value: 0, label: "0" },
      { value: 5, label: "5" },
    ],
    thresholdMaxValue: 5,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  foreclosures: {
    enabledOperators: ["greater than", "greater than or equal to"],
    disableMedicalTradeLine: true,
    disableStudentLoan: true,
    displayTimeframe: true,
    includeMedicalTradeline: false,
    includeStudentLoan: false,
    longDescription:
      "The number of properties that have been repossessed by the lender following a borrower's failure to pay.",
    ruleType: "foreclosures",
    shortDescription: "Number of foreclosure entries",
    threshold: 1,
    thresholdMarks: [
      { value: 0, label: "0" },
      { value: 20, label: "20" },
    ],
    thresholdMaxValue: 20,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  monthsOfCreditHistory: {
    enabledOperators: ["less than", "less than or equal to"],
    disableMedicalTradeLine: true,
    disableStudentLoan: true,
    displayTimeframe: false,
    includeMedicalTradeline: false,
    includeStudentLoan: false,
    longDescription:
      "Credit scoring models rely on a borrower's past behavior to forecast their ability and likelyhood to repay debts in the future.  Generally, longer credit histories are more accurate than shorter histories.",
    ruleType: "months of credit history",
    shortDescription: "Length of a borrower's oldest tradeline",
    threshold: 12,
    thresholdMarks: [
      { value: 0, label: "0" },
      { value: 84, label: "84" },
    ],
    thresholdMaxValue: 84,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  activeTrades: {
    enabledOperators: [
      "less than",
      "less than or equal to",
      "greater than",
      "greater than or equal to",
    ],
    disableMedicalTradeLine: false,
    disableStudentLoan: false,
    displayTimeframe: false,
    includeMedicalTradeline: true,
    includeStudentLoan: true,
    longDescription:
      "The number of open tradelines outlines one’s credit worthiness. A tradeline is a summary on every revolving or installment credit one has. Having too many can make one look overextended, and having too few shows one lack experience with credit.",
    ruleType: "active trades",
    shortDescription:
      "Number of currently open revolving or installment tradelines",
    threshold: 3,
    thresholdMarks: [
      { value: 0, label: "0" },
      { value: 20, label: "20" },
    ],
    thresholdMaxValue: 20,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  revolvingTrades: {
    enabledOperators: [
      "less than",
      "less than or equal to",
      "greater than",
      "greater than or equal to",
    ],
    disableMedicalTradeLine: false,
    disableStudentLoan: false,
    displayTimeframe: false,
    includeMedicalTradeline: true,
    includeStudentLoan: true,
    longDescription:
      "A revolving tradeline is a line of credit or a credit card account - these accounts let the borrower use credit as needed (up to their credit limit) over an extended time frame.",
    ruleType: "revolving trades",
    shortDescription: "Number of currently open revolving tradelines",
    threshold: 3,
    thresholdMarks: [
      { value: 0, label: "0" },
      { value: 10, label: "10" },
    ],
    thresholdMaxValue: 10,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  inquiries: {
    enabledOperators: ["greater than", "greater than or equal to"],
    disableMedicalTradeLine: true,
    disableStudentLoan: true,
    displayTimeframe: true,
    includeMedicalTradeline: false,
    includeStudentLoan: false,
    longDescription:
      "Inquiries are the result of an application of credit, goods or services. Generally, a high number of hard credit inquiries in a short period of time can be interpreted as an attempt to substantially expand available credit.",
    ruleType: "inquiries",
    shortDescription: "Number of hard inquiry entries",
    threshold: 6,
    thresholdMarks: [
      { value: 0, label: "0" },
      { value: 20, label: "20" },
    ],
    thresholdMaxValue: 20,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  utilizationOfCredit: {
    enabledOperators: ["greater than", "greater than or equal to"],
    disableMedicalTradeLine: true,
    disableStudentLoan: true,
    displayTimeframe: false,
    includeMedicalTradeline: true,
    includeStudentLoan: true,
    longDescription:
      "Utilization of credit measures the amount of revolving credit limits that one is currently using. Utilization of revolving trades is defined as: Sum of all open revolving tradeline balance / Sum of all open tradeline credit limit.",
    ruleType: "utilization of credit",
    shortDescription:
      "Ratio of the max credit available in revolving tradelines.",
    threshold: 50,
    thresholdMarks: [
      { value: 0, label: "0%" },
      { value: 125, label: "125%" },
    ],
    thresholdMaxValue: 125,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  collectionsCount: {
    enabledOperators: ["greater than", "greater than or equal to"],
    disableMedicalTradeLine: true,
    disableStudentLoan: true,
    displayTimeframe: true,
    dollarThreshold: 200,
    includeMedicalTradeline: false,
    includeStudentLoan: false,
    longDescription:
      "A tradeline is designated as a 'collections' account when the debt goes past 120 days, and generally is assigned to a 3rd party collections agency.",
    ruleType: "collections count",
    shortDescription: "Number of tradelines involved with a collection agency",
    threshold: 1,
    thresholdMarks: [
      { value: 1, label: "1" },
      { value: 20, label: "20" },
    ],
    thresholdMaxValue: 20,
    thresholdMinValue: 1,
    thresholdStep: 1,
  },
  collectionsAmount: {
    enabledOperators: ["greater than", "greater than or equal to"],
    disableMedicalTradeLine: true,
    disableStudentLoan: true,
    displayTimeframe: false,
    includeMedicalTradeline: false,
    includeStudentLoan: false,
    longDescription:
      "A tradeline is designated as a 'collections' account when the debt goes past 120 days, and generally is assigned to a 3rd party collections agency.",
    ruleType: "collections amount",
    shortDescription:
      "The total dollar amount of debt that has been sent to a 3rd party for collections.",
    threshold: 500,
    thresholdMarks: [
      { value: 0, label: "$0" },
      { value: 5000, label: "$5,000" },
    ],
    thresholdMaxValue: 5000,
    thresholdMinValue: 0,
    thresholdStep: 100,
  },
};


interface CreditRulesProps {
  productId?: string;
}

const CreditRules = ({productId}: CreditRulesProps) => {
  const isMerchant: boolean = isUserMerchant();
  const [rowsData, setRowsData] = useState<PlatformCreditRules[]>([]);
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [openDeleteRuleModal, setOpenDeleteRuleModal] = useState(false);
  const [enableMerchantRules, setEnableMerchantRules] = useState(false);
  const baseRuleColumns: GridColDef[] = [
    {
      field: "active",
      headerName: "Active",
      width: 70,
      renderCell: ({ formattedValue, row }) => (
        <Switch
          onChange={() => onActiveChange(row.id, !formattedValue)}
          checked={formattedValue}
          disabled={
            (isMerchant && row.platform?.name === row.createdBy) ||
            (isMerchant && !enableMerchantRules)
          }
        />
      ),
    },
    {
      field: "bypass",
      headerName: "Bypass",
      width: 75,
      renderCell: ({ formattedValue }) => (
        <Switch disabled={true} checked={formattedValue} />
      ),
    },
    {
      field: "id",
      headerName: "Action",
      width: 200,
      renderCell: ({ row }) => (
        <>
          <Button
            variant="contained"
            onClick={() => onEditRuleClick(row)}
            disabled={
              (isMerchant && row.platform?.name === row.createdBy) ||
              (isMerchant && !enableMerchantRules)
            }
            startIcon={<EditIcon />}
            sx={{ mr: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            onClick={() => onDeleteRuleOpen(row)}
            disabled={
              (isMerchant && row.platform?.name === row.createdBy) ||
              (isMerchant && !enableMerchantRules)
            }
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </>
      ),
    },
    {
      field: "ruleType",
      headerName: "Type",
      width: 150,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    {
      field: "failAction",
      headerName: "Fail Action",
      width: 110,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    {
      field: "failIf",
      headerName: "Fail If",
      width: 250,
      renderCell: ({ formattedValue, row }) => {
        if (row.ruleType === ruleTemplates.creditScore.ruleType) {
          return `${capitalize(row.scoreType)} ${capitalize(formattedValue)} ${
            row.threshold
          }`;
        }
        if (row.ruleType === ruleTemplates.latePayment.ruleType) {
          return `${capitalize(row.lateAttribute)} ${capitalize(
            formattedValue
          )} ${row.threshold}`;
        }
        if (row.ruleType === ruleTemplates.income.ruleType) {
          return `${capitalize(formattedValue)} $${currencyToString(
            row.threshold
          )}`;
        }

        return `${capitalize(formattedValue)} ${row.threshold}`;
      },
    },
    {
      field: "periodType",
      headerName: "Rules Timeframe",
      width: 145,
      renderCell: ({ formattedValue, row }) => {
        for (const [, value] of Object.entries(ruleTemplates)) {
          if (
            value.ruleType === row.ruleType &&
            value.displayTimeframe &&
            row.ruleType !== ruleTemplates.income.ruleType
          ) {
            if (row.periodSelection <= 1) {
              if (formattedValue === "months") {
                return `${row.periodSelection} Month`;
              }

              return `${row.periodSelection} Year`;
            }

            return `${row.periodSelection} ${capitalize(formattedValue)}`;
          } else if (
            value.ruleType === row.ruleType &&
            value.displayTimeframe &&
            row.ruleType === ruleTemplates.income.ruleType
          ) {
            return `${capitalize(row.incomePeriodType)}`;
          }
        }

        return `--`;
      },
    },
    {
      field: "dollarThreshold",
      headerName: "Dollar Threshold",
      width: 140,
      renderCell: ({ formattedValue, row }) => {
        if (
          row.ruleType === ruleTemplates.collectionsCount.ruleType ||
          row.ruleType === ruleTemplates.chargeOffCount.ruleType
        ) {
          return `$${currencyToString(formattedValue)}`;
        }

        return "--";
      },
    },
    {
      field: "includeMedicalTradeline",
      headerName: "Include Medical Loan",
      width: 170,
      renderCell: ({ formattedValue, row }) => {
        for (const [, value] of Object.entries(ruleTemplates)) {
          if (
            row.ruleType === "utilization of credit" ||
            row.ruleType === "dti"
          ) {
            return <Switch disabled={true} checked={formattedValue} />;
          } else if (
            value.ruleType === row.ruleType &&
            value.disableMedicalTradeLine
          ) {
            return "--";
          }
        }

        return <Switch disabled={true} checked={formattedValue} />;
      },
    },
    {
      field: "includeStudentLoan",
      headerName: "Include Student Loan",
      width: 175,
      renderCell: ({ formattedValue, row }) => {
        for (const [, value] of Object.entries(ruleTemplates)) {
          if (
            row.ruleType === "utilization of credit" ||
            row.ruleType === "dti"
          ) {
            return <Switch disabled={true} checked={true} />;
          } else if (
            value.ruleType === row.ruleType &&
            value.disableStudentLoan
          ) {
            return "--";
          }
        }

        return <Switch disabled={true} checked={formattedValue} />;
      },
    },
  ];
  const [ruleColumns, setRuleColumns] = useState<GridColDef[]>(baseRuleColumns);
  const grayPaperBackground = "#f6f7f7";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      active: true,
      bypass: false,
      disableMedicalTradeLine: false,
      disableStudentLoan: false,
      displayTimeframe: false,
      dollarThreshold: 0,
      enabledOperators: [] as (
        | "less than"
        | "less than or equal to"
        | "greater than"
        | "greater than or equal to"
      )[],
      failAction: "manual review" as "manual review" | "decline",
      failIf: undefined as
        | undefined
        | "less than"
        | "less than or equal to"
        | "greater than"
        | "greater than or equal to",
      includeMedicalTradeline: false,
      includeStudentLoan: true,
      incomePeriodType: "monthly" as "monthly" | "annual",
      lateAttribute: undefined as
        | "current"
        | "30 days"
        | "60 days"
        | "90 days"
        | "120 days"
        | undefined,
      longDescription: "",
      name: "",
      periodSelection: 1,
      periodType: "months",
      ruleId: undefined as undefined | string,
      ruleType: "",
      scoreType: "vantage 3",
      shortDescription: "",
      threshold: 0,
      thresholdMarks: [] as { value: number; label: string }[],
      thresholdMaxValue: 100,
      thresholdMinValue: 0,
      thresholdStep: 1,
    },
    validationSchema: yup.object({
      name: yup.string().trim().required("Rule name is required"),
      failIf: yup.string().trim().required("Fail condition is required"),
      incomePeriodType: yup
        .string()
        .nullable()
        .test(
          "income-period-type-check",
          "Period type is required",
          (value: string) => {
            if (!formik.values.displayTimeframe) {
              return true;
            }
            if (
              formik.values.displayTimeframe &&
              formik.values.ruleType !== ruleTemplates.income.ruleType
            ) {
              return true;
            }

            return formik.values.displayTimeframe && Boolean(value);
          }
        ),
      lateAttribute: yup
        .string()
        .nullable()
        .test(
          "late-attribute-check",
          "Late attribute is required",
          (value: string) => {
            if (formik.values.ruleType !== ruleTemplates.latePayment.ruleType) {
              return true;
            }

            return Boolean(
              value &&
                formik.values.ruleType === ruleTemplates.latePayment.ruleType
            );
          }
        ),
    }),

    onSubmit: async (values) => {
      try {
        dispatch(setIsLoading(true));

        const { ruleId } = values;
        const requestBody: any = filterRequestBody(values);
        if (values.ruleId) {
          if (isMerchant) {
            await updateMerchantCreditRule(ruleId, requestBody);
          } else {
            await updatePlatformCreditRule(ruleId, requestBody);
          }
        } else if (isMerchant) {
          await addMerchantCreditRule(requestBody);
        } else {
          await addPlatformCreditRule(requestBody, productId);
        }

        await loadState();

        setOpenRuleModal(false);
        formik.resetForm();
        dispatch(
          showNotification({
            message: `Rule ${values.ruleId ? "updated" : "added"} successfully`,
            severity: "success",
            shouldNotify: true,
          })
        );

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    },
  });

  const onRuleTemplateClick = async (ruleTemplate: RuleTemplate) => {
    if (isMerchant && !enableMerchantRules) {
      return;
    }

    await formik.setValues(
      {
        ...formik.values,
        ...ruleTemplate,
      },
      false
    );
    onModalOpen();
  };
  const onCreateNewRuleClick = async () => {
    formik.resetForm();
    setOpenRuleModal(true);
  };
  const onRuleTypeClick = async (ruleTemplate: RuleTemplate) => {
    await formik.setErrors({});
    await formik.setTouched({});
    await formik.setValues(
      {
        ...formik.values,
        ...ruleTemplate,
        failAction: "manual review",
        failIf: undefined,
        lateAttribute: undefined,
        incomePeriodType: "monthly",
        name: "",
      },
      false
    );
  };
  const onRuleTimeframeClick = async (timeframe: "months") => {
    await formik.setFieldValue("periodType", timeframe, true);
  };
  const onIncomeTimeframeClick = async (
    incomePeriodType: "monthly" | "annual"
  ) => {
    if (incomePeriodType === "annual") {
      await formik.setValues(
        {
          ...formik.values,
          incomePeriodType,
          threshold: 5000,
          thresholdMaxValue: 300000,
          thresholdStep: 5000,
          thresholdMarks: [
            { value: 0, label: "$0" },
            { value: 300000, label: "$300,000" },
          ],
        },
        true
      );
    } else {
      await formik.setValues(
        {
          ...formik.values,
          incomePeriodType,
          threshold: 500,
          thresholdMaxValue: 25000,
          thresholdStep: 500,
          thresholdMarks: [
            { value: 0, label: "$0" },
            { value: 25000, label: "$25,000" },
          ],
        },
        true
      );
    }
  };
  const onFailIfClick = async (
    failIfCondition:
      | "less than"
      | "less than or equal to"
      | "greater than"
      | "greater than or equal to"
  ) => {
    await formik.setFieldValue("failIf", failIfCondition, true);
  };
  const onLateAttributeClick = async (
    lateAttribute: "current" | "30 days" | "60 days" | "90 days" | "120 days"
  ) => {
    if (lateAttribute === "current") {
      await formik.setValues({
        ...formik.values,
        lateAttribute,
        periodSelection: 1,
      });
    } else {
      await formik.setFieldValue("lateAttribute", lateAttribute, true);
    }
  };
  const onModalOpen = () => {
    setOpenRuleModal(true);
  };
  const onModalClose = async () => {
    setOpenRuleModal(false);

    if (formik.values.ruleId) {
      await formik.resetForm();
    }
  };
  const loadState = async () => {
    if (isMerchant) {
      const { data } = await getMerchantCreditRules();

      setRuleColumns([
        {
          field: "createdBy",
          headerName: "Created By",
          width: 200,
          renderCell: ({ formattedValue }) => `${capitalize(formattedValue)}`,
        },
        ...baseRuleColumns,
      ]);
      setRowsData(data);
    } else if (productId) {
      const { data } = await getPlatformCreditRules(productId);
      setRowsData(data);
    }
  };
  const onActiveChange = async (ruleId: string, active: boolean) => {
    try {
      dispatch(setIsLoading(true));
      const requestBody = {
        active,
      };
      if (isMerchant) {
        await updateMerchantCreditRule(ruleId, requestBody);
      } else {
        await updatePlatformCreditRule(ruleId, requestBody);
      }

      await loadState();

      dispatch(
        showNotification({
          message: "Rule updated successfully",
          severity: "success",
          shouldNotify: true,
        })
      );

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = handleError(error, navigate, true);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }

      dispatch(setIsLoading(false));
    }
  };
  const onEditRuleClick = async (
    rule: PlatformCreditRules & {
      createdBy: string;
      platform?: Record<string, any>;
      merchant?: Record<string, any>;
    }
  ) => {
    const { id, createdBy, merchant, platform, ...other } = rule;

    const ruleTemplate = Object.values(ruleTemplates).find(({ ruleType }) => {
      return ruleType === other.ruleType;
    });
    await formik.setValues({ ...ruleTemplate, ...other, ruleId: id }, true);
    setOpenRuleModal(true);
  };
  const onDeleteRuleOpen = async (
    rule: PlatformCreditRules & {
      createdBy: string;
      platform?: Record<string, any>;
      merchant?: Record<string, any>;
    }
  ) => {
    const { id, createdBy, merchant, platform, ...other } = rule;

    const ruleTemplate = Object.values(ruleTemplates).find(({ ruleType }) => {
      return ruleType === other.ruleType;
    });
    await formik.setValues({ ...ruleTemplate, ...other, ruleId: id }, false);
    setOpenDeleteRuleModal(true);
  };
  const onDeleteRuleClose = async () => {
    formik.resetForm();
    setOpenDeleteRuleModal(false);
  };
  const onDeleteRuleConfirm = async () => {
    try {
      dispatch(setIsLoading(true));

      if (isMerchant) {
        await deleteMerchantCreditRule(formik.values.ruleId);
      } else {
        await deletePlatformCreditRule(formik.values.ruleId);
      }

      await loadState();

      setOpenDeleteRuleModal(false);
      formik.resetForm();
      dispatch(
        showNotification({
          message: "Rule deleted successfully",
          severity: "success",
          shouldNotify: true,
        })
      );

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = handleError(error, navigate);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }

      dispatch(setIsLoading(false));
    }
  };
  const filterRequestBody = (requestBody: Record<string, any>) => {
    const {
      disableMedicalTradeLine,
      disableStudentLoan,
      displayTimeframe,
      dollarThreshold,
      enabledOperators,
      includeMedicalTradeline,
      includeStudentLoan,
      incomePeriodType,
      lateAttribute,
      longDescription,
      periodType,
      periodSelection,
      ruleId,
      ruleType,
      scoreType,
      shortDescription,
      thresholdMarks,
      thresholdMaxValue,
      thresholdMinValue,
      thresholdStep,
      ...other
    } = requestBody;

    let filteredRequestBody: any = {
      ruleType,
    };
    if (ruleType === "credit score") {
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        scoreType,
      };
    } else if (ruleType === "income") {
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        incomePeriodType,
      };
    } else if (ruleType === "late payment") {
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        includeMedicalTradeline,
        includeStudentLoan,
        lateAttribute,
        periodSelection,
        periodType,
      };
    } else if (
      ruleType === "chargeoff count" ||
      ruleType === "collections count"
    ) {
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        dollarThreshold,
        includeMedicalTradeline,
        includeStudentLoan,
        periodSelection,
        periodType,
      };
    } else if (
      ruleType === "dti" ||
      ruleType === "active trades" ||
      ruleType === "revolving trades" ||
      ruleType === "utilization of credit"
    ) {
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        includeMedicalTradeline,
        includeStudentLoan,
      };
    } else if (
      ruleType === "collections amount" ||
      ruleType === "months of credit history"
    ) {
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
      };
    } else {
      // bankruptcy, foreclosures and inquires
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        periodSelection,
        periodType,
      };
    }

    return filteredRequestBody;
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));

        if (isMerchant) {
          const { data } = await getMerchantByJWT();
          setEnableMerchantRules(
            (data.creditReportSettings as CreditReportSettings)
              .enableAdditionalMerchantUnderwritingRules
          );
        }

        await loadState();

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableMerchantRules]);

  const render = (
    <Box>
      <Box>
        <Typography variant="h5">
          <strong>Credit Rules Templates</strong>
        </Typography>
      </Box>

      <Grid container rowSpacing={1} columnSpacing={2}>
        {Object.values(ruleTemplates).map((template) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={template.ruleType}>
            <Paper
              onClick={() => onRuleTemplateClick(template)}
              sx={{
                mt: 1.375,
                cursor: isMerchant && !enableMerchantRules ? "" : "pointer",
                ...(isMerchant &&
                  !enableMerchantRules && {
                    backgroundColor: grayPaperBackground,
                  }),
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", p: 1, minWidth: "8rem",
                    minHeight: "8rem", }}>
                {/* <Box
                  sx={{
                    minWidth: "7.375rem",
                    minHeight: "7.375rem",
                    backgroundImage: `url(${imageTemplate})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    borderRadius: "20px",
                    mr: 1,
                  }}
                ></Box> */}
                <Box
                  sx={{
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Typography>
                    <strong>
                      {template.ruleType === "dti"
                        ? "DTI"
                        : capitalize(template.ruleType)}
                    </strong>
                  </Typography>
                  <Typography>{template.shortDescription}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {rowsData?.length <= 0 ? (
        <Typography variant="h5" sx={{ mt: 7, mb: 2 }}>
          <strong>Credit Rules</strong>
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ mt: 7, mb: 2 }}>
            <strong>Credit Rules</strong>
          </Typography>
          <Button
            variant="contained"
            onClick={onCreateNewRuleClick}
            disabled={isMerchant && !enableMerchantRules}
            startIcon={<AddIcon />}
            sx={{ mt: 4 }}
          >
            Create New Credit Rule
          </Button>
        </Box>
      )}

      {rowsData?.length <= 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 5.5 }}>
          <Box sx={{ textAlign: "center" }}>
            <AdvertisingIcon
              sx={{ width: "2.5627.375rem", height: "2.5627.375rem" }}
            />
            <Typography sx={{ mt: 2.466 }}>
              <strong>No credit rules found</strong>
            </Typography>
            <Typography sx={{ color: "#667085" }}>
              Get some data here by pressing add rules button
            </Typography>

            <Button
              variant="contained"
              onClick={onCreateNewRuleClick}
              disabled={isMerchant && !enableMerchantRules}
              startIcon={<AddIcon />}
              sx={{ mt: 4 }}
            >
              Create New Credit Rule
            </Button>
          </Box>
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <DataGrid
              columns={ruleColumns}
              rows={rowsData}
              autoHeight
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              }}
              hideFooter
            />
          </Grid>
        </Grid>
      )}

      <Modal
        open={openRuleModal}
        onClose={onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openRuleModal}>
          <Box
            sx={{
              ...modalStyles,
              height: formik.values.ruleType ? "90%" : "auto",
              width: "60%",
              overflowY: "auto",
            }}
          >
            <form onSubmit={formik.handleSubmit} noValidate>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                <strong>Create New Credit Rule</strong>
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  mt: 3.71,
                }}
              >
                {Object.values(ruleTemplates).map((template: RuleTemplate) => (
                  <Button
                    key={template.ruleType}
                    variant={
                      formik.values.ruleType === template.ruleType
                        ? "contained"
                        : "outlined"
                    }
                    startIcon={
                      formik.values.ruleType === template.ruleType && (
                        <CheckIcon />
                      )
                    }
                    onClick={() => onRuleTypeClick(template)}
                    sx={{ mt: 1 }}
                  >
                    {template.ruleType === "dti"
                      ? "DTI"
                      : capitalize(template.ruleType)}
                  </Button>
                ))}
              </Box>

              {formik.values.ruleType ? (
                <Box sx={{ ml: 4 }}>
                  <Box sx={{ mt: 7 }}>
                    <Typography variant="h6">
                      <strong>
                        {formik.values.ruleType === "dti"
                          ? "DTI"
                          : capitalize(formik.values.ruleType)}
                      </strong>
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                      {formik.values.longDescription}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      my: 4,
                    }}
                  >
                    <TextField
                      sx={{ mr: 2 }}
                      variant="outlined"
                      label="NAME"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{
                        maxLength: 100,
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      fullWidth
                      required
                    />
                    <FormControl fullWidth required>
                      <InputLabel id="fail-action">FAIL ACTION</InputLabel>
                      <Select
                        labelId="fail-action"
                        label="FAIL ACTION"
                        name="failAction"
                        value={formik.values.failAction}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.failAction &&
                          Boolean(formik.errors.failAction)
                        }
                      >
                        <MenuItem value="manual review">Manual Review</MenuItem>
                        <MenuItem value="decline">Decline</MenuItem>
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {formik.touched.failAction && formik.errors.failAction}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  {formik.values.displayTimeframe && (
                    <>
                      <hr style={{ color: "rgba(0, 0, 0, 0.12)" }} />
                      <Box sx={{ mt: 2.5 }}>
                        <Typography variant="h6">
                          <strong>Rules Timeframe</strong>
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <Typography sx={{ mr: 5.25 }}>Period Type</Typography>
                          {formik.values.ruleType ===
                          ruleTemplates.income.ruleType ? (
                            <>
                              <Button
                                variant={
                                  formik.values.incomePeriodType === "monthly"
                                    ? "contained"
                                    : "outlined"
                                }
                                startIcon={
                                  formik.values.incomePeriodType ===
                                    "monthly" && <CheckIcon />
                                }
                                onClick={() =>
                                  onIncomeTimeframeClick("monthly")
                                }
                                sx={{ mr: 2 }}
                              >
                                Monthly
                              </Button>
                              <Button
                                variant={
                                  formik.values.incomePeriodType === "annual"
                                    ? "contained"
                                    : "outlined"
                                }
                                startIcon={
                                  formik.values.incomePeriodType ===
                                    "annual" && <CheckIcon />
                                }
                                onClick={() => onIncomeTimeframeClick("annual")}
                              >
                                Annual
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                variant={"contained"}
                                startIcon={<CheckIcon />}
                                onClick={() => onRuleTimeframeClick("months")}
                                sx={{ mr: 2 }}
                              >
                                Months
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>

                      {formik.values.ruleType !==
                        ruleTemplates.income.ruleType && (
                        <Box
                          sx={{ display: "flex", alignItems: "center", my: 4 }}
                        >
                          <Typography sx={{ minWidth: "120px", mr: 2 }}>
                            Period Selection
                          </Typography>
                          <Slider
                            valueLabelDisplay="on"
                            name="periodSelection"
                            min={1}
                            max={84}
                            marks={[
                              { value: 1, label: "1 month" },
                              { value: 84, label: "84 months" },
                            ]}
                            value={formik.values.periodSelection}
                            onChange={formik.handleChange}
                            disabled={formik.values.lateAttribute === "current"}
                            sx={{ mt: 1 }}
                          />
                        </Box>
                      )}

                      <Typography
                        sx={{ color: "#d32f2f", textAlign: "center", my: 2 }}
                      >
                        {(formik.touched.periodType &&
                          formik.errors.periodType) ||
                          (formik.touched.incomePeriodType &&
                            formik.errors.incomePeriodType)}
                      </Typography>
                    </>
                  )}

                  <hr style={{ color: "rgba(0, 0, 0, 0.12)" }} />

                  <Box sx={{ mt: 2.5 }}>
                    <Typography variant="h6">
                      <strong>Rules Threshold</strong>
                    </Typography>

                    {formik.values.ruleType ===
                      ruleTemplates.creditScore.ruleType && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <Typography sx={{ mr: 5.25 }}>Score Type</Typography>
                          <Button
                            variant="outlined"
                            disabled={true}
                            sx={{ mr: 2 }}
                          >
                            Fico v9
                          </Button>
                          <Button
                            variant={"contained"}
                            disabled={false}
                            startIcon={<CheckIcon />}
                            sx={{ mr: 2 }}
                          >
                            Vantage 3
                          </Button>
                          <Button
                            variant="outlined"
                            disabled={true}
                            sx={{ mr: 2 }}
                          >
                            Vantage 4
                          </Button>
                        </Box>
                      </>
                    )}

                    {formik.values.ruleType ===
                      ruleTemplates.latePayment.ruleType && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <Typography sx={{ mr: 5.25 }}>
                            Late Attribute
                          </Typography>
                          <Tooltip title="The Current option checks for any currently delinquent trades">
                            <Button
                              variant={
                                formik.values.lateAttribute === "current"
                                  ? "contained"
                                  : "outlined"
                              }
                              startIcon={
                                formik.values.lateAttribute === "current" && (
                                  <CheckIcon />
                                )
                              }
                              onClick={() => onLateAttributeClick("current")}
                              sx={{ mr: 2 }}
                            >
                              Current
                            </Button>
                          </Tooltip>
                          <Button
                            variant={
                              formik.values.lateAttribute === "30 days"
                                ? "contained"
                                : "outlined"
                            }
                            startIcon={
                              formik.values.lateAttribute === "30 days" && (
                                <CheckIcon />
                              )
                            }
                            onClick={() => onLateAttributeClick("30 days")}
                            sx={{ mr: 2 }}
                          >
                            30 days
                          </Button>
                          <Button
                            variant={
                              formik.values.lateAttribute === "60 days"
                                ? "contained"
                                : "outlined"
                            }
                            startIcon={
                              formik.values.lateAttribute === "60 days" && (
                                <CheckIcon />
                              )
                            }
                            onClick={() => onLateAttributeClick("60 days")}
                            sx={{ mr: 2 }}
                          >
                            60 days
                          </Button>
                          <Button
                            variant={
                              formik.values.lateAttribute === "90 days"
                                ? "contained"
                                : "outlined"
                            }
                            startIcon={
                              formik.values.lateAttribute === "90 days" && (
                                <CheckIcon />
                              )
                            }
                            onClick={() => onLateAttributeClick("90 days")}
                            sx={{ mr: 2 }}
                          >
                            90 days
                          </Button>
                          <Button
                            variant={
                              formik.values.lateAttribute === "120 days"
                                ? "contained"
                                : "outlined"
                            }
                            startIcon={
                              formik.values.lateAttribute === "120 days" && (
                                <CheckIcon />
                              )
                            }
                            onClick={() => onLateAttributeClick("120 days")}
                            sx={{ mr: 2 }}
                          >
                            120 days
                          </Button>
                        </Box>
                        <Typography
                          sx={{ color: "#d32f2f", textAlign: "center", mt: 2 }}
                        >
                          {formik.touched.lateAttribute &&
                            formik.errors.lateAttribute}
                        </Typography>
                      </>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography sx={{ mr: 5.25, mt: 2 }}>Fail If</Typography>
                      <Button
                        variant={
                          formik.values.failIf === "less than"
                            ? "contained"
                            : "outlined"
                        }
                        startIcon={
                          formik.values.failIf === "less than" && <CheckIcon />
                        }
                        onClick={() => onFailIfClick("less than")}
                        disabled={
                          formik.values.enabledOperators.indexOf(
                            "less than"
                          ) === -1
                        }
                        sx={{ mr: 2, mt: 2 }}
                      >
                        Less Than
                      </Button>
                      <Button
                        variant={
                          formik.values.failIf === "less than or equal to"
                            ? "contained"
                            : "outlined"
                        }
                        startIcon={
                          formik.values.failIf === "less than or equal to" && (
                            <CheckIcon />
                          )
                        }
                        onClick={() => onFailIfClick("less than or equal to")}
                        disabled={
                          formik.values.enabledOperators.indexOf(
                            "less than or equal to"
                          ) === -1
                        }
                        sx={{ mr: 2, mt: 2 }}
                      >
                        Less Than Or Equal To
                      </Button>
                      <Button
                        variant={
                          formik.values.failIf === "greater than"
                            ? "contained"
                            : "outlined"
                        }
                        startIcon={
                          formik.values.failIf === "greater than" && (
                            <CheckIcon />
                          )
                        }
                        onClick={() => onFailIfClick("greater than")}
                        disabled={
                          formik.values.enabledOperators.indexOf(
                            "greater than"
                          ) === -1
                        }
                        sx={{ mr: 2, mt: 2 }}
                      >
                        Greater Than
                      </Button>
                      <Button
                        variant={
                          formik.values.failIf === "greater than or equal to"
                            ? "contained"
                            : "outlined"
                        }
                        startIcon={
                          formik.values.failIf ===
                            "greater than or equal to" && <CheckIcon />
                        }
                        onClick={() =>
                          onFailIfClick("greater than or equal to")
                        }
                        disabled={
                          formik.values.enabledOperators.indexOf(
                            "greater than or equal to"
                          ) === -1
                        }
                        sx={{ mr: 2, mt: 2 }}
                      >
                        Greater Than Or Equal To
                      </Button>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
                    <Typography sx={{ minWidth: "80px", mr: 2 }}>
                      Threshold
                    </Typography>
                    <Slider
                      valueLabelDisplay="on"
                      name="threshold"
                      min={formik.values.thresholdMinValue}
                      max={formik.values.thresholdMaxValue}
                      step={formik.values.thresholdStep}
                      marks={formik.values.thresholdMarks}
                      value={formik.values.threshold}
                      onChange={formik.handleChange}
                      sx={{ mt: 1 }}
                    />
                  </Box>

                  {(formik.values.ruleType ===
                    ruleTemplates.collectionsCount.ruleType ||
                    formik.values.ruleType ===
                      ruleTemplates.chargeOffCount.ruleType) && (
                    <>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ minWidth: "120px" }}>
                          Dollar Threshold
                        </Typography>
                        <Tooltip
                          title="Only count items above this dollar threshold"
                          sx={{ mr: 2 }}
                        >
                          <IconButton size="small">
                            <HelpOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Slider
                          valueLabelDisplay="on"
                          name="dollarThreshold"
                          min={0}
                          max={1000}
                          step={50}
                          marks={[
                            { value: 0, label: "$0" },
                            { value: 1000, label: "$1000" },
                          ]}
                          value={formik.values.dollarThreshold}
                          onChange={formik.handleChange}
                          sx={{ mt: 1 }}
                        />
                      </Box>
                    </>
                  )}

                  <Typography
                    sx={{ color: "#d32f2f", textAlign: "center", mb: 2 }}
                  >
                    {formik.touched.failIf && formik.errors.failIf}
                  </Typography>

                  <hr style={{ color: "rgba(0, 0, 0, 0.12)" }} />

                  <Box>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      <strong>Rules Outcome</strong>
                    </Typography>
                    <FormGroup sx={{ mt: 1.5 }}>
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              name="active"
                              onChange={formik.handleChange}
                              checked={formik.values.active}
                            />
                          }
                          label="Active"
                          sx={{ mr: 1 }}
                        />
                        <Tooltip title="Active">
                          <IconButton size="small">
                            <HelpOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              name="bypass"
                              onChange={formik.handleChange}
                              checked={formik.values.bypass}
                            />
                          }
                          label="Bypass"
                          sx={{ mr: 1 }}
                        />
                        <Tooltip title="Bypassed rule will not stop the application, it will trigger a manual review at the end of the application">
                          <IconButton size="small">
                            <HelpOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={formik.values.disableMedicalTradeLine}
                              name="includeMedicalTradeline"
                              onChange={formik.handleChange}
                              checked={formik.values.includeMedicalTradeline}
                            />
                          }
                          label="Include Medical Tradeline"
                          sx={{ mr: 1 }}
                        />
                        <Tooltip title="The standard practice is to exclude medical trades">
                          <IconButton size="small">
                            <HelpOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={formik.values.disableStudentLoan}
                              name="includeStudentLoan"
                              onChange={formik.handleChange}
                              checked={formik.values.includeStudentLoan}
                            />
                          }
                          label="Include Student Loan"
                          sx={{ mr: 1 }}
                        />
                        <Tooltip title="The standard practice is to include student loans">
                          <IconButton size="small">
                            <HelpOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </FormGroup>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 6,
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={onModalClose}
                      sx={{ mr: 4 }}
                    >
                      Go Back
                    </Button>
                    <Button type="submit" variant="contained">
                      {formik.values.ruleId ? "Update Rule" : "Save New Rule"}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
                  <Button
                    variant="outlined"
                    onClick={onModalClose}
                    sx={{ mr: 4 }}
                  >
                    Go Back
                  </Button>
                </Box>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>

      {openDeleteRuleModal && (
        <Modal
          open={openDeleteRuleModal}
          onClose={onDeleteRuleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDeleteRuleModal}>
            <Box sx={{ ...modalStyles }}>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Are you sure you want to delete the rule "
                {capitalize(formik.values.name)}"?
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
                <Button
                  variant="outlined"
                  onClick={onDeleteRuleClose}
                  sx={{ mr: 4 }}
                >
                  Cancel
                </Button>
                <Button variant="contained" onClick={onDeleteRuleConfirm}>
                  Confirm
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
    </Box>
  );

  return render;
};

export default CreditRules;
