import { Box, Container } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import Steps from "./Steps";
import Spinner from "../../../global-components/spinners/Spinner";
import NotificationBar from "../../../global-components/notifications/NotificationBar";
import { IGtmData } from "../../../global-helpers/handleGtmConfig";
import TagManager from "react-gtm-module";

try {
  const gtmData: IGtmData = JSON.parse(localStorage.getItem("gtmConfig"));
  if (gtmData?.gtm) {
    TagManager.initialize(gtmData.config);
  }
} catch (e) {
  console.log(e);
}

const Layout = () => {
  const { pathname } = useLocation();
  let showLoginButton = true;
  let showSteps = true;

  if (pathname.endsWith("/login")) {
    showLoginButton = false;
    showSteps = false;
  } else if (
    pathname.endsWith("/forgot-password") ||
    pathname.endsWith("/reset-password")
  ) {
    showLoginButton = false;
    showSteps = false;
  } else if (pathname.endsWith("/close-loan")) {
    showLoginButton = false;
    showSteps = false;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "secondary.main",
      }}
    >
      <Header showLoginButton={showLoginButton} />
      <Container sx={{ flex: 1 }}>
        <NotificationBar />
        {showSteps && <Steps />}
        <Outlet />
        <Spinner />
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
