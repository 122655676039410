import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

type TokenType = 'adminToken' | 'platformAdminToken';

interface Token {
  type: TokenType,
  value: any,
  url: string,
}

interface State {
  tokens: Token[],
  showHistoryToken: boolean,
}

const tokens = JSON.parse(localStorage.getItem('tokens') ?? '[]');

const initialState: State = {
  tokens,
  showHistoryToken: tokens.length > 0,
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<Token[]>) => {
      state.tokens = action.payload;
      state.showHistoryToken = action.payload.length > 0;
    },
  },
});

export const { setTokens } = tokenSlice.actions;

export default tokenSlice.reducer;
