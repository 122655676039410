import { Backdrop, CircularProgress } from "@mui/material";

import { useAppSelector } from "../../global-store/hooks";

const Spinner = () => {
  const isLoading = useAppSelector((state) => state.loading.isLoading);

  return (
    <Backdrop
      open={isLoading}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Spinner;
