import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import SearchField from "../../../global-components/inputs/SearchField";
import handleError from "../../../global-helpers/handleError";
import capitalize from "../../../global-helpers/capitalize";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { showNotification } from "../../../global-store/notificationSlice";
import { useAppDispatch } from "../../../global-store/hooks";
import { changeActive, getAllProfiles } from "../api";

const ManageProfile = () => {
  const [rowsData, setRowsData] = useState<{
    profiles: Record<string, any>[];
    total: number;
  }>({ profiles: [], total: 0 });
  const [page, setPage] = useState(0);
  const [searchedWord, setSearchedWord] = useState<string>("");
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Action",
      width: 200,
      renderCell: ({ formattedValue, row }) => (
        <Box sx={{ px: 0.75 }}>
          <Button
            variant="contained"
            onClick={() =>
              navigate(`/admin/dashboard/edit-profile/${formattedValue}`)
            }
            startIcon={<EditIcon />}
            sx={{ mr: 1 }}
          >
            Edit Program
          </Button>
        </Box>
      ),
    },
    {
      field: "active",
      headerName: "Enabled",
      width: 100,
      renderCell: ({ formattedValue, id }) => (
        <>
          <Switch
            onChange={() => changeActiveProfile(id, formattedValue)}
            checked={formattedValue}
          />
        </>
      ),
    },
    {
      field: "default",
      headerName: "Default",
      width: 100,
      renderCell: ({ formattedValue, id }) => (
        <>
          <Switch
            disabled
            checked={formattedValue}
          />
        </>
      ),
    },
    {
      field: "name",
      headerName: "Profile Name",
      width: 180,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
  ];
  const onSearchChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setSearchedWord(event.target.value);
  };
  const onSearchSubmit = async (): Promise<void> => {
    try {
      dispatch(setIsLoading(true));

      const requestBody = {
        page: page + 1,
        perPage: 20,
        searchTerms: searchedWord,
      };
      const { data: profiles } = await getAllProfiles(requestBody);
      setRowsData(profiles);

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = handleError(error, navigate, true);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }

      dispatch(setIsLoading(false));
    }
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  
  const changeActiveProfile = async (profileId: any, value: any) => {
    try {
      await changeActive(profileId);
      const requestBody = {
        page: page + 1,
        perPage: 20,
      };
      const { data: profiles } = await getAllProfiles(requestBody);
      setRowsData(profiles);
    } catch (error) {
        const errorMessage = handleError(error, navigate, true);
          if (errorMessage) {
            dispatch(
              showNotification({
                message: errorMessage,
                severity: "error",
                shouldNotify: true,
              })
            );
          }
      }
    }

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));

        const requestBody = {
          page: page + 1,
          perPage: 20,
        };
        const { data: profiles } = await getAllProfiles(requestBody);
        setRowsData(profiles);

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    })();
  }, [dispatch, navigate, page]);

  const render = (
    <Box sx={{ mt: 6, mx: 4, mb: 4 }}>
      <Typography variant="h4">
        <strong>Manage Programs</strong>
      </Typography>
      <Paper sx={{ mt: 6 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mx: 2,
                mt: 1,
              }}
            >
              <Box>
                <Button
                  variant="contained"
                  onClick={() => navigate("/admin/dashboard/add-profile")}
                  startIcon={<AddIcon />}
                >
                  Add Program
                </Button>
              </Box>
              <SearchField
                onChange={onSearchChange}
                onSubmit={onSearchSubmit}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              columns={columns}
              rows={rowsData.profiles}
              rowCount={rowsData.total}
              pageSize={20}
              rowsPerPageOptions={[20]}
              onPageChange={(newPage: number) => setPage(newPage)}
              autoHeight
              pagination
              paginationMode="server"
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
        </Grid>
      </Paper>

    </Box>
  );

  return render;
};

export default ManageProfile;
