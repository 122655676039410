import axios, { AxiosResponse } from "axios";
import { MerchantAccounts } from "../../../../../server/src/admin/merchant/entities/accounts.entity";
import { MerchantCards } from "../../../../../server/src/admin/merchant/entities/cards.entity";
import { CreditReportSettings } from "../../../../../server/src/admin/merchant/entities/credit-report-settings.entity";
import { PlatformAccounts } from "../../../../../server/src/platform/accounts/entities/accounts.entity";
import { PlatformCards } from "../../../../../server/src/platform/cards/entities/cards.entity";
import { PlatformCreditRules } from "../../../../../server/src/platform/underwriting/entities/credit-rules.entity";
import { PlatformPreliminaryCheckRules } from "../../../../../server/src/platform/underwriting/entities/preliminary-check-rules.entity";

import baseUrl from "../../../app.config";
import { getAdminToken } from "../../authentication/helpers";
import { PlatformDataVendors } from "../../../../../server/src/platform/data-vendors/entities/data-vendors.entity";

export const getAllMerchantUsers = async (params: {
  page: number;
  perPage: number;
  search?: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl.server}/api/admin/dashboard/merchant/users`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
    params,
  });
};

export const getMerchantCards = async (): Promise<
  AxiosResponse<MerchantCards[]>
> => {
  return axios.get(`${baseUrl.server}/api/admin/dashboard/merchants/cards`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const getMerchantAccounts = async (): Promise<
  AxiosResponse<MerchantAccounts[]>
> => {
  return axios.get(`${baseUrl.server}/api/admin/dashboard/merchants/accounts`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const getMerchantConsents = async (): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl.server}/api/admin/dashboard/merchants/consents`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const updateMerchantBankingInformation = async (requestBody: {
  cardId?: string;
  accountId?: string;
  isPrimaryDisbursementMethod?: boolean;
  isPrimarySAASFeeMethod?: boolean;
}): Promise<AxiosResponse<any>> => {
  return axios.patch(
    `${baseUrl.server}/api/admin/dashboard/merchants/bankingInformation`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const updateAdminBankingInformation = async (requestBody: {
  cardId?: string;
  accountId?: string;
  isPrimaryDisbursementMethod?: boolean;
  isPrimarySAASFeeMethod?: boolean;
}): Promise<AxiosResponse<any>> => {
  return axios.patch(
    `${baseUrl.server}/api/platform/dashboard/platforms/bankingInformation`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const getMerchantCreditReportSettings = async (): Promise<
  AxiosResponse<CreditReportSettings>
> => {
  return axios.get(
    `${baseUrl.server}/api/admin/dashboard/merchants/settings/creditReport`,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const updateMerchantCreditReportSettings = async (requestBody: {
  pullCreditReports: boolean;
}): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `${baseUrl.server}/api/admin/dashboard/merchants/settings/creditReport`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const getAdminCards = async (): Promise<
  AxiosResponse<PlatformCards[]>
> => {
  return axios.get(`${baseUrl.server}/api/platform/dashboard/platforms/cards`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const getAdminAccounts = async (): Promise<
  AxiosResponse<PlatformAccounts[]>
> => {
  return axios.get(
    `${baseUrl.server}/api/platform/dashboard/platforms/accounts`,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const addPlatformPreliminaryRule = async (
  requestBody: {
    active: boolean;
    bypass: boolean;
    failAction: string;
    failIf: string;
    incomePeriodType?: string;
    name: string;
    periodSelection?: number;
    periodType?: string;
    ruleType: string;
    threshold: number;
  },
  productId: string
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `${baseUrl.server}/api/platform/preliminaryCheckRules/${productId}`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const addMerchantPreliminaryRule = async (requestBody: {
  active: boolean;
  bypass: boolean;
  failAction: string;
  failIf: string;
  incomePeriodType?: string;
  name: string;
  periodSelection?: number;
  periodType?: string;
  ruleType: string;
  threshold: number;
}): Promise<AxiosResponse<void>> => {
  return axios.post(
    `${baseUrl.server}/api/merchant/preliminaryCheckRules`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const addPlatformCreditRule = async (
  requestBody: {
    active: boolean;
    bypass: boolean;
    dollarThreshold?: number;
    failAction: string;
    failIf: string;
    includeMedicalTradeline?: boolean;
    includeStudentLoan?: boolean;
    incomePeriodType?: string;
    lateAttribute?: string;
    name: string;
    periodSelection?: number;
    periodType?: string;
    ruleType: string;
    scoreType?: string;
    threshold: number;
  },
  productId: string
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `${baseUrl.server}/api/platform/creditRules/${productId}`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const addMerchantCreditRule = async (requestBody: {
  active: boolean;
  bypass: boolean;
  dollarThreshold?: number;
  failAction: string;
  failIf: string;
  includeMedicalTradeline?: boolean;
  includeStudentLoan?: boolean;
  incomePeriodType?: string;
  lateAttribute?: string;
  name: string;
  periodSelection?: number;
  periodType?: string;
  ruleType: string;
  scoreType?: string;
  threshold: number;
}): Promise<AxiosResponse<void>> => {
  return axios.post(`${baseUrl.server}/api/merchant/creditRules`, requestBody, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const getPlatformPreliminaryCheckRules = async (
  productId: string
): Promise<AxiosResponse<PlatformPreliminaryCheckRules[]>> => {
  return axios.get(
    `${baseUrl.server}/api/platform/preliminaryCheckRules/${productId}`,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const getMerchantPreliminaryRules = async (): Promise<
  AxiosResponse<PlatformPreliminaryCheckRules[]>
> => {
  return axios.get(`${baseUrl.server}/api/merchant/preliminaryCheckRules`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const getPlatformCreditRules = async (
  productId: string
): Promise<AxiosResponse<PlatformCreditRules[]>> => {
  return axios.get(`${baseUrl.server}/api/platform/creditRules/${productId}`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const getMerchantCreditRules = async (): Promise<
  AxiosResponse<PlatformCreditRules[]>
> => {
  return axios.get(`${baseUrl.server}/api/merchant/creditRules`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const updatePlatformPreliminaryCheckRule = async (
  ruleId: string,
  requestBody: {
    active?: boolean;
    bypass?: boolean;
    failAction?: string;
    failIf?: string;
    incomePeriodType?: string;
    name?: string;
    periodSelection?: number;
    periodType?: string;
    ruleType?: string;
    threshold?: number;
  }
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `${baseUrl.server}/api/platform/preliminaryCheckRules/${ruleId}`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const updateMerchantPreliminaryCheckRule = async (
  ruleId: string,
  requestBody: {
    active?: boolean;
    bypass?: boolean;
    failAction?: string;
    failIf?: string;
    incomePeriodType?: string;
    name?: string;
    periodSelection?: number;
    periodType?: string;
    ruleType?: string;
    threshold?: number;
  }
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `${baseUrl.server}/api/merchant/preliminaryCheckRules/${ruleId}`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const updatePlatformCreditRule = async (
  ruleId: string,
  requestBody: {
    active?: boolean;
    bypass?: boolean;
    dollarThreshold?: number;
    failAction?: string;
    failIf?: string;
    includeMedicalTradeline?: boolean;
    includeStudentLoan?: boolean;
    incomePeriodType?: string;
    lateAttribute?: string;
    name?: string;
    periodSelection?: number;
    periodType?: string;
    ruleType?: string;
    scoreType?: string;
    threshold?: number;
  }
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `${baseUrl.server}/api/platform/creditRules/${ruleId}`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const updateMerchantCreditRule = async (
  ruleId: string,
  requestBody: {
    active?: boolean;
    bypass?: boolean;
    dollarThreshold?: number;
    failAction?: string;
    failIf?: string;
    includeMedicalTradeline?: boolean;
    includeStudentLoan?: boolean;
    incomePeriodType?: string;
    lateAttribute?: string;
    name?: string;
    periodSelection?: number;
    periodType?: string;
    ruleType?: string;
    scoreType?: string;
    threshold?: number;
  }
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `${baseUrl.server}/api/merchant/creditRules/${ruleId}`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const deletePlatformPreliminaryCheckRule = async (
  ruleId: string
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `${baseUrl.server}/api/platform/preliminaryCheckRules/${ruleId}`,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const deleteMerchantPreliminaryCheckRule = async (
  ruleId: string
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `${baseUrl.server}/api/merchant/preliminaryCheckRules/${ruleId}`,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const deletePlatformCreditRule = async (
  ruleId: string
): Promise<AxiosResponse<void>> => {
  return axios.delete(`${baseUrl.server}/api/platform/creditRules/${ruleId}`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const deleteMerchantCreditRule = async (
  ruleId: string
): Promise<AxiosResponse<void>> => {
  return axios.delete(`${baseUrl.server}/api/merchant/creditRules/${ruleId}`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const getDataVendors = async (): Promise<
  AxiosResponse<PlatformDataVendors[]>
> => {
  return axios.get(`${baseUrl.server}/api/platform/dataVendors`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const deleteDataVendor = async (
  id: string
): Promise<AxiosResponse<void>> => {
  return axios.delete(`${baseUrl.server}/api/platform/dataVendors/${id}`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const getDataVendorById = async (
  id: string
): Promise<AxiosResponse<PlatformDataVendors>> => {
  return axios.get(`${baseUrl.server}/api/platform/dataVendors/${id}`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const toggleDataVendor = async (
  id: string
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `${baseUrl.server}/api/platform/dataVendors/toggle/${id}`,
    null,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const editDataVendor = async (
  id: string,
  data: any
): Promise<AxiosResponse<any>> => {
  return axios.patch(`${baseUrl.server}/api/platform/dataVendors/${id}`, data, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const addDataVendor = async (
  data: any
): Promise<AxiosResponse<PlatformDataVendors>> => {
  return axios.post(`${baseUrl.server}/api/platform/dataVendors`, data, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};
