import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  capitalize,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import handleError from "../../../global-helpers/handleError";
import { useAppDispatch } from "../../../global-store/hooks";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { showNotification } from "../../../global-store/notificationSlice";
import { getProfileById, isRegisteredProfile, updateProfile } from "../api";
import { getAllProducts } from "../../products-management/api";

interface ProfileInformationProps {
  profileId?: string;
  onGoBack?: () => void;
  onNext?: (profileInformation: any) => void;
}

const Information = ({ profileId, onNext }: ProfileInformationProps) => {
  const [profileInformationData, setProfileInformationData] = useState<any>();
  const [productProfileList, setProductProfileList] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loadState = async () => {

    const { data: productsList } = await getAllProducts();
    setProductProfileList(productsList.products);
  
    if (profileId) {
      const { data } = await getProfileById(profileId);
      setProfileInformationData(data);

      await formik.setValues(
        {
          name: capitalize(data.profile.name),
          default: data.profile.default,
          productProfileId: data.profile.productProfileId,
        },
        true
      );
    } else {
      setProfileInformationData({
        name: "",
        default: true,
        productProfileId: '',
      });
    }

  };

  const formik = useFormik({
    initialValues: {
      name: "",
      default: false,
      productProfileId: '',
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .trim()
        .required("Program name is required")
        .test(
          "profile-name-test",
          "Profile name already taken",
          async (value: string, context) => {
            if (formik.touched.name && value && value.trim().length > 0) {
              const { data } = await isRegisteredProfile(value);
              if (data.registered) {
                if (
                  profileId &&
                  value.toLocaleLowerCase() === profileInformationData?.name
                ) {
                  return true;
                } else {
                  return false;
                }
              }
            }
            return true;
          }
        ),
      default: yup.boolean().optional(),
      productProfileId: yup.string().trim().required("Product Profile is required")
    }),
    onSubmit: async (values) => {
      try {
        dispatch(setIsLoading(true));
        if (onNext) {
          onNext(values);
        } else if (profileId) {
          const updateObject = {
            ...profileInformationData.profile,
            ...values,
          };
            await updateProfile(profileId, {profile: updateObject});

          dispatch(setIsLoading(false));
          dispatch(
            showNotification({
              message: "Program information updated successfully",
              severity: "success",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));

        await loadState();

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const render = (
    <>
      <Box>
        <Box sx={{ mt: 2 }}>
          <Box>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="PROGRAM NAME"
                    name="name"
                    value={formik.values.name}
                    inputProps={{ maxLength: 30 }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center'}}>
                  <FormControlLabel
                    label={"Default"}
                    control={
                      <Switch
                        name="default"
                        disabled={profileId && formik.values.default ? true : false}
                        onChange={formik.handleChange}
                        checked={formik.values.default}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center'}}>
                <FormControl fullWidth>
                  <InputLabel
                    id="prod-profile-label"
                    error={
                      formik.touched.productProfileId &&
                      Boolean(formik.errors.productProfileId)
                    }
                  >
                    PROFILE
                  </InputLabel>
                  <Select
                    name="productProfileId"
                    label="PRODUCT PROFILE"
                    labelId="prod-profile-label"
                    value={formik.values.productProfileId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.productProfileId &&
                      Boolean(formik.errors.productProfileId)
                    }
                    fullWidth
                  >
                    <MenuItem disabled value="">
                      No Product Profile Selected
                    </MenuItem>
                    {productProfileList &&
                      productProfileList.map((profile) => (
                        <MenuItem key={profile.id} value={profile.id}>
                          {profile.name} - {profile.productType}
                          {profile.default ? " - Default" : ""}
                        </MenuItem>
                      ))}
                  </Select>
                  {formik.touched.productProfileId && formik.errors.productProfileId && (
                    <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required MuiErrorText">
                      {formik.errors.productProfileId}
                    </p>
                  )}
                </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() =>
                        navigate("/admin/dashboard/manage-profile")
                      }
                      sx={{ mr: 4, minWidth: "9.82125rem" }}
                    >
                      Go Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={formik.isSubmitting}
                      sx={{ minWidth: "9.82125rem" }}
                    >
                      {profileId ? "Update" : "Next"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );

  return render;
};

export default Information;
