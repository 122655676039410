import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { Matrix } from '../../../../../../server/src/admin/validation/offer-engine.dto';
import { OfferEngineApi } from '../../../../global-api/OfferEngineApi';
import { useAppDispatch } from '../../../../global-store/hooks';
import { setIsLoading } from '../../../../global-store/loadingSlice';
import { OfferEngine } from './OfferEngine';
import { OfferEngineSettings } from './OfferEngineSettings';

export interface OfferEngineTabsProps {
  productId: string;
}

export const OfferEngineTabs = ({ productId }: OfferEngineTabsProps) => {
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState('Interest Rate');

  const [matrixes, setMatrixes] = useState<Matrix[] | undefined>();

  const getMatrixes = useCallback(async () => {
    dispatch(setIsLoading(true));

    const { data } = await OfferEngineApi.listMatrixes(productId);

    dispatch(setIsLoading(false));

    setMatrixes(data);
  }, [dispatch]);

  useEffect(() => {
    getMatrixes();
  }, [getMatrixes]);

  return matrixes == null ? null : (
    <Box>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(_event, value: string) => setActiveTab(value)}
            sx={{
              variant: {
                xs: 'scrollable',
                sm: 'standard',
              }
            }}
            scrollButtons
            allowScrollButtonsMobile
          >
            {matrixes.map(({ matrix }) => <Tab key={matrix} label={matrix} value={matrix} />)}
            <Tab label='Settings' value='settings' />
          </TabList>

          {matrixes.map(({ matrix }) => (
            <TabPanel key={matrix} value={matrix}>
              <OfferEngine matrix={matrix} onImport={getMatrixes} productId={productId} />
            </TabPanel>
          ))}
          <TabPanel value='settings'>
            <OfferEngineSettings initialMatrixes={matrixes} onSave={() => getMatrixes()} productId={productId} />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
