import { ProductQueryFiltersDTO } from "../../../../../server/src/platform/products-profiles/validation/product-query-filters.dto";

import axios, { AxiosResponse } from "axios";
import baseUrl from "../../../app.config";
import { getAdminToken } from "../../authentication/helpers";
import { AdvancePaymentType } from "../components/PaymentSettings";

export enum ProductTypeEnum {
  LINE_OF_CREDIT = "Line Of Credit",
  INSTALLMENT_LOAN = "Installment Loan",
}

export enum ProductCategoryEnum {
  LMS = "Loan Management System",
  LOS = "Loan Origination System",
}

export const getAllProducts = async (
  params?: ProductQueryFiltersDTO
): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl.server}/api/admin/product/list`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
    params,
  });
};

export const getProductById = async (
  productId: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl.server}/api/admin/product/find/${productId}`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const saveProduct = async (
  product: any
): Promise<AxiosResponse<any>> => {
  if (product.id) {
    return axios.patch(
      `${baseUrl.server}/api/admin/product/${product.id}`,
      { product },
      {
        headers: { Authorization: `Bearer ${getAdminToken()}` },
      }
    );
  } else {
    return axios.post(
      `${baseUrl.server}/api/admin/product`,
      { product },
      {
        headers: {
          Authorization: `Bearer ${getAdminToken()}`,
        },
      }
    );
  }
};

export const deleteProduct = async (
  productId: string
): Promise<AxiosResponse<any>> => {
  return axios.delete(`${baseUrl.server}/api/admin/product/${productId}`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const cloneProduct = async (
  productId: string
): Promise<AxiosResponse<any>> => {
  return axios.post(
    `${baseUrl.server}/api/admin/product/clone/${productId}`,
    null,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const changeActive = async (
  productId: string
): Promise<AxiosResponse<any>> => {
  return axios.patch(
    `${baseUrl.server}/api/admin/product/changeStatus/${productId}`,
    null,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const changeDefault = async (
  productId: string
): Promise<AxiosResponse<any>> => {
  return axios.patch(
    `${baseUrl.server}/api/admin/product/changeDefault/${productId}`,
    null,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const toggleProductKycStatus = async (
  productId: string,
  data: {
    kyc: boolean;
    kycDocsVerification: boolean;
  }
): Promise<AxiosResponse<any>> => {
  return axios.patch(
    `${baseUrl.server}/api/admin/product/kycStatus/${productId}`,
    data,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const updateAdvancePaymentType = async (
  productId: string,
  advancePaymentType: AdvancePaymentType
): Promise<AxiosResponse<any>> => {
  return axios.patch(
    `${baseUrl.server}/api/admin/product/advancePaymentType/${productId}`,
    { advancePaymentType },
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};
