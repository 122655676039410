import { Link } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getYear } from "date-fns";

import BWBlueLogoWithWhiteText from "../../../global-components/logos/BWBlueLogoWithWhiteText";

const Footer = () => {
  const theme = useTheme();
  const isCellphone = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "#0c0c2a",
        color: "primary.contrastText",
      }}
    >
      <Container sx={{ py: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Link to="/">
              <BWBlueLogoWithWhiteText
                sx={{
                  width: isCellphone ? "5.625rem" : "9.93rem",
                  height: isCellphone ? "1.6rem" : "2.6875rem",
                }}
              />
            </Link>
          </Box>
          <Typography fontFamily='"Source Sans Pro", sans-serif'>
            &copy;{getYear(new Date())} Business Warrior Technologies All rights
            reserved
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
