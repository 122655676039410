import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { saveAs } from "file-saver";
import baseUrl from "../../../app.config";

const SaveQRCode = () => {
  const s3bucket = "https://rp-alchemy-bnpl.s3.amazonaws.com";
  const s3Folder = "qr-codes";
  const { merchantUrl, platformUrl } = useParams();

  const saveQRCode = () => {
    saveAs(
      `${baseUrl.server}/api/merchantOnboarding/QRImage/${merchantUrl}`,
      "QR-code.png"
    );
  };

  useEffect(() => {
    saveQRCode();
  });

  const render = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{
          width: "320px",
          border: "2px solid #2163F6",
          borderRadius: "12px",
        }}
        src={`${s3bucket}/${s3Folder}/${platformUrl}/${merchantUrl}.png`}
        alt="qr-code"
      />
    </div>
  );

  return render;
};

export default SaveQRCode;
