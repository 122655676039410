import { SvgIcon, SvgIconProps } from "@mui/material";

const BWWhiteLogoWithWhiteText = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 113 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.0867 9.51654C41.0867 9.41363 41.0506 9.31363 40.9787 9.21727C40.9064 9.12163 40.8204 9.05872 40.7198 9.0289H38.3875V11.6453H40.5901C40.7339 11.6453 40.8526 11.5973 40.9465 11.5009C41.04 11.4049 41.0867 11.2827 41.0867 11.1351V9.51654ZM41.0867 4.39559C41.0867 4.26286 41.04 4.14832 40.9465 4.05195C40.8526 3.95595 40.7339 3.90795 40.5901 3.90795H38.3875V6.23559H40.5901C40.7339 6.23559 40.8526 6.18395 40.9465 6.08032C41.04 5.97741 41.0867 5.85159 41.0867 5.70359V4.39559ZM44.1102 6.63454L43.1385 7.63236L44.1102 8.63017V11.8669C44.1102 12.5764 43.8686 13.1825 43.3865 13.6847C42.904 14.1873 42.31 14.4385 41.605 14.4385H35.3635V2.53345L34.7156 1.13672H41.605C42.31 1.13672 42.904 1.38799 43.3865 1.89054C43.8686 2.39308 44.1102 2.9989 44.1102 3.70836V6.63454Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.6741 1.13672V14.4385H48.411C47.7202 14.4385 47.1332 14.1873 46.6515 13.6847C46.1683 13.1825 45.9278 12.5764 45.9278 11.8669V2.53345L45.2795 1.13672H48.9512V11.1796C48.9512 11.3124 48.9979 11.4273 49.0914 11.5233C49.1849 11.6196 49.3036 11.6673 49.4478 11.6673H51.6508V1.13672H54.6741Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.2144 5.12726V4.39563C62.2144 4.2629 62.1677 4.14836 62.0742 4.05199C61.9803 3.95636 61.8616 3.90799 61.7178 3.90799H60.0118C59.8673 3.90799 59.7486 3.95636 59.6551 4.05199C59.5616 4.14836 59.5148 4.2629 59.5148 4.39563V5.45999C59.5148 5.57854 59.5506 5.68145 59.6232 5.77054C59.6951 5.8589 59.7883 5.91817 59.9038 5.94763L63.2725 6.72363C63.8481 6.85672 64.32 7.15963 64.687 7.63272C65.0543 8.10545 65.2378 8.63781 65.2378 9.22872V11.8669C65.2378 12.5764 64.993 13.1825 64.5038 13.6851C64.0143 14.1876 63.4238 14.4385 62.733 14.4385H58.9966C58.3055 14.4385 57.715 14.1876 57.2258 13.6851C56.7359 13.1825 56.4915 12.5764 56.4915 11.8669V10.2262H59.5148V11.1796C59.5148 11.3127 59.5616 11.4276 59.6551 11.5233C59.7486 11.6196 59.8673 11.6673 60.0118 11.6673H61.7178C61.8616 11.6673 61.9803 11.6196 62.0742 11.5233C62.1677 11.4276 62.2144 11.3127 62.2144 11.1796V9.67236C62.2144 9.55417 62.1783 9.4469 62.1064 9.3509C62.0341 9.2549 61.941 9.19926 61.8258 9.18436L58.4567 8.40836C57.8808 8.27526 57.4096 7.97272 57.0419 7.49926C56.6749 7.02654 56.4915 6.49454 56.4915 5.90326V3.70872C56.4915 2.9989 56.7359 2.39308 57.2258 1.89054C57.715 1.38836 58.3055 1.13672 58.9966 1.13672H62.733C63.4238 1.13672 64.0143 1.38836 64.5038 1.89054C64.993 2.39308 65.2378 2.9989 65.2378 3.70872V5.12726H62.2144Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.0787 14.4385H67.0554V2.53345L66.4075 1.13672H70.0787V14.4385Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.444 1.13672V14.4385H78.6367L75.2892 7.56617V14.4385H72.2658V2.53345L71.6179 1.13672H75.0735L78.4206 8.00908V1.13672H81.444Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.6547 11.6673H91.8381L90.3259 14.4385H83.6313V2.53345L82.9834 1.13672H91.8381L90.3259 3.90799H86.6547V6.23563H90.8442L89.333 9.02908H86.6547V11.6673Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.3785 5.12726V4.39563C99.3785 4.2629 99.3314 4.14836 99.2382 4.05199C99.144 3.95636 99.0253 3.90799 98.8815 3.90799H97.1755C97.0313 3.90799 96.9127 3.95636 96.8188 4.05199C96.7256 4.14836 96.6789 4.2629 96.6789 4.39563V5.45999C96.6789 5.57854 96.7143 5.68145 96.7869 5.77054C96.8588 5.8589 96.952 5.91817 97.0675 5.94763L100.437 6.72363C101.012 6.85672 101.484 7.15963 101.851 7.63272C102.218 8.10545 102.402 8.63781 102.402 9.22872V11.8669C102.402 12.5764 102.157 13.1825 101.668 13.6851C101.178 14.1876 100.588 14.4385 99.8967 14.4385H96.1603C95.4695 14.4385 94.879 14.1876 94.3895 13.6851C93.8999 13.1825 93.6555 12.5764 93.6555 11.8669V10.2262H96.6789V11.1796C96.6789 11.3127 96.7256 11.4276 96.8188 11.5233C96.9127 11.6196 97.0313 11.6673 97.1755 11.6673H98.8815C99.0253 11.6673 99.144 11.6196 99.2382 11.5233C99.3314 11.4276 99.3785 11.3127 99.3785 11.1796V9.67236C99.3785 9.55417 99.342 9.4469 99.2701 9.3509C99.1982 9.2549 99.1047 9.19926 98.9895 9.18436L95.6208 8.40836C95.0445 8.27526 94.5733 7.97272 94.206 7.49926C93.839 7.02654 93.6555 6.49454 93.6555 5.90326V3.70872C93.6555 2.9989 93.8999 2.39308 94.3895 1.89054C94.879 1.38836 95.4695 1.13672 96.1603 1.13672H99.8967C100.588 1.13672 101.178 1.38836 101.668 1.89054C102.157 2.39308 102.402 2.9989 102.402 3.70872V5.12726H99.3785Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.943 5.12726V4.39563C109.943 4.2629 109.896 4.14836 109.802 4.05199C109.709 3.95636 109.59 3.90799 109.446 3.90799H107.74C107.596 3.90799 107.477 3.95636 107.383 4.05199C107.29 4.14836 107.243 4.2629 107.243 4.39563V5.45999C107.243 5.57854 107.279 5.68145 107.351 5.77054C107.423 5.8589 107.517 5.91817 107.632 5.94763L111.001 6.72363C111.576 6.85672 112.048 7.15963 112.415 7.63272C112.783 8.10545 112.966 8.63781 112.966 9.22872V11.8669C112.966 12.5764 112.721 13.1825 112.232 13.6851C111.742 14.1876 111.152 14.4385 110.461 14.4385H106.725C106.034 14.4385 105.443 14.1876 104.954 13.6851C104.464 13.1825 104.22 12.5764 104.22 11.8669V10.2262H107.243V11.1796C107.243 11.3127 107.29 11.4276 107.383 11.5233C107.477 11.6196 107.596 11.6673 107.74 11.6673H109.446C109.59 11.6673 109.709 11.6196 109.802 11.5233C109.896 11.4276 109.943 11.3127 109.943 11.1796V9.67236C109.943 9.55417 109.906 9.4469 109.834 9.3509C109.762 9.2549 109.669 9.19926 109.554 9.18436L106.185 8.40836C105.609 8.27526 105.138 7.97272 104.77 7.49926C104.403 7.02654 104.22 6.49454 104.22 5.90326V3.70872C104.22 2.9989 104.464 2.39308 104.954 1.89054C105.443 1.38836 106.034 1.13672 106.725 1.13672H110.461C111.152 1.13672 111.742 1.38836 112.232 1.89054C112.721 2.39308 112.966 2.9989 112.966 3.70872V5.12726H109.943Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.4815 17.0322L47.4578 30.334H44.3264L42.6848 22.774L41.0653 30.334H37.9335L34.9321 17.0322H38.1712L39.7479 25.3457L41.3462 17.0322H44.0458L45.6654 25.3457L47.2417 17.0322H50.4815Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.7718 24.7917L56.7353 20.0251L55.6989 24.7917H57.7718ZM58.3546 27.5628H55.0935L54.4889 30.334H51.2498L54.5108 18.4286L53.8626 17.0322H58.6787L62.1991 30.334H58.9596L58.3546 27.5628Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.6083 23.6832H69.8113C69.9551 23.6832 70.0738 23.6355 70.168 23.5392C70.2611 23.4428 70.3079 23.321 70.3079 23.1734V20.3134C70.3079 20.1657 70.2611 20.0439 70.168 19.9475C70.0738 19.8515 69.9551 19.8035 69.8113 19.8035H67.6083V23.6832ZM72.9856 24.825C72.755 25.2759 72.4383 25.6417 72.0356 25.9224L73.331 30.334H70.3292L69.1847 26.4544H67.608V30.334H64.5847V18.429L63.9368 17.0322H70.8262C71.517 17.0322 72.1075 17.2839 72.597 17.786C73.0862 18.2886 73.331 18.8948 73.331 19.6039V23.3726C73.331 23.8904 73.2155 24.374 72.9856 24.825Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.9355 23.6832H81.1381C81.2819 23.6832 81.4006 23.6355 81.4948 23.5392C81.5879 23.4428 81.6347 23.321 81.6347 23.1734V20.3134C81.6347 20.1657 81.5879 20.0439 81.4948 19.9475C81.4006 19.8515 81.2819 19.8035 81.1381 19.8035H78.9355V23.6832ZM84.3129 24.825C84.0819 25.2759 83.7652 25.6417 83.3625 25.9224L84.6583 30.334H81.6565L80.5116 26.4544H78.9353V30.334H75.9116V18.429L75.2637 17.0322H82.1531C82.8442 17.0322 83.4344 17.2839 83.9239 17.786C84.4135 18.2886 84.6583 18.8948 84.6583 19.6039V23.3726C84.6583 23.8904 84.5428 24.374 84.3129 24.825Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.2624 30.334H87.2391V18.429L86.5908 17.0322H90.2624V30.334Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.6024 20.2917C98.6024 20.1589 98.5553 20.0444 98.4621 19.948C98.3679 19.8524 98.2492 19.804 98.1058 19.804H96.3994C96.2553 19.804 96.1366 19.8524 96.0431 19.948C95.9496 20.0444 95.9028 20.1589 95.9028 20.2917V27.0757C95.9028 27.2088 95.9496 27.3233 96.0431 27.4193C96.1366 27.5157 96.2553 27.5637 96.3994 27.5637H98.1058C98.2492 27.5637 98.3679 27.5157 98.4621 27.4193C98.5553 27.3233 98.6024 27.2088 98.6024 27.0757V20.2917ZM100.891 17.7867C101.381 18.2892 101.626 18.8954 101.626 19.6045V27.763C101.626 28.4725 101.381 29.0787 100.891 29.5808C100.402 30.0834 99.8043 30.3347 99.099 30.3347H95.3845C94.6789 30.3347 94.0848 30.0834 93.6027 29.5808C93.1199 29.0787 92.8794 28.4725 92.8794 27.763V19.6045C92.8794 18.8954 93.1199 18.2892 93.6027 17.7867C94.0848 17.2845 94.6789 17.0332 95.3845 17.0332H99.099C99.8043 17.0332 100.402 17.2845 100.891 17.7867Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.243 23.6832H109.446C109.589 23.6832 109.708 23.6355 109.802 23.5392C109.895 23.4428 109.942 23.321 109.942 23.1734V20.3134C109.942 20.1657 109.895 20.0439 109.802 19.9475C109.708 19.8515 109.589 19.8035 109.446 19.8035H107.243V23.6832ZM112.62 24.825C112.39 25.2759 112.073 25.6417 111.67 25.9224L112.966 30.334H109.964L108.819 26.4544H107.243V30.334H104.219V18.429L103.571 17.0322H110.46C111.151 17.0322 111.742 17.2839 112.232 17.786C112.721 18.2886 112.966 18.8948 112.966 19.6039V23.3726C112.966 23.8904 112.85 24.374 112.62 24.825Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5643 0.363281C10.8331 0.363281 9.17673 0.724736 7.67337 1.37783C5.41656 2.3571 3.50087 3.99055 2.147 6.0491C0.792417 8.1051 0 10.5953 0 13.2611V25.0233C0 25.7927 0.397803 26.5036 1.04534 26.8916L9.25998 31.8109C10.1059 32.3171 11.1682 31.6909 11.1682 30.6858V18.5156C11.1682 17.328 10.4279 16.2738 9.33012 15.8986L6.4952 14.9291C6.04674 14.776 5.58411 15.1186 5.58411 15.6036V17.1262C5.58411 17.4316 5.77469 17.7036 6.05772 17.8004L7.55081 18.3113C8.04355 18.48 8.37617 18.9535 8.37617 19.4869V27.58C8.37617 27.7498 8.19657 27.8553 8.05382 27.7698L3.11476 24.812C2.91498 24.6927 2.79206 24.4731 2.79206 24.2353V13.2611C2.79206 11.8724 3.06517 10.5556 3.56003 9.35674C4.29967 7.55892 5.5409 6.02401 7.10164 4.9411C8.66345 3.86001 10.5352 3.22946 12.5643 3.22946C13.9171 3.22946 15.1994 3.50946 16.3676 4.01746C18.119 4.7771 19.6145 6.05092 20.6691 7.65274C21.7222 9.25601 22.3365 11.1782 22.3365 13.2611V24.2353C22.3365 24.4731 22.2135 24.6927 22.0141 24.812L17.0747 27.7698C16.9319 27.8553 16.7523 27.7498 16.7523 27.58V19.4869C16.7523 18.9535 17.085 18.48 17.5777 18.3113L19.0708 17.8004C19.3538 17.7036 19.5444 17.4316 19.5444 17.1262V15.6036C19.5444 15.1186 19.0818 14.776 18.633 14.9291L15.7984 15.8986C14.7006 16.2738 13.9603 17.328 13.9603 18.5156V30.6858C13.9603 31.6909 15.0223 32.3171 15.8682 31.8109L24.0835 26.8916C24.7307 26.5036 25.1285 25.7927 25.1285 25.0233V13.2611C25.1285 11.484 24.776 9.78328 24.1406 8.24037C23.1863 5.92365 21.595 3.9571 19.5894 2.56692C17.5869 1.17674 15.1608 0.363281 12.5643 0.363281Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default BWWhiteLogoWithWhiteText;
