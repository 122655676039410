import axios, { AxiosResponse } from "axios";

import baseUrl from "../../../app.config";
import getPlatformAdminToken from "../../authentication/helpers/getPlatformAdminToken";
import { IInformation } from "../interfaces/IInformation";
import { IProductSettings } from "../interfaces/IProductSettings";
import { ITenant } from "../interfaces/ITenant";

export const getAllPlatforms = async (params: {
  page: number;
  perPage: number;
  search?: string;
}): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl.server}/api/platform/dashboard/platforms`, {
    headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    params,
  });
};

export const isRegisteredPlatform = async (
  name?: string,
  email?: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl.server}/api/platforms/registered`, {
    params: {
      name,
      email,
    },
  });
};

export const addTenant = async (
  requestBody: ITenant
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `${baseUrl.server}/api/platform/dashboard/platforms`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    }
  );
};

export const updatePlatformInformation = async (
  id: string,
  requestBody: IInformation
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `${baseUrl.server}/api/platform/dashboard/platforms/${id}/information`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    }
  );
};

export const updateProductSettings = async (
  id: string,
  requestBody: IProductSettings
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `${baseUrl.server}/api/admin/dashboard/platform/${id}/productSettings`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    }
  );
};

export const loginAsTenant = async (requestBody: {
  platformId: string;
  email: string;
}): Promise<AxiosResponse<any>> => {
  return axios.post(
    `${baseUrl.server}/api/platform/admin/loginAsTenant`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    }
  );
};

export const getPlatformCards = async (
  platformId: string
): Promise<AxiosResponse<any>> => {
  return axios.get(
    `${baseUrl.server}/api/platform/dashboard/platforms/${platformId}/cards`,
    {
      headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    }
  );
};

export const getPlatformAccounts = async (
  platformId: string
): Promise<AxiosResponse<any>> => {
  return axios.get(
    `${baseUrl.server}/api/platform/dashboard/platforms/${platformId}/accounts`,
    {
      headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    }
  );
};

export const updateBankingInformationByPlatformId = async (
  platformId: string,
  requestBody: {
    cardId?: string;
    accountId?: string;
    isPrimaryDisbursementMethod?: boolean;
    isPrimarySAASFeeMethod?: boolean;
  }
): Promise<AxiosResponse<any>> => {
  return axios.patch(
    `${baseUrl.server}/api/platform/dashboard/platforms/${platformId}/bankingInformation`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    }
  );
};

export const getTenantSAASFee = async (
  platformId: string
): Promise<AxiosResponse<any>> => {
  return axios.get(
    `${baseUrl.server}/api/admin/dashboard/platform/${platformId}/saasFee`,
    {
      headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    }
  );
};

export const makePayment = async (
  platformId: string,
  requestBody: {
    accountId?: string;
    amount?: number;
    customerVaultId?: string;
    paymentDate: Date;
    category: "saas fee" | "other";
    discountPercentage: number;
    numberOfMonthsToPay?: number;
    paymentDescription?: string;
    recurringAmount?: number;
    recurringPaymentDay?: number;
  }
) => {
  return axios.post(
    `${baseUrl.server}/api/admin/dashboard/merchants/${platformId}/payments`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    }
  );
};

export const updateTenantSAASFeeSettings = async (
  platformId: string,
  requestBody: {
    amount?: number;
    enabled: boolean;
    nextPaymentDate?: Date;
  }
) => {
  return axios.patch(
    `${baseUrl.server}/api/admin/dashboard/platform/${platformId}/saasFee`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
    }
  );
};

export const getRolesPlatform = async (): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl.server}/api/admin/roles`, {
    headers: { Authorization: `Bearer ${getPlatformAdminToken()}` },
  });
};
