import { useState, SyntheticEvent, useEffect } from "react";
import { Box, Tab, useTheme, useMediaQuery } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import PreliminaryCheckRules from "../components/tabs/PreliminaryCheckRules";
import CreditRules from "../components/tabs/CreditRules";

interface RulesProps {
  productId?: string;
}

const Rules = ({productId}: RulesProps) => {
  const [activeTab, setActiveTab] = useState("1");
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const onTabChange = (_event: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const render = (
    <Box>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={onTabChange}
            variant={isLargeScreen ? "standard" : "scrollable"}
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Preliminary Check Rules" value="1" />
            <Tab label="Credit Rules" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <PreliminaryCheckRules productId={productId} />
        </TabPanel>
        <TabPanel value="2">
          <CreditRules productId={productId} />
        </TabPanel>
      </TabContext>
    </Box>
  );

  return render;
};

export default Rules;
