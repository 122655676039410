import { Navigate, useLocation } from "react-router-dom";

import { isPlatformAdminAuthenticated } from "../../../admin-dashboard/authentication/helpers";

const RequirePlatformAdminAuthentication = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const isAuthenticated = isPlatformAdminAuthenticated();
  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/platform/login" state={{ from: location }} />
  );
};

export default RequirePlatformAdminAuthentication;
