const environment = process.env.REACT_APP_NODE_ENV;

const baseUrl = environment === 'staging'
  ? {
      server: 'https://api.staging.payplan.ai',
      client: 'https://staging.payplan.ai',
    }
  : environment === 'demo'
  ? {
      server: 'https://api.demo.payplan.ai',
      client: 'https://demo.payplan.ai',
    }
  : environment === 'production'
  ? {
      server: 'https://api.app.payplan.ai',
      client: 'https://app.payplan.ai',
    }
  : {
      server: 'http://localhost:3001',
      client: 'http://localhost:3000',
    };

export default baseUrl;
