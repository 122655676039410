import getAdminRoles from "../global-helpers/getAdminRoles";
import getAdminJWTData from "../global-helpers/getAdminJWTData";

const isMerchant = (): boolean => {
  const adminData = getAdminJWTData();
  const adminRoles = getAdminRoles();
  const isMerchant: boolean = adminData?.role === adminRoles.Merchant;

  return isMerchant;
};

export default isMerchant;
