import {
  Modal,
  Fade,
  Box,
  Backdrop,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material'

import modalStyles from '../../../global-helpers/modalStyles'

interface ConfirmProps {
  open: boolean
  title: string
  description?: string
  onConfirm: () => void
  onCancel: () => void
}

const Confirm = ({
  open,
  title,
  description,
  onConfirm,
  onCancel,
}: ConfirmProps) => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Modal
      open={open}
      onClose={onCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...modalStyles,
            overflow: 'auto',
            maxHeight: '90%',
            minWidth: '400px',
            maxWidth: isLargeScreen ? '75%' : '100%',
          }}
        >
          <div
            style={{
              ...(!isLargeScreen && { flexWrap: 'wrap' }),
              display: 'flex',
              alignItems: 'end',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h3 style={{ textAlign: 'center' }}>{title.toUpperCase()}</h3>
              {description && (
                <p style={{ marginTop: '0px' }}>
                  <strong>{description}</strong>
                </p>
              )}
            </div>
          </div>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant="contained" onClick={onConfirm}>
              YES
            </Button>
            <Button variant="outlined" onClick={onCancel}>
              NO
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default Confirm
