import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useEffect, useRef } from "react";

const PrintQRCode = () => {
  const s3bucket = "https://rp-alchemy-bnpl.s3.amazonaws.com";
  const s3Folder = "qr-codes";
  const { merchantUrl, platformUrl } = useParams();
  const QRCodeRef = useRef(null);

  const printQRCode = useReactToPrint({
    content: () => QRCodeRef.current,
    pageStyle: `@page {padding: 24px}`,
  });

  useEffect(() => {
    printQRCode();
  });

  const render = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        ref={QRCodeRef}
        style={{
          width: "320px",
          border: "2px solid #2163F6",
          borderRadius: "12px",
        }}
        src={`${s3bucket}/${s3Folder}/${platformUrl}/${merchantUrl}.png`}
        alt="qr-code"
      />
    </div>
  );

  return render;
};

export default PrintQRCode;
