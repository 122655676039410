import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../global-store";

interface MerchantOnboardingLayout {
  step: number;
  isLoading: boolean;
}

const initialState: MerchantOnboardingLayout = {
  step: 0,
  isLoading: false,
};

export const layoutSlice = createSlice({
  name: "merchant-onboarding-layout",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
  },
});

export const { setStep } = layoutSlice.actions;
export const selectStep = (state: RootState) =>
  state.merchantOnboardingLayout.step;
export const selectIsLoading = (state: RootState) =>
  state.merchantOnboardingLayout.isLoading;

export default layoutSlice.reducer;
