const getAdminJWTData = ():
  | {
      email: string;
      id: string;
      merchant: string;
      platform: string;
      role: string;
      userName: string;
    }
  | undefined => {
  const item: string = localStorage.getItem("adminToken");
  if (item) {
    const adminToken = JSON.parse(localStorage.getItem("adminToken"));
    return {
      email: adminToken.email,
      id: adminToken.id,
      merchant: adminToken.merchant,
      platform: adminToken.platform,
      role: adminToken.role,
      userName: adminToken.userName,
    };
  }
};

export default getAdminJWTData;
