const getPlatformAdminToken = (): string | undefined => {
  const platformAdminToken = localStorage.getItem("platformAdminToken");
  if (!platformAdminToken) {
    return;
  } else {
    const { token } = JSON.parse(platformAdminToken);

    return token;
  }
};

export default getPlatformAdminToken;
