import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../global-store";

interface ApplicationLayout {
  step: number;
  isLoading: boolean;
}

const initialState: ApplicationLayout = {
  step: 0,
  isLoading: false,
};

export const layoutSlice = createSlice({
  name: "user-layout",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
  },
});

export const { setStep } = layoutSlice.actions;
export const selectStep = (state: RootState) => state.userLayout.step;
export const selectIsLoading = (state: RootState) => state.userLayout.isLoading;

export default layoutSlice.reducer;
