import {
  Button,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Matrix } from "../../../../../../server/src/admin/validation/offer-engine.dto";
import { OfferEngineApi } from "../../../../global-api/OfferEngineApi";
import { useAppDispatch } from "../../../../global-store/hooks";
import { setIsLoading } from "../../../../global-store/loadingSlice";
import { showNotification } from "../../../../global-store/notificationSlice";
import handleError from "../../../../global-helpers/handleError";
import { useNavigate } from "react-router-dom";

const AXIS_VALUES = [
  {
    name: "Credit Score",
    value: "CREDIT_SCORE",
  },
  {
    name: "Income",
    value: "INCOME",
  },
  {
    name: "DTI (Decimal)",
    value: "DTI",
  },
  {
    name: "Late Payment",
    value: "LATE_PAYMENT",
  },
  {
    name: "Charge-Off Count",
    value: "CHARGEOFF_COUNT",
  },
  {
    name: "Charge-Off Amount",
    value: "CHARGEOFF_AMOUNT",
  },
  {
    name: "Bankruptcy",
    value: "BANKRUPTCY",
  },
  {
    name: "Foreclosures",
    value: "FORECLOSURES",
  },
  {
    name: "Months of Credit History",
    value: "MONTHS_OF_CREDIT_HISTORY",
  },
  {
    name: "Active Trades",
    value: "ACTIVE_TRADES",
  },
  {
    name: "Revolving Trades",
    value: "REVOLVING_TRADES",
  },
  {
    name: "Inquiries",
    value: "INQUIRIES",
  },
  {
    name: "Utilization of Credit",
    value: "UTILIZATION_OF_CREDIT",
  },
  {
    name: "Collections Count",
    value: "COLLECTIONS_COUNT",
  },
  {
    name: "Collections Amount",
    value: "COLLECTIONS_AMOUNT",
  },
];

export interface OfferEngineSettingsProps {
  initialMatrixes: Matrix[];
  onSave?(): void;
  productId: string;
}

export const OfferEngineSettings = ({
  initialMatrixes,
  onSave,
  productId,
}: OfferEngineSettingsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [matrixes, setMatrixes] = useState<Matrix[]>(initialMatrixes);

  useEffect(() => {
    setMatrixes(initialMatrixes);
  }, [initialMatrixes]);

  const handleUpdateMatrix = useCallback(
    (newMatrix: Matrix, index: number) => {
      setMatrixes(
        matrixes.map((matrix, idx) => (index === idx ? newMatrix : matrix))
      );
    },
    [matrixes]
  );

  const handleDeleteMatrix = useCallback(
    (index: number) => {
      setMatrixes(matrixes.filter((matrix, idx) => index !== idx));
    },
    [matrixes]
  );

  const handleAddMatrix = useCallback(() => {
    setMatrixes([
      ...matrixes,
      { matrix: "", enabled: true, xType: null, yType: null },
    ]);
  }, [matrixes]);

  const handleSave = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));
      await OfferEngineApi.updateMatrixes(productId, { matrixes });
      dispatch(
        showNotification({
          message: "Matrixes updated successfully",
          severity: "success",
          shouldNotify: true,
        })
      );
      onSave?.();
    } catch (e) {
      const errorMessage = handleError(e, navigate);
      dispatch(
        showNotification({
          message: errorMessage,
          severity: "error",
          shouldNotify: true,
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  }, [dispatch, matrixes, onSave]);

  const handleCancel = useCallback(() => {
    setMatrixes(initialMatrixes);
  }, [initialMatrixes]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3} justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddMatrix}
          disabled={matrixes.some(({ matrix }) => matrix.trim() === "")}
        >
          Add Matrix
        </Button>
      </Stack>

      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Enabled</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Matrix</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>X-axis</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Y-axis</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matrixes.map((matrix, index) => {
              const readonly = [
                "Interest Rate",
                "Loan Limit",
                "Loan Term",
              ].includes(matrix.matrix);

              return (
                <TableRow key={matrix.matrix}>
                  <TableCell>
                    <Switch
                      onChange={() =>
                        handleUpdateMatrix(
                          { ...matrix, enabled: !matrix.enabled },
                          index
                        )
                      }
                      checked={matrix.enabled}
                      disabled={readonly}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteMatrix(index)}
                      disabled={readonly}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </TableCell>
                  <TableCell>
                    <TextField
                      sx={{ width: 200 }}
                      size="small"
                      onBlur={(event) => {
                        const value = event.target.value;
                        handleUpdateMatrix({ ...matrix, matrix: value }, index);
                      }}
                      variant="outlined"
                      defaultValue={matrix.matrix}
                      disabled={readonly}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      sx={{ width: 200 }}
                      size="small"
                      value={matrix.xType}
                      onChange={(event) => {
                        const value = event.target.value as any;
                        handleUpdateMatrix({ ...matrix, xType: value }, index);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {AXIS_VALUES.filter(
                        ({ value }) => value !== matrix.yType
                      ).map(({ name, value }) => (
                        <MenuItem key={value} value={value}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      sx={{ width: 200 }}
                      size="small"
                      value={matrix.yType}
                      onChange={(event) => {
                        const value = event.target.value as any;
                        handleUpdateMatrix({ ...matrix, yType: value }, index);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {AXIS_VALUES.filter(
                        ({ value }) => value !== matrix.xType
                      ).map(({ name, value }) => (
                        <MenuItem key={value} value={value}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" spacing={3} justifyContent="center">
        <Button variant="outlined" onClick={handleCancel}>
          Cancel Changes
        </Button>

        <Button color="primary" variant="contained" onClick={handleSave}>
          Update Matrixes
        </Button>
      </Stack>
    </Stack>
  );
};
