import axios, { AxiosResponse } from 'axios';
import { getAdminToken } from '../admin-dashboard/authentication/helpers';
import baseUrl from '../app.config';
import { UpdateMatrixesDto } from '../../../server/src/admin/validation/offer-engine.dto';

export class OfferEngineApi {
  static listMatrixes = async (productId: string): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${baseUrl.server}/api/admin/offer-engine/${productId}/matrixes`,
      {
        headers: { Authorization: `Bearer ${getAdminToken()}` },
      }
    );
  };

  static updateMatrixes = async (productId: string, requestBody: UpdateMatrixesDto): Promise<AxiosResponse<any>> => {
    return axios.put(
      `${baseUrl.server}/api/admin/offer-engine/${productId}/matrixes`,
      requestBody,
      {
        headers: { Authorization: `Bearer ${getAdminToken()}` },
      }
    );
  };

  static getData = async (
    productId: string,
    requestParams: {
      matrix: string;
      exportType: 'json' | 'csv' | 'excel';
    },
  ): Promise<AxiosResponse<any>> => {
    return axios.get(
      `${baseUrl.server}/api/admin/offer-engine/${productId}/export`,
      {
        params: requestParams,
        responseType: requestParams.exportType === 'json' ? undefined : 'blob',
        headers: { Authorization: `Bearer ${getAdminToken()}` },
      }
    );
  };

  static updateData = async (
    productId: string,
    requestBody: {
      matrix: string;
      file: File;
    },
  ): Promise<AxiosResponse<any>> => {
    const formData = new FormData();
    formData.append('matrix', requestBody.matrix);
    formData.append('file', requestBody.file);

    return axios.put(
      `${baseUrl.server}/api/admin/offer-engine/${productId}/import`,
      formData,
      {
        transformRequest: () => formData,
        headers: {
          Authorization: `Bearer ${getAdminToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  };
}
