import {
  Box,
  Button,
  FormControl,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { getProductById, toggleProductKycStatus } from "../api";
import * as yup from "yup";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { showNotification } from "../../../global-store/notificationSlice";
import handleError from "../../../global-helpers/handleError";
import { useAppDispatch } from "../../../global-store/hooks";
import { useNavigate } from "react-router-dom";

interface ProfileIdentityKycProps {
  productId?: string;
  onGoBack?: () => void;
}

const IdentityKyc = ({ productId, onGoBack }: ProfileIdentityKycProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loadState = async () => {
    if (productId) {
      const { data } = await getProductById(productId);

      await formik.setValues(
        {
          instntVerification: data.product.kyc,
          instntDocsVerification: data.product.kycDocsVerification,
        },
        true
      );
    } else {
      await formik.setValues(
        {
          instntVerification: false,
          instntDocsVerification: false,
        },
        true
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      instntVerification: false,
      instntDocsVerification: false,
    },
    validationSchema: yup.object({
      instntVerification: yup.boolean().required(),
      instntDocsVerification: yup.boolean().required(),
    }),
    onSubmit: async ({ instntVerification, instntDocsVerification }) => {
      try {
        dispatch(setIsLoading(true));
        await toggleProductKycStatus(productId, {
          kyc: instntVerification,
          kycDocsVerification: instntDocsVerification,
        });
        dispatch(setIsLoading(false));
        dispatch(
          showNotification({
            message: "Identity / KYC information updated successfully",
            severity: "success",
            shouldNotify: true,
          })
        );
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }
        dispatch(setIsLoading(false));
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));
        await loadState();
        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }
        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (!formik.values.instntVerification) {
      formik.values.instntDocsVerification = false;
    }
  }, [formik.values.instntVerification]);

  const render = (
    <form onSubmit={formik.handleSubmit} noValidate>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>Instnt Verification</strong>
              </TableCell>

              <TableCell>
                <FormControl>
                  <Switch
                    name={"instntVerification"}
                    checked={formik.values.instntVerification}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Instnt Docs Verification</strong>
              </TableCell>

              <TableCell>
                <FormControl>
                  <Switch
                    disabled={!formik.values.instntVerification}
                    name={"instntDocsVerification"}
                    checked={formik.values.instntDocsVerification}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="outlined"
          onClick={() => onGoBack()}
          sx={{ mr: 4, minWidth: "9.82125rem" }}
        >
          Close
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={formik.isSubmitting}
          sx={{ minWidth: "9.82125rem" }}
        >
          Update
        </Button>
      </Box>
    </form>
  );
  return render;
};

export default IdentityKyc;
