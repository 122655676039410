import axios, { AxiosResponse } from "axios";

import baseUrl from "../app.config";
import { getUserToken } from "../user-application/authentication/helpers";
import { getAdminToken } from "../admin-dashboard/authentication/helpers";
import getPlatformAdminToken from "../platform-dashboard/authentication/helpers/getPlatformAdminToken";
import { UserAccounts } from "../../../server/src/user/accounts/entities/accounts.entity";
import { UserCards } from "../../../server/src/user/cards/entities/cards.entity";
import { Merchant } from "../../../server/src/admin/merchant/entities/merchant.entity";
import { Platform } from "../../../server/src/platform/entities/platform.entity";
import { PlatformDataVendors } from "../../../server/src/platform/data-vendors/entities/data-vendors.entity";
import { DataVendorNames } from "../global-helpers/DataVendorNames.enum";

export const createUser = async (requestBody: {
  city: string;
  dateOfBirth: Date;
  email: string;
  firstName: string;
  isBackendApplication?: boolean;
  lastName: string;
  monthlyIncome: number;
  password: string;
  phones: { phone: string; type: "mobile" | "home" | "office" }[];
  merchant?: string;
  platformId: string;
  ssnNumber: string;
  state: string;
  unitApt?: string;
  street: string;
  zipCode: string;
}): Promise<AxiosResponse<any>> => {
  return axios.post(`${baseUrl.server}/api/application/apply`, requestBody);
};

export const isRegisteredMerchant = async (
  platformId: string,
  name?: string,
  email?: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl.server}/api/merchants/${platformId}/registered`, {
    params: {
      name,
      email,
    },
  });
};

export const addAccount = async (requestBody: {
  accountHolder: string;
  accountNumber: string;
  bankName: string;
  routingNumber: string;
  isDefaultPaymentMethod: boolean;
}): Promise<AxiosResponse<any>> => {
  return axios.post(
    `${baseUrl.server}/api/application/users/accounts`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    }
  );
};

export const getUserAccounts = async (): Promise<
  AxiosResponse<UserAccounts[]>
> => {
  return axios.get(`${baseUrl.server}/api/application/users/accounts`, {
    headers: { Authorization: `Bearer ${getUserToken()}` },
  });
};

export const addCard = async (requestBody: {
  billingAddress: string;
  billingCity: string;
  billingFirstName: string;
  billingLastName: string;
  billingState: string;
  billingZip: string;
  cvv: string;
  cardholderName: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  isDefaultPaymentMethod?: boolean;
}): Promise<AxiosResponse<any>> => {
  return axios.post(
    `${baseUrl.server}/api/application/users/cards`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getUserToken()}` },
    }
  );
};

export const getUserCards = async (): Promise<AxiosResponse<UserCards[]>> => {
  return axios.get(`${baseUrl.server}/api/application/users/cards`, {
    headers: { Authorization: `Bearer ${getUserToken()}` },
  });
};

export const getUserCardsByScreenTrackingId = async (
  screenTrackingId: string
): Promise<AxiosResponse<any>> => {
  return axios.get(
    `${baseUrl.server}/api/admin/dashboard/users/cards/${screenTrackingId}`,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const getUserAccountsByScreenTrackingId = async (
  screenTrackingId: string
): Promise<AxiosResponse<any>> => {
  return axios.get(
    `${baseUrl.server}/api/admin/dashboard/users/accounts/${screenTrackingId}`,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const getMerchantByURL = async (
  platformId: string,
  url: string
): Promise<AxiosResponse<Merchant>> => {
  return axios.get(
    `${baseUrl.server}/api/admin/dashboard/merchants/${platformId}/url/${url}`
  );
};

export const getApplicationData = async (): Promise<AxiosResponse<any>> => {
  return axios.get(`${baseUrl.server}/api/application/user`, {
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
    },
  });
};

export const saveEFTA = async (requestBody: {
  customerVaultId?: string;
  accountNumber?: string;
}): Promise<AxiosResponse<any>> => {
  return axios.post(`${baseUrl.server}/api/application/efta`, requestBody, {
    headers: { Authorization: `Bearer ${getUserToken()}` },
  });
};

export const saveEFTAByScreenTrackingId = async (
  screenTracking: string,
  requestBody: {
    customerVaultId?: string;
    accountNumber?: string;
  }
): Promise<AxiosResponse<any>> => {
  return axios.post(
    `${baseUrl.server}/api/admin/dashboard/loans/efta/${screenTracking}`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const addMerchantCard = async (requestBody: {
  billingAddress: string;
  billingCity: string;
  billingFirstName: string;
  billingLastName: string;
  billingState: string;
  billingZip: string;
  cvv: string;
  cardholderName: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  isPrimaryDisbursementMethod: boolean;
}): Promise<AxiosResponse<any>> => {
  return axios.post(
    `${baseUrl.server}/api/admin/dashboard/merchants/cards`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const getApplicationDataByScreenTrackingId = async (
  screenTrackingId: string
): Promise<AxiosResponse<any>> => {
  return axios.get(
    `${baseUrl.server}/api/admin/dashboard/application/info/${screenTrackingId}`,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const getMerchantById = async (
  id: string
): Promise<AxiosResponse<Merchant>> => {
  return axios.get(`${baseUrl.server}/api/admin/dashboard/merchants/${id}`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const addMerchantAccountByMerchantId = async (
  merchantId: string,
  requestBody: {
    accountHolder: string;
    accountNumber: string;
    bankName: string;
    isPrimaryDisbursementMethod?: boolean;
    isPrimarySAASFeeMethod?: boolean;
    routingNumber: string;
  }
): Promise<AxiosResponse<any>> => {
  return axios.post(
    `${baseUrl.server}/api/admin/dashboard/merchants/${merchantId}/accounts`,
    requestBody,
    {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
    }
  );
};

export const getMerchantByJWT = async (): Promise<AxiosResponse<Merchant>> => {
  return axios.get(`${baseUrl.server}/api/admin/dashboard/merchant`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const getPlatformByJWT = async (): Promise<AxiosResponse<Platform>> => {
  return axios.get(`${baseUrl.server}/api/platform/dashboard/platform`, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
};

export const getPlatFormByURL = async (
  url: string
): Promise<AxiosResponse<Platform>> => {
  return axios.get(`${baseUrl.server}/api/platforms/url/${url}`);
};

export const getPlatFormById = async (
  id: string
): Promise<AxiosResponse<Platform>> => {
  const userToken: string = getUserToken();
  const adminToken: string = getAdminToken();
  const platformAdminToken: string = getPlatformAdminToken();

  let token: string;
  if (platformAdminToken) {
    token = platformAdminToken;
  } else if (adminToken) {
    token = adminToken;
  } else {
    token = userToken;
  }

  return axios.get(`${baseUrl.server}/api/platforms/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getDataVendorByName = async (
  platform: string,
  name: DataVendorNames
): Promise<AxiosResponse<PlatformDataVendors>> => {
  return axios.get(
    `${baseUrl.server}/api/platform/dataVendors/${platform}/${name}`
  );
};
