import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";

import store from "./global-store";
import Routes from "./Routes";

const theme = createTheme({
  palette: {
    primary: {
      main: "#024cd0",
    },
    secondary: {
      main: "#f8f8f8",
      contrastText: "#767676",
    },
  },
  typography: {
    fontFamily: '"Red Hat Display", sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 870,
      lg: 1200,
      xl: 1536,
    },
  },
});

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
