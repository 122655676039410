const capitalize = (value: string) => {
  const splittedValue = value.toLowerCase().split(" ");

  const result = splittedValue
    .map((value) => {
      const fistLetter = value.charAt(0);
      return `${fistLetter.toUpperCase()}${value.slice(1)}`;
    })
    .join(" ");

  return result;
};

export default capitalize;
