import { useState, useEffect } from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";

import { useAppSelector } from "../../../global-store/hooks";

const Steps = () => {
  const step = useAppSelector((state) => state.userLayout.step);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const stepLabels = [
    "Personal Information",
    "Open Banking",
    "Select Offer",
    "Review Application",
    "Sign Contract",
    "Payment Method",
  ];

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, [windowWidth]);

  return (
    <Box sx={{ my: 7, display: "flex", justifyContent: "center" }}>
      <Stepper
        alternativeLabel={windowWidth >= 500}
        orientation={windowWidth >= 500 ? "horizontal" : "vertical"}
        activeStep={step}
      >
        {stepLabels.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default Steps;
