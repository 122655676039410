const getAdminRoles = () => {
  return {
    Manager: "Manager",
    Merchant: "Merchant",
    SuperAdmin: "Super Admin",
    MerchantStaff: "Merchant Staff",
    PlatformAdmin: "Platform Admin",
  };
};

export default getAdminRoles;
