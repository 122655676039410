import { InCreateProfileDTO } from './../../../../../server/src/admin/profile/validation/in-create-profile.dto';
import { QueryFiltersDTO } from './../../../../../server/src/admin/profile/validation/query-filters.dto';
import axios, { AxiosResponse } from "axios";
import baseUrl from "../../../app.config";
import { getAdminToken } from "../../authentication/helpers";

export const isRegisteredProfile = async (
    name?: string,
  ): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseUrl.server}/api/admin/profile/registered`, {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
      params: {
        name,
      },
    });
  };

export const getAllProfiles = async (
    params?: QueryFiltersDTO,
  ): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseUrl.server}/api/admin/profile/list`, {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
      params
    });
  };

export const getProfileById = async (
    profileId: string,
    params?: QueryFiltersDTO,
  ): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseUrl.server}/api/admin/profile/find/${profileId}`, {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
      params
    });
  };

export const getMerchantsProfileById = async (
    profileId: string,
    params?: QueryFiltersDTO,
  ): Promise<AxiosResponse<any>> => {
    return axios.get(`${baseUrl.server}/api/admin/profile/find/merchants/${profileId}`, {
      headers: { Authorization: `Bearer ${getAdminToken()}` },
      params
    });
  };

export const getProfileByTenantId = async (tenantId: string):Promise<AxiosResponse<any>> => {
    return axios.get(`${baseUrl.server}/api/admin/profile/${tenantId}`,
      {
        headers: { Authorization: `Bearer ${getAdminToken()}` },
      },
    );
}

export const saveProfile = async (profile: InCreateProfileDTO):Promise<AxiosResponse<any>> => {
  return axios.post(`${baseUrl.server}/api/admin/profile`, profile, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
}

export const updateProfile = async (profileId: string, profile: any):Promise<AxiosResponse<any>> => {
  return axios.patch(`${baseUrl.server}/api/admin/profile/${profileId}`, profile, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
}

export const changeActive = async (profileId: string):Promise<AxiosResponse<any>> => {
  return axios.patch(`${baseUrl.server}/api/admin/profile/changeStatus/${profileId}`, null, {
    headers: { Authorization: `Bearer ${getAdminToken()}` },
  });
}