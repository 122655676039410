import { getMerchantsProfileById } from "../api/index";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  capitalize,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import handleError from "../../../global-helpers/handleError";
import { useAppDispatch } from "../../../global-store/hooks";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { showNotification } from "../../../global-store/notificationSlice";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SearchField from "../../../global-components/inputs/SearchField";
import formatPhoneNumber from "../../../global-helpers/formatPhoneNumber";
// import EditIcon from "@mui/icons-material/Edit";

interface MerchantProgramListProps {
  profileId?: string;
}

const MerchantProgramList = ({ profileId }: MerchantProgramListProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [rowsData, setRowsData] = useState<{
    merchants: Record<string, any>[];
    total: number;
  }>({ merchants: [], total: 0 });
  const [page, setPage] = useState(0);
  const [searchedWord, setSearchedWord] = useState<string>("");

  const onSearchChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setSearchedWord(event.target.value);
  };

  const onSearchSubmit = async (): Promise<void> => {
    try {
      dispatch(setIsLoading(true));

      const requestBody = {
        page: page + 1,
        perPage: 20,
        withMerchants: true,
        merchantTerms: searchedWord,
      };
      const { data } = await getMerchantsProfileById(profileId, requestBody);

      setRowsData({ merchants: data.merchants, total: data.merchants.length })

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = handleError(error, navigate, true);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }

      dispatch(setIsLoading(false));
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Merchant Name",
      width: 180,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    {
      field: "contactName",
      headerName: "Contact Name",
      width: 200,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    {
      field: "email",
      headerName: "Email",
      width: 330,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 140,
      renderCell: ({ formattedValue }) => formatPhoneNumber(formattedValue),
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    {
      field: "city",
      headerName: "City",
      width: 130,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    {
      field: "stateCode",
      headerName: "State",
      width: 65,
    },
    {
      field: "zip",
      headerName: "Zip",
      width: 75,
    },
    {
      field: "businessCategory",
      headerName: "Business Category",
      width: 160,
      renderCell: ({ formattedValue }) =>
        formattedValue ? capitalize(formattedValue) : "",
    },
    {
      field: "website",
      headerName: "Website",
      width: 330,
      renderCell: ({ formattedValue }) =>
        formattedValue ? formattedValue : "",
    },
  ];

  const loadState = async () => {
    if (profileId) {
      const requestBody = {
        page: page + 1,
        perPage: 20,
        withMerchants: true,
        merchantTerms: searchedWord,
      };
      const { data } = await getMerchantsProfileById(profileId, requestBody);
      if (
        data.merchants?.length > 0
      ) setRowsData({ merchants: data.merchants, total: data.merchants.length });

    }
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));

        await loadState();

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const render = (
    <>
      <Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            <strong>Merchants with this Program</strong>
          </Typography>
          <Paper sx={{ mt: 6 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mx: 2,
                    mt: 1,
                  }}
                >
                  <SearchField
                    onChange={onSearchChange}
                    onSubmit={onSearchSubmit}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  columns={columns}
                  rows={rowsData.merchants}
                  rowCount={rowsData.total}
                  pageSize={20}
                  rowsPerPageOptions={[20]}
                  onPageChange={(newPage: number) => setPage(newPage)}
                  autoHeight
                  pagination
                  paginationMode="server"
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  );

  return render;
};

export default MerchantProgramList;