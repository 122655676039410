import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  capitalize,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Switch,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import handleError from "../../../global-helpers/handleError";
import { useAppDispatch } from "../../../global-store/hooks";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { showNotification } from "../../../global-store/notificationSlice";
import { getProductById, saveProduct, ProductTypeEnum, ProductCategoryEnum } from "../api";
interface ProfileInformationProps {
  productId?: string;
  onGoBack?: () => void;
  refreshList?: () => void;
  onNext?: (profileInformation: any) => void;
}

const Information = ({
  productId,
  onGoBack,
  refreshList,
  onNext,
}: ProfileInformationProps) => {
  const [productInformationData, setProductInformationData] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loadState = async () => {
    if (productId) {
      const { data } = await getProductById(productId);
      setProductInformationData(data);

      await formik.setValues(
        {
          name: capitalize(data.product.name),
          default: data.product.default,
          productType: data.product.productType,
          productCategory: data.product.productCategory,
        },
        true
      );
    } else {
      setProductInformationData({
        name: "",
        default: true,
        productType: "",
        productCategory: "",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      default: false,
      productType: ProductTypeEnum.INSTALLMENT_LOAN,
      productCategory: ProductCategoryEnum.LMS,
    },
    validationSchema: yup.object({
      name: yup.string().trim().required("Program name is required"),
      default: yup.boolean().optional(),
    }),
    onSubmit: async (values) => {
      try {
        dispatch(setIsLoading(true));
        if (onNext) {
          onNext(values);
        } else {
          const updateObject = {
            ...productInformationData.product,
            ...values,
          };
          await saveProduct(updateObject);

          dispatch(setIsLoading(false));
          dispatch(
            showNotification({
              message: productId? "Product Information updated successfully": "Product created successfully",
              severity: "success",
              shouldNotify: true,
            })
          );
          if (!productId && onGoBack) {
            onGoBack()
          };
        } 
        dispatch(setIsLoading(false));
        if (refreshList) {
          refreshList();
        }
        
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    },
  });


  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));

        await loadState();

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const render = (
    <>
      <Box>
        <Box sx={{ mt: 2 }}>
          <Box>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="PRODUCT PROFILE NAME"
                    name="name"
                    value={formik.values.name}
                    inputProps={{ maxLength: 30 }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControlLabel
                    label={"Default"}
                    control={
                      <Switch
                        name="default"
                        disabled={
                          productId && formik.values.default ? true : false
                        }
                        onChange={formik.handleChange}
                        checked={formik.values.default}
                      />
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControl fullWidth required>
                    <InputLabel id="product-type">Product Type</InputLabel>
                    <Select
                      labelId="product-type"
                      label="Product Type"
                      name="productType"
                      value={formik.values.productType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.productType &&
                        Boolean(formik.errors.productType)
                      }
                    >
                      {Object.keys(ProductTypeEnum).map((prodType) => {
                        return (
                          <MenuItem value={ProductTypeEnum[prodType]}>
                            {ProductTypeEnum[prodType]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {formik.touched.productType && formik.errors.productType}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControl fullWidth required>
                    <InputLabel id="product-category">Product Category</InputLabel>
                    <Select
                      labelId="product-category"
                      label="Product Category"
                      name="productCategory"
                      value={formik.values.productCategory}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.productCategory &&
                        Boolean(formik.errors.productCategory)
                      }
                    >
                      {Object.keys(ProductCategoryEnum).map((prodType) => {
                        return (
                          <MenuItem value={ProductCategoryEnum[prodType]}>
                            {ProductCategoryEnum[prodType]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {formik.touched.productType && formik.errors.productType}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => onGoBack()}
                      sx={{ mr: 4, minWidth: "9.82125rem" }}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={formik.isSubmitting}
                      sx={{ minWidth: "9.82125rem" }}
                    >
                      {productId ? "Update" : "Create"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );

  return render;
};

export default Information;
