import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
  Theme,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import BWWhiteLogoWithWhiteText from "../../../global-components/logos/BWWhiteLogoWithWhiteText";
import OutlinedButton from "../../../global-components/buttons/OutlinedButton";
import PrimaryButton from "../../../global-components/buttons/PrimaryButton";
import ArrowRightIcon from "../../../global-components/icons/ArrowRightIcon";
import landingPageBackgroundSmall from "../assets/header/landing-page-background-sm.png";
import landingPageBackgroundMedium from "../assets/header/landing-page-background-md.png";
import step1BackgroundSmall from "../assets/header/enrollment-step1-background-sm.png";
import step1BackgroundMedium from "../assets/header/enrollment-step1-background-md.png";
import step2BackgroundSmall from "../assets/header/enrollment-step2-background-sm.png";
import step2BackgroundMedium from "../assets/header/enrollment-step2-background-md.png";
import step3BackgroundSmall from "../assets/header/enrollment-step3-background-sm.png";
import step3BackgroundMedium from "../assets/header/enrollment-step3-background-md.png";
import { ReactComponent as BWBlueLogoWithBlackFullText } from "../assets/header/bw-logo-full-text.svg";
import { useAppSelector } from "../../../global-store/hooks";
import { useAppDispatch } from "../../../global-store/hooks";
import { setIsLoading } from "../../../global-store/loadingSlice";
import handleError from "../../../global-helpers/handleError";
import { showNotification } from "../../../global-store/notificationSlice";

const Header = () => {
  const step: number = useAppSelector(
    (state) => state.merchantOnboardingLayout.step
  );
  const theme: Theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [platformUrl, setPlatformUrl] = useState<string | undefined>(undefined);
  const routeParams = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));

        const platformURL: string = routeParams.platformUrl;
        setPlatformUrl(platformURL);

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const render = isLargeScreen ? (
    <Box
      sx={{
        height: "5.3125rem",
        backgroundColor: "#fff",
        borderRadius: "0px 0px 20px 20px;",
        padding: "26px 48px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BWBlueLogoWithBlackFullText />

        <PrimaryButton
          endIcon={
            <ArrowRightIcon
              sx={{ width: "9px", height: "7px", marginLeft: "4px" }}
            />
          }
          onClick={() =>
            (window.location.href = "https://www.businesswarrior.com")
          }
          sx={{ fontSize: "0.75rem", lineHeight: "15px", padding: "6px 16px" }}
        >
          Chat Support
        </PrimaryButton>
      </Box>
    </Box>
  ) : (
    <Box sx={{ mb: step === 0 ? 2 : 20 }}>
      <Box
        sx={{
          width: "100%",
          height: "26.9375rem",
          ...(step === 0 &&
            isSmallScreen && {
              backgroundImage: `url(${landingPageBackgroundSmall})`,
              backgroundSize: "cover",
            }),
          ...(step === 0 &&
            isMediumScreen && {
              backgroundImage: `url(${landingPageBackgroundMedium})`,
              backgroundSize: "contain",
            }),
          ...(step === 1 &&
            isSmallScreen && {
              backgroundImage: `url(${step1BackgroundSmall})`,
              backgroundSize: "cover",
            }),
          ...(step === 1 &&
            isMediumScreen && {
              backgroundImage: `url(${step1BackgroundMedium})`,
              backgroundSize: "contain",
            }),
          ...(step === 2 &&
            isSmallScreen && {
              backgroundImage: `url(${step2BackgroundSmall})`,
              backgroundSize: "cover",
            }),
          ...(step === 2 &&
            isMediumScreen && {
              backgroundImage: `url(${step2BackgroundMedium})`,
              backgroundSize: "contain",
            }),
          ...(step === 3 &&
            isSmallScreen && {
              backgroundImage: `url(${step3BackgroundSmall})`,
              backgroundSize: "cover",
            }),
          ...(step === 3 &&
            isMediumScreen && {
              backgroundImage: `url(${step3BackgroundMedium})`,
              backgroundSize: "contain",
            }),
          position: "absolute",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            ...(step > 0 && { background: "rgba(4, 25, 66, 0.6)" }),
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <BWWhiteLogoWithWhiteText
            sx={{ width: "7.0625rem", height: "2rem" }}
          />
          <OutlinedButton
            endIcon={
              <ArrowRightIcon
                sx={{
                  width: "9px",
                  height: "7px",
                  marginLeft: "12px",
                  marginRight: "10px",
                }}
              />
            }
            sx={{
              fontSize: "0.75rem",
              padding: "8.5px 13px",
              lineHeight: "15px",
            }}
          >
            Chat Support
          </OutlinedButton>
        </Box>

        {step === 0 && (
          <Box sx={{ mt: 2, mx: isSmallScreen ? 2 : 10 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                py: 2,
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Proxima Nova",
                  fontSize: "0.8rem",
                  color: "#fff",
                  letterSpacing: "0.5px",
                  lineHeight: "16px",
                }}
              >
                Already have an account?
              </Typography>

              <Link
                sx={{ color: "#fff" }}
                underline="none"
                to={`/${platformUrl}/admin/login`}
                component={RouterLink}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontFamily: "Proxima Nova",
                      fontWeight: 700,
                      letterSpacing: "0.5px",
                    }}
                  >
                    Sign In
                  </span>
                  <ArrowForwardIcon sx={{ ml: 1 }} />
                </Box>
              </Link>
            </Box>

            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "2.44125rem",
                fontWeight: 700,
                lineHeight: "48px",
                color: "#041942",
                mt: 2,
              }}
            >
              Enroll now for <span style={{ color: "#fff" }}>FREE</span> today!
            </Typography>

            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Proxima Nova",
                letterSpacing: "0.5px",
                mt: 2,
              }}
            >
              Business Warrior BNPL Platform will help you to finance your
              clients.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  return render;
};

export default Header;
