import { SvgIcon, SvgIconProps } from "@mui/material";

const AdvertisingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 41 41">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3049 10.0331L21.6574 5.1251C20.7603 3.46207 18.4819 3.46385 17.6337 5.17784L11.7271 16.6699L17.9849 28.2705L30.1987 28.5649C31.9878 28.595 33.1502 26.5246 32.2531 24.8616L29.5838 19.913C30.2098 18.4445 29.9032 16.0921 28.6998 13.8612C27.5563 11.6474 25.8061 10.187 24.3049 10.0331ZM39.3908 15.5671L35.8745 15.5878C35.0143 15.5813 34.2757 16.3608 34.2678 17.2807C34.2851 17.593 34.3401 17.8819 34.4707 18.1241C34.7537 18.6489 35.2794 18.9698 35.8475 18.9954L39.3638 18.9747C40.224 18.9812 40.9626 18.2016 40.9705 17.2818C40.9783 16.3619 40.2509 15.5736 39.3908 15.5671ZM30.9392 6.54101L32.714 3.29092C33.1388 2.49008 32.8867 1.46182 32.1169 0.968834C31.3689 0.516216 30.4069 0.787992 29.9443 1.6122L28.1695 4.86229C27.8683 5.42521 27.9028 6.0498 28.1857 6.57457C28.3164 6.81678 28.5225 7.01226 28.7665 7.18437C29.5145 7.63699 30.4766 7.36521 30.9392 6.54101ZM36.4001 7.23779L33.348 9.12286C32.5944 9.58832 32.3388 10.6041 32.7729 11.4083C33.2069 12.2125 34.157 12.4835 34.9106 12.0181L37.9627 10.133C38.7163 9.66757 38.9719 8.65182 38.5379 7.84759C38.1255 7.08357 37.1537 6.77234 36.4001 7.23779ZM0.916693 26.0823L4.94597 33.5503C5.55912 34.6867 6.93723 35.08 8.00178 34.4223L9.10435 33.741L12.4329 39.9103C12.7395 40.4785 13.3986 40.6665 13.9308 40.3377L15.6797 39.2572C16.212 38.9283 16.3893 38.2234 16.0828 37.6552L12.7542 31.486L15.7958 29.6068L9.53292 17.999L1.73889 22.8144C0.674335 23.4721 0.303542 24.9459 0.916693 26.0823Z"
        fill="#041942"
      />
    </SvgIcon>
  );
};

export default AdvertisingIcon;
