import { useState, useEffect, SyntheticEvent } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Typography,
  Paper,
  Tab,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import Information from "../components/Information";
import ProfileSaasFeeConfiguration from "../components/ProfileSaasFeeConfiguration";
import MerchantProgramList from "../components/MerchantProgramList";

const EditProfile = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [profileId, setProfileId] = useState<string | undefined>(undefined);
  const routeParams = useParams();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const onTabChange = (_event: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    (async () => {
      const tenantId: string = routeParams.id;
      if (tenantId) {
        setProfileId(tenantId);
      }
    })();
  }, [profileId]);

  const render = (
    <Box sx={{ mt: 6, mx: 4, mb: 4 }}>
      <Typography variant="h4">
        <strong>Program Details</strong>
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Paper sx={{ mt: 6 }}>
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={onTabChange}
                variant={isLargeScreen ? "standard" : "scrollable"}
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab label="Program Information" value="1" />
                <Tab label="Program Saas Fee Configuration" value="2" />
                <Tab label="Merchants with this Program" value="3" />
              </TabList>
            </Box>

            {activeTab === "1" && (
              <TabPanel value="1">
                <Information profileId={profileId} />
              </TabPanel>
            )}
            {activeTab === "2" && (
              <ProfileSaasFeeConfiguration profileId={profileId} />
            )}
            {activeTab === "3" && (
              <MerchantProgramList profileId={profileId} />
            )}
          </TabContext>
        </Paper>
      </Box>
    </Box>
  );

  return render;
};

export default EditProfile;
