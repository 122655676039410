import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import modalStyles from "../../../global-helpers/modalStyles";

import Information from "../components/Information";
import ProfileSaasFeeConfiguration from "../components/ProfileSaasFeeConfiguration";
import { saveProfile } from "../api";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../global-store/hooks";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { showNotification } from "../../../global-store/notificationSlice";
import handleError from "../../../global-helpers/handleError";


const AddProfile = () => {
  const [step, setStep] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [profile, setProfile] = useState<any | undefined>(undefined);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const stepLabels: string[] = [
    "Program Information",
    "SAAS Fee Configuration",
  ];

  const onProfileInformationNext = (profileInformation: any): void => {
    setProfile({
      ...profile,
      information: profileInformation,
    });
    setStep(step + 1);
  };

  const onSuccessMessageClose = async () => {
    setOpenSuccessMessage(false);
    navigate("/admin/dashboard/manage-profile");
  };


  const onAddProfileClick = async (saasFee: any): Promise<void> => {
    if (profile) {
      const information = {
        ...profile.information,
      };
      const responseBody = {
        profile: information,
        saasFee,
      };

      try {
        await saveProfile(responseBody);
        setOpenSuccessMessage(true);
      } catch (err) {
        const errorResponse = handleError(err, navigate, true);
        if (errorResponse) {
          dispatch(
            showNotification({
              message: errorResponse,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    }
  };
  const onGoBackClick = (): void => {
    if (step >= 0) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, [windowWidth]);

  const render = (
    <>
    <Box sx={{ mt: 6, mx: 4, mb: 4 }}>
      <Typography variant="h4">
        <strong>Program Details</strong>
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Paper sx={{ p: 4 }}>
          <Stepper
            alternativeLabel={windowWidth >= 500}
            orientation={windowWidth >= 500 ? "horizontal" : "vertical"}
            activeStep={step}
          >
            {stepLabels.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box sx={{ mt: 4 }}>
            {step === 0 && <Information onNext={onProfileInformationNext} />}
            {/* {step === 1 && (
              <DecisionEngineConfiguration onNext={onDecisionEngineNext} onGoBack={onGoBackClick} />
            )} */}
            {step === 1 && (
              <ProfileSaasFeeConfiguration
                onNext={onAddProfileClick}
                onGoBack={onGoBackClick}
              />
            )}
          </Box>
        </Paper>
      </Box>
    </Box>

    <Modal
    open={openSuccessMessage}
    onClose={onSuccessMessageClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
    >
    <Fade in={openSuccessMessage}>
      <Box sx={{ ...modalStyles }}>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Program added successfully.
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
          <Button
            variant="contained"
            onClick={onSuccessMessageClose}
            sx={{ minWidth: "6.25rem" }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Fade>
    </Modal>
    </>
  );

  return render;
};

export default AddProfile;