import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { OfferEngineApi } from "../../../../global-api/OfferEngineApi";
import { useAppDispatch } from "../../../../global-store/hooks";
import { setIsLoading } from "../../../../global-store/loadingSlice";
import { showNotification } from "../../../../global-store/notificationSlice";
import handleError from "../../../../global-helpers/handleError";
import { useNavigate } from "react-router-dom";

interface OfferEngineProps {
  matrix: string;
  onImport?(): void;
  productId: string;
}

interface Data {
  platform: string;
  matrix: string;
  xValue: string;
  yValue: string;
  value: string;
}

export const OfferEngine = ({
  matrix,
  onImport,
  productId,
}: OfferEngineProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState<Data[] | undefined>();
  const [file, setFile] = useState<File | undefined>();

  const getData = useCallback(async () => {
    dispatch(setIsLoading(true));

    const { data } = await OfferEngineApi.getData(productId, {
      matrix,
      exportType: "json",
    });

    dispatch(setIsLoading(false));

    setData(data);
  }, [dispatch, matrix]);

  useEffect(() => {
    getData();
  }, [getData]);

  const xValues = [...new Set(data?.map(({ xValue }) => xValue))];
  const yValues = [...new Set(data?.map(({ yValue }) => yValue))];

  const handleSubmit = async () => {
    try {
      await OfferEngineApi.updateData(productId, { matrix, file });
    } catch (e) {
      const errorMessage = handleError(e, navigate);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }
    }

    getData();
    onImport?.();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFile(event.target?.files?.[0]);
  };

  const handleExport = async (exportType: "excel" | "csv") => {
    const { data } = await OfferEngineApi.getData(productId, {
      matrix,
      exportType,
    });

    const href = URL.createObjectURL(data);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `export.${exportType === "csv" ? "csv" : "xlsx"}`
    );
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  return (
    <Stack sx={{ p: 3 }} spacing={3}>
      <Stack direction="row" spacing={3} justifyContent="flex-end">
        <Button variant="contained" component="label">
          Choose File
          <input type="file" onChange={handleFileChange} hidden />
        </Button>

        <Button
          color="primary"
          variant="contained"
          disabled={!file}
          onClick={handleSubmit}
        >
          Upload
        </Button>
      </Stack>

      {data != null && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell />
                {xValues.map((xValue) => (
                  <TableCell
                    key={xValue}
                    align="right"
                    sx={{ fontWeight: "bold" }}
                  >
                    {xValue}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {yValues.map((yValue) => (
                <TableRow
                  key={yValue}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                  >
                    {yValue}
                  </TableCell>
                  {xValues.map((xValue) => (
                    <TableCell key={`${yValue}-${xValue}`} align="right">
                      {data.find(
                        (d) => d.xValue === xValue && d.yValue === yValue
                      )?.value ?? ""}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Stack direction="row" spacing={3}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleExport("excel")}
        >
          Export to excel
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={() => handleExport("csv")}
        >
          Export to CSV
        </Button>
      </Stack>
    </Stack>
  );
};
