import { styled } from "@mui/system";
import Button, { ButtonProps } from "@mui/material/Button";

const textColor = "#041942";
const hoverTextColor = "#fff";
const borderColor = "rgba(33, 99, 246, 1)";
const hoverBorderColor = "rgba(33, 99, 246, 0)";
const backgroundColor = "#fff";
const hoverBackgroundColor = "#0060ff";

const PrimaryButton = styled(Button)<ButtonProps>(() => ({
  fontFamily: "Proxima Nova",
  fontSize: "1.25rem",
  fontWeight: 700,
  color: textColor,
  backgroundColor,
  border: `2px solid ${borderColor}`,
  borderRadius: "50rem",
  padding: "13px 68.5px",
  lineHeight: "24px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: hoverBackgroundColor,
    color: hoverTextColor,
    borderColor: hoverBorderColor,
  },
}));

export default PrimaryButton;
