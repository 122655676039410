import { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Button,
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import handleError from "../../../global-helpers/handleError";
import { showNotification } from "../../../global-store/notificationSlice";
import { useAppDispatch } from "../../../global-store/hooks";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { getMerchantByURL, getPlatFormByURL } from "../../../global-api";
// import BlueLogoWithBlackText from "../../../global-components/logos/BWBlueLogoWithBlackText";
import { ReactComponent as BlueLogoWithBlackText } from "../../../global-assets/logos/BWBlueLogoWithBlackText.svg";
import { isUserAuthenticated } from "../../authentication/helpers";

interface HeaderOptions {
  showLoginButton: boolean;
}

const Header = ({ showLoginButton }: HeaderOptions) => {
  const theme = useTheme();
  const isCellphone = useMediaQuery(theme.breakpoints.down("sm"));
  const hasUserToken = isUserAuthenticated();
  const [merchantData, setMerchantData] = useState<
    Record<string, any> | undefined
  >(undefined);
  const [platformUrl, setPlatformUrl] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const routeParams = useParams();

  const onLoginLogoutClick = () => {
    if (hasUserToken) {
      localStorage.removeItem("userToken");
      navigate(`/${platformUrl}/login`);
    } else {
      navigate(`/${platformUrl}/login`);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));

        const platformURL: string = routeParams.platformUrl;
        const merchantUrl: string = routeParams.merchantUrl;

        if (merchantUrl) {
          setPlatformUrl(platformURL);
          const { data: platformData } = await getPlatFormByURL(platformURL);
          const { data } = await getMerchantByURL(platformData.id, merchantUrl);
          setMerchantData(data);
        }

        dispatch(setIsLoading(false));
      } catch (error) {
        if ((error as any).response.data.statusCode === 404) {
          navigate("/");
        } else {
          const errorMessage = handleError(error, navigate);
          if (errorMessage) {
            dispatch(
              showNotification({
                message: errorMessage,
                severity: "error",
                shouldNotify: true,
              })
            );
          }
        }

        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, routeParams.merchantUrl]);

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to="/">
              <BlueLogoWithBlackText style={{width: "200"}}/>
            </Link>
            {merchantData?.name && (
              <>
                <Typography
                  sx={{
                    fontSize: isCellphone ? "2rem" : "2.75rem",
                    color: "#000",
                    mr: 1,
                  }}
                >
                  |
                </Typography>
                <Typography
                  sx={{
                    fontSize: isCellphone ? "1.5rem" : "2rem",
                    color: "#000",
                  }}
                >
                  <strong>{merchantData?.name}</strong>
                </Typography>
              </>
            )}
          </Box>
          {showLoginButton && (
            <Button
              variant={hasUserToken ? "outlined" : "contained"}
              onClick={onLoginLogoutClick}
              sx={{ height: "33px" }}
            >
              {hasUserToken ? "Logout" : "Login"}
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
