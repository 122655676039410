import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Spinner from "../../../global-components/spinners/Spinner";
import NotificationBar from "../../../global-components/notifications/NotificationBar";

const Layout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#EBF3FF",
      }}
    >
      <Header />
      <Box sx={{ zIndex: 1 }}>
        <NotificationBar />
        <Outlet />
        <Spinner />
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
