import {
  useState,
  useEffect,
  
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

import { getMerchantByURL, getPlatFormByURL } from "../../../global-api";
import { useAppDispatch } from "../../../global-store/hooks";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { showNotification } from "../../../global-store/notificationSlice";
import handleError from "../../../global-helpers/handleError";
import { Merchant } from "../../../../../server/src/admin/merchant/entities/merchant.entity";
import { Platform } from "../../../../../server/src/platform/entities/platform.entity";
import modalStyles from "../../../global-helpers/modalStyles";
import { ProductProfiles } from "../../../../../server/src/platform/products-profiles/entities/product-profile.entity";

const StandBy = () => {
  const [merchant, setMerchant] = useState<Merchant | undefined>(undefined);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const routeParams = useParams();
  const [productProfileData, setProductProfileData] =
    useState<ProductProfiles>(undefined);

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));
        const platformURL: string = routeParams.platformUrl;
        const merchantURL: string = routeParams.merchantUrl;

        if (platformURL && merchantURL) {
          const { data: platformData } = await getPlatFormByURL(platformURL);
          const { data: merchantData } = await getMerchantByURL(
            platformData.id,
            merchantURL
          );

          const merchantProfile = merchantData?.profile;
          const productProfile = merchantData?.profile?.productProfile;
          setProductProfileData(productProfile);

          setMerchant(merchantData);
          if (
            merchantData?.profileId &&
            merchantProfile?.productProfileId &&
            productProfile.active
          ) {
            dispatch(setIsLoading(false));
            navigate(`/${platformData?.url}/${merchantData?.url}/apply`);
            return;
          }
          dispatch(setIsLoading(false));
        }
      } catch (error) {
        if (
          !Boolean(
            (error as Record<string, any>)?.response?.data?.statusCode === 404
          )
        ) {
          const errorMessage = handleError(error, navigate, false);
          if (errorMessage) {
            dispatch(
              showNotification({
                message: errorMessage,
                severity: "error",
                shouldNotify: true,
              })
            );
          }
        }

        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box>
      <Typography variant="h3" sx={{ mb: 3, textAlign: "center" }}>
        Please, Stand By.
      </Typography>
      <Box>
        <Typography variant="h6" sx={{ mb: 3, textAlign: "center" }}>
          This merchant it's not fully configured yet, so please stand by. While
          we finish building his configurations.
        </Typography>
      </Box>
    </Box>
  );
};

export default StandBy;
