import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Modal,
  Fade,
  Backdrop,
  Slider,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
  Grid,
  Checkbox,
  TextField,
  Chip,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUpload from "react-material-file-upload";
import { useFormik } from "formik";
import * as yup from "yup";

import imageTemplate from "../../assets/image-template.svg";
import AdvertisingIcon from "../../assets/AdvertisingIcon";
import modalStyles from "../../../../global-helpers/modalStyles";
import handleError from "../../../../global-helpers/handleError";
import { useAppDispatch } from "../../../../global-store/hooks";
import { showNotification } from "../../../../global-store/notificationSlice";
import { setIsLoading } from "../../../../global-store/loadingSlice";
import {
  addPlatformPreliminaryRule,
  addMerchantPreliminaryRule,
  getPlatformPreliminaryCheckRules,
  getMerchantPreliminaryRules,
  updatePlatformPreliminaryCheckRule,
  updateMerchantPreliminaryCheckRule,
  deletePlatformPreliminaryCheckRule,
  deleteMerchantPreliminaryCheckRule,
} from "../../api";
import { getMerchantByJWT } from "../../../../global-api";
import { PlatformPreliminaryCheckRules } from "../../../../../../server/src/platform/underwriting/entities/preliminary-check-rules.entity";
import capitalize from "../../../../global-helpers/capitalize";
import states from "../../../../global-helpers/states";
import currencyToString from "../../../../global-helpers/currencyToString";
import isUserMerchant from "../../../../global-helpers/isMerchant";
import { CreditReportSettings } from "../../../../../../server/src/admin/merchant/entities/credit-report-settings.entity";

interface RuleTemplate {
  address?: boolean;
  email?: boolean;
  enabledOperators: (
    | "less than"
    | "less than or equal to"
    | "greater than"
    | "greater than or equal to"
  )[];
  displayTimeframe: boolean;
  ip?: boolean;
  ruleType: string;
  shortDescription: string;
  ssn?: boolean;
  states?: { stateCode: string; checked: boolean }[];
  threshold: number;
  thresholdMarks: { value: number; label: string }[];
  thresholdMaxValue: number;
  thresholdMinValue: number;
  thresholdStep: number;
}

const ruleTemplates: Record<string, RuleTemplate> = {
  income: {
    enabledOperators: ["less than or equal to", "less than"],
    displayTimeframe: true,
    ruleType: "income",
    shortDescription: "Income rules based on unverified user input",
    threshold: 500,
    thresholdMarks: [
      { value: 0, label: "$0" },
      { value: 25000, label: "$25,000" },
    ],
    thresholdMaxValue: 25000,
    thresholdMinValue: 0,
    thresholdStep: 500,
  },
  bannedIp: {
    enabledOperators: ["less than", "less than or equal to"],
    displayTimeframe: false,
    ruleType: "banned ip",
    shortDescription:
      "Fraud Screen: Add IP addresses or IP address ranges to the IP blacklist",
    threshold: 660,
    thresholdMarks: [
      { value: 300, label: "300" },
      { value: 850, label: "850" },
    ],
    thresholdMaxValue: 850,
    thresholdMinValue: 300,
    thresholdStep: 1,
  },
  bannedAddress: {
    enabledOperators: ["less than or equal to", "less than"],
    displayTimeframe: false,
    ruleType: "banned address",
    shortDescription:
      "Fraud Screen: Add or delete addresses from the physical addresses blacklist",
    threshold: 500,
    thresholdMarks: [
      { value: 0, label: "$0" },
      { value: 25000, label: "$25,000" },
    ],
    thresholdMaxValue: 25000,
    thresholdMinValue: 0,
    thresholdStep: 500,
  },
  duplicateApplication: {
    enabledOperators: ["greater than", "greater than or equal to"],
    displayTimeframe: true,
    ruleType: "duplicate application",
    shortDescription:
      "Allow or prevent duplicate borrower applications within a specified timeframe",
    threshold: 35,
    thresholdMarks: [
      { value: 0, label: "0%" },
      { value: 200, label: "200%" },
    ],
    thresholdMaxValue: 200,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  state: {
    enabledOperators: ["greater than", "greater than or equal to"],
    displayTimeframe: false,
    ruleType: "state",
    shortDescription:
      "Select the active US states. Generally, states with a lending license",
    threshold: 0,
    thresholdMarks: [
      { value: 0, label: "0" },
      { value: 20, label: "20" },
    ],
    thresholdMaxValue: 20,
    thresholdMinValue: 0,
    thresholdStep: 1,
  },
  ageCheck: {
    enabledOperators: ["less than", "less than or equal to"],
    displayTimeframe: false,
    ruleType: "age check",
    shortDescription: "Set the minimum age for an applicant",
    threshold: 12,
    thresholdMarks: [
      { value: 1, label: "1 year" },
      { value: 85, label: "85 years" },
    ],
    thresholdMaxValue: 85,
    thresholdMinValue: 1,
    thresholdStep: 1,
  },
  reapplyLimit: {
    enabledOperators: ["greater than", "greater than or equal to"],
    displayTimeframe: true,
    ruleType: "reapply limit",
    shortDescription:
      "Set the maximum number of times a user can reapply within a specified timeframe",
    threshold: 1,
    thresholdMarks: [
      { value: 0, label: "0" },
      { value: 10, label: "10" },
    ],
    thresholdMaxValue: 10,
    thresholdMinValue: 1,
    thresholdStep: 1,
  },
};

const stateSelection: {
  stateCode: string;
  checked: boolean;
}[] = states.map((state) => ({
  stateCode: state.stateCode,
  checked: false,
}));
interface PremChecksProps {
  productId?: string;
}

const PreliminaryCheckRules = ({ productId }: PremChecksProps) => {
  const isMerchant: boolean = isUserMerchant();
  const [files, setFiles] = useState<File[]>([]);
  const [rowsData, setRowsData] = useState<PlatformPreliminaryCheckRules[]>([]);
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [openDeleteRuleModal, setOpenDeleteRuleModal] = useState(false);
  const [enableMerchantRules, setEnableMerchantRules] = useState(false);
  const [viwAllStates, setViewAllStates] = useState(false);
  const [states, setStates] = useState<Record<string, any>[]>([]);
  const baseRuleColumns: GridColDef[] = [
    {
      field: "active",
      headerName: "Active",
      width: 70,
      renderCell: ({ formattedValue, row }) => (
        <Switch
          onChange={() => onActiveChange(row.id, !formattedValue)}
          checked={formattedValue}
          disabled={
            (isMerchant && row.platform?.name === row.createdBy) ||
            (isMerchant && !enableMerchantRules) ||
            row.ruleType === ruleTemplates.state.ruleType
          }
        />
      ),
    },
    {
      field: "bypass",
      headerName: "Bypass",
      width: 75,
      renderCell: ({ formattedValue }) => (
        <Switch disabled={true} checked={formattedValue} />
      ),
    },
    {
      field: "id",
      headerName: "Action",
      width: 200,
      renderCell: ({ row }) => (
        <>
          <Button
            variant="contained"
            onClick={() => onEditRuleClick(row)}
            disabled={
              (isMerchant && row.platform?.name === row.createdBy) ||
              (isMerchant && !enableMerchantRules)
            }
            startIcon={<EditIcon />}
            sx={{ mr: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            onClick={() => onDeleteRuleOpen(row)}
            disabled={
              (isMerchant && row.platform?.name === row.createdBy) ||
              (isMerchant && !enableMerchantRules) ||
              row.ruleType === ruleTemplates.state.ruleType
            }
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </>
      ),
    },
    {
      field: "ruleType",
      headerName: "Type",
      width: 150,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: ({ formattedValue }) =>
        formattedValue ? capitalize(formattedValue) : "--",
    },
    {
      field: "failAction",
      headerName: "Fail Action",
      width: 110,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    {
      field: "failIf",
      headerName: "Fail If",
      width: 250,
      renderCell: ({ formattedValue, row }) => {
        if (row.ruleType === ruleTemplates.income.ruleType) {
          return `${capitalize(formattedValue)} $${currencyToString(
            row.threshold
          )}`;
        } else if (row.ruleType === ruleTemplates.state.ruleType) {
          return "--";
        } else if (
          row.ruleType === ruleTemplates.duplicateApplication.ruleType
        ) {
          return (
            <Box>
              {row.ip && <Chip label="IP" sx={{ mr: 1 }} />}
              {row.ssn && <Chip label="SSN" sx={{ mr: 1 }} />}
              {row.address && <Chip label="Address" sx={{ mr: 1 }} />}
              {row.email && <Chip label="Email" />}
            </Box>
          );
        }

        return `${capitalize(formattedValue)} ${row.threshold}`;
      },
    },
    {
      field: "periodType",
      headerName: "Rules Timeframe",
      width: 145,
      renderCell: ({ formattedValue, row }) => {
        for (const [, value] of Object.entries(ruleTemplates)) {
          if (
            value.ruleType === row.ruleType &&
            value.displayTimeframe &&
            row.ruleType !== ruleTemplates.income.ruleType
          ) {
            if (row.periodSelection <= 1) {
              if (formattedValue === "months") {
                return `${row.periodSelection} Month`;
              }

              return `${row.periodSelection} Day`;
            }

            return `${row.periodSelection} ${capitalize(formattedValue)}`;
          } else if (
            value.ruleType === row.ruleType &&
            value.displayTimeframe &&
            row.ruleType === ruleTemplates.income.ruleType
          ) {
            return `${capitalize(row.incomePeriodType)}`;
          }
        }

        return `--`;
      },
    },
    {
      field: "states",
      headerName: "State Selection",
      flex: 1,
      renderCell: ({ formattedValue, row }) => {
        if (row.ruleType === ruleTemplates.state.ruleType) {
          const activeStates = formattedValue.filter((state) => state.checked);
          const allStates = activeStates.some(
            (state) => state.stateCode === "all"
          );

          if (allStates) {
            return <Chip key={"all"} label="ALL STATES" />;
          } else if (activeStates.length <= 3) {
            return activeStates.map((state) => (
              <Chip
                key={state.stateCode}
                label={
                  state.stateCode === "all" ? "ALL STATES" : state.stateCode
                }
              />
            ));
          } else {
            return (
              <Button
                variant="contained"
                onClick={() => onViewAllStatesOpen(activeStates)}
              >
                View States
              </Button>
            );
          }
        }

        return "--";
      },
    },
  ];
  const [ruleColumns, setRuleColumns] = useState<GridColDef[]>(baseRuleColumns);
  const grayPaperBackground = "#f6f7f7";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      active: true,
      address: false,
      bypass: false,
      displayTimeframe: false,
      email: false,
      enabledOperators: [] as (
        | "less than"
        | "less than or equal to"
        | "greater than"
        | "greater than or equal to"
      )[],
      failAction: "manual review" as "manual review" | "decline",
      failIf: undefined as
        | undefined
        | "less than"
        | "less than or equal to"
        | "greater than"
        | "greater than or equal to",
      ip: false,
      incomePeriodType: "monthly" as "monthly" | "annual",
      name: "",
      periodSelection: 1,
      periodType: "months" as "months" | "days",
      ruleId: undefined,
      ruleType: "",
      shortDescription: "",
      ssn: false as boolean | undefined,
      states: [{ stateCode: "all", checked: false }, ...stateSelection],
      threshold: 0,
      thresholdMarks: [] as { value: number; label: string }[],
      thresholdMaxValue: 100,
      thresholdMinValue: 0,
      thresholdStep: 1,
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .nullable()
        .test(
          "check-name",
          "Rule name is required",
          (value: string, context) => {
            if (context.parent.ruleType !== "state" && !value) {
              return false;
            }

            return true;
          }
        ),
      // the validation bellow also applies for ssn, address and email
      ip: yup
        .boolean()
        .test(
          "check-ip",
          "No rules threshold selected",
          (value: boolean, context) => {
            if (context.parent.ruleType !== "duplicate application")
              return true;
            else if (
              context.parent.ip ||
              context.parent.ssn ||
              context.parent.address ||
              context.parent.email
            ) {
              return true;
            }
            return false;
          }
        ),
      failIf: yup
        .string()
        .nullable()
        .test(
          "check-fail-if",
          "Fail condition is required",
          (value: string, context) => {
            if (
              (formik.values.ruleType === "income" ||
                formik.values.ruleType === "age check" ||
                formik.values.ruleType === "reapply limit") &&
              !value
            ) {
              return false;
            }

            return true;
          }
        ),
      states: yup
        .array()
        .of(
          yup.object({
            stateCode: yup.string(),
            checked: yup.boolean(),
          })
        )
        .nullable()
        .test(
          "check-states",
          "At least one state must be selected",
          (value, context) => {
            if (context.parent.ruleType !== "state") {
              return true;
            } else if (context.parent.states.some((state) => state.checked)) {
              return true;
            }

            return false;
          }
        ),
    }),

    onSubmit: async (values) => {
      try {
        dispatch(setIsLoading(true));

        const { ruleId } = values;
        const requestBody: any = filterRequestBody(values);
        if (values.ruleId) {
          if (isMerchant) {
            await updateMerchantPreliminaryCheckRule(ruleId, requestBody);
          } else {
            await updatePlatformPreliminaryCheckRule(ruleId, requestBody);
          }
        } else if (isMerchant) {
          await addMerchantPreliminaryRule(requestBody);
        } else {
          await addPlatformPreliminaryRule(requestBody, productId);
        }

        await loadState();

        setOpenRuleModal(false);
        formik.resetForm();
        dispatch(
          showNotification({
            message: `Rule ${values.ruleId ? "updated" : "added"} successfully`,
            severity: "success",
            shouldNotify: true,
          })
        );

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    },
  });

  const onRuleTemplateClick = async (ruleTemplate: RuleTemplate) => {
    if (
      (isMerchant && !enableMerchantRules) ||
      ruleTemplate.ruleType === ruleTemplates.bannedIp.ruleType ||
      ruleTemplate.ruleType === ruleTemplates.bannedAddress.ruleType ||
      (ruleTemplate.ruleType === ruleTemplates.state.ruleType &&
        rowsData.filter((row) => row.ruleType === "state").length > 0)
    ) {
      return;
    }

    await formik.setValues(
      {
        ...formik.values,
        ...ruleTemplate,
      },
      false
    );
    onModalOpen();
  };
  const onCreateNewRuleClick = async () => {
    formik.resetForm();
    setOpenRuleModal(true);
  };
  const onRuleTypeClick = async (ruleTemplate: RuleTemplate) => {
    formik.resetForm();
    await formik.setValues(
      {
        ...formik.values,
        ...ruleTemplate,
        failAction: "manual review",
        failIf: undefined,
        periodType: "months",
        states: [{ stateCode: "all", checked: false }, ...stateSelection],
      },
      false
    );
  };
  const onRuleTimeframeClick = async (timeframe: "months" | "days") => {
    await formik.setValues(
      {
        ...formik.values,
        periodType: timeframe,
        periodSelection: 1,
      },
      true
    );
  };
  const onIncomeTimeframeClick = async (
    incomePeriodType: "monthly" | "annual"
  ) => {
    if (incomePeriodType === "annual") {
      await formik.setValues(
        {
          ...formik.values,
          incomePeriodType,
          threshold: 5000,
          thresholdMaxValue: 300000,
          thresholdStep: 5000,
          thresholdMarks: [
            { value: 0, label: "$0" },
            { value: 300000, label: "$300,000" },
          ],
        },
        true
      );
    } else {
      await formik.setValues(
        {
          ...formik.values,
          incomePeriodType,
          threshold: 500,
          thresholdMaxValue: 25000,
          thresholdStep: 500,
          thresholdMarks: [
            { value: 0, label: "$0" },
            { value: 25000, label: "$25,000" },
          ],
        },
        true
      );
    }
  };
  const onFailIfClick = async (
    failIfCondition:
      | "less than"
      | "less than or equal to"
      | "greater than"
      | "greater than or equal to"
  ) => {
    await formik.setFieldValue("failIf", failIfCondition, true);
  };
  const onModalOpen = () => {
    setOpenRuleModal(true);
  };
  const onModalClose = async () => {
    setOpenRuleModal(false);
    await formik.setFieldValue("ruleId", undefined, false);
  };
  const onViewAllStatesOpen = (states: Record<string, any>[]) => {
    setStates(states);
    setViewAllStates(true);
  };
  const onViewAllStatesClose = () => {
    setViewAllStates(false);
    setStates([]);
  };
  const loadState = async () => {
    if (isMerchant) {
      const { data } = await getMerchantPreliminaryRules();
      setRuleColumns([
        {
          field: "createdBy",
          headerName: "Created By",
          width: 200,
          renderCell: ({ formattedValue }) => `${capitalize(formattedValue)}`,
        },
        ...baseRuleColumns,
      ]);
      setRowsData(data);
    } else if (productId) {
      const { data } = await getPlatformPreliminaryCheckRules(productId);
      setRowsData(data);
    }
  };
  const onActiveChange = async (ruleId: string, active: boolean) => {
    try {
      dispatch(setIsLoading(true));
      const requestBody = {
        active,
      };
      if (isMerchant) {
        await updateMerchantPreliminaryCheckRule(ruleId, requestBody);
      } else {
        await updatePlatformPreliminaryCheckRule(ruleId, requestBody);
      }

      await loadState();

      dispatch(
        showNotification({
          message: "Rule updated successfully",
          severity: "success",
          shouldNotify: true,
        })
      );

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = handleError(error, navigate, true);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }

      dispatch(setIsLoading(false));
    }
  };
  const onStateSelectionChange = async (checked: boolean, index: number) => {
    if (index === 0) {
      // Make all checkboxes checked/unchecked

      const stateSelectionAll = stateSelection.map((state) => ({
        ...state,
        checked: checked,
      }));

      await formik.setFieldValue(
        "states",
        [{ stateCode: "all", checked: checked }, ...stateSelectionAll],
        true
      );
    } else {
      await formik.setFieldValue(`states.${index}.checked`, checked, true);
    }
  };

  useEffect(() => {
    const allChecked = formik.values.states.every((state, index) =>
      index !== 0 ? state.checked : true
    );
    formik.setFieldValue(`states[0].checked`, allChecked, true);
  }, [formik.values.states]);

  const onEditRuleClick = async (
    rule: PlatformPreliminaryCheckRules & {
      createdBy: string;
      platform?: Record<string, any>;
      merchant?: Record<string, any>;
    }
  ) => {
    const { id, createdBy, merchant, platform, ...other } = rule;

    const ruleTemplate = Object.values(ruleTemplates).find(({ ruleType }) => {
      return ruleType === other.ruleType;
    });
    await formik.setValues(
      { ...formik.values, ...ruleTemplate, ...other, ruleId: id },
      true
    );
    setOpenRuleModal(true);
  };
  const onDeleteRuleOpen = async (
    rule: PlatformPreliminaryCheckRules & {
      createdBy: string;
      platform?: Record<string, any>;
      merchant?: Record<string, any>;
    }
  ) => {
    const { id, createdBy, merchant, platform, ...other } = rule;

    const ruleTemplate = Object.values(ruleTemplates).find(({ ruleType }) => {
      return ruleType === other.ruleType;
    });
    await formik.setValues(
      { ...formik.values, ...ruleTemplate, ...other, ruleId: id },
      false
    );
    setOpenDeleteRuleModal(true);
  };
  const onDeleteRuleClose = async () => {
    formik.resetForm();
    setOpenDeleteRuleModal(false);
  };
  const onDeleteRuleConfirm = async () => {
    try {
      dispatch(setIsLoading(true));

      if (isMerchant) {
        await deleteMerchantPreliminaryCheckRule(formik.values.ruleId);
      } else {
        await deletePlatformPreliminaryCheckRule(formik.values.ruleId);
      }

      await loadState();

      setOpenDeleteRuleModal(false);
      formik.resetForm();
      dispatch(
        showNotification({
          message: "Rule deleted successfully",
          severity: "success",
          shouldNotify: true,
        })
      );

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = handleError(error, navigate);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }

      dispatch(setIsLoading(false));
    }
  };

  const filterRequestBody = (requestBody: Record<string, any>) => {
    const {
      address,
      displayTimeframe,
      email,
      enabledOperators,
      incomePeriodType,
      ip,
      periodSelection,
      periodType,
      ruleId,
      ruleType,
      shortDescription,
      ssn,
      states,
      thresholdMarks,
      thresholdMaxValue,
      thresholdMinValue,
      thresholdStep,
      bannedIpFile,
      bannedAddressFile,
      name,
      failIf,
      threshold,
      ...other
    } = requestBody;

    let filteredRequestBody: any = {
      ruleType,
    };
    if (ruleType === "income") {
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        incomePeriodType,
        name,
        failIf,
        threshold,
      };
    } else if (ruleType === "duplicate application") {
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        address,
        email,
        ip,
        name,
        periodSelection,
        periodType,
        ssn,
      };
    } else if (ruleType === "reapply limit") {
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        periodSelection,
        periodType,
        name,
        failIf,
        threshold,
      };
    } else if (ruleType === "state") {
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        states,
        name,
      };
    } else {
      // age check
      filteredRequestBody = {
        ...filteredRequestBody,
        ...other,
        name,
        failIf,
        threshold,
      };
    }

    return filteredRequestBody;
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));

        if (isMerchant) {
          const { data } = await getMerchantByJWT();
          setEnableMerchantRules(
            (data.creditReportSettings as CreditReportSettings)
              .enableAdditionalMerchantUnderwritingRules
          );
        }

        await loadState();

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableMerchantRules]);

  const render = (
    <Box>
      <Box>
        <Typography variant="h5">
          <strong>Preliminary Check Rule Templates</strong>
        </Typography>
      </Box>

      <Grid container rowSpacing={1} columnSpacing={2}>
        {Object.values(ruleTemplates).map((template) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={template.ruleType}>
            <Paper
              onClick={() => onRuleTemplateClick(template)}
              sx={{
                mt: 1.375,
                cursor:
                  (isMerchant && !enableMerchantRules) ||
                  template.ruleType === "banned ip" ||
                  template.ruleType === "banned address" ||
                  (template.ruleType === "state" &&
                    rowsData.filter((row) => row.ruleType === "state").length >
                      0)
                    ? ""
                    : "pointer",
                ...(((isMerchant && !enableMerchantRules) ||
                  template.ruleType === "banned ip" ||
                  template.ruleType === "banned address" ||
                  (template.ruleType === "state" &&
                    rowsData.filter((row) => row.ruleType === "state").length >
                      0)) && {
                  backgroundColor: grayPaperBackground,
                }),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 1,
                  minWidth: "8rem",
                  minHeight: "8rem",
                }}
              >
                {/* <Box
                  sx={{
                    minWidth: "7.375rem",
                    minHeight: "7.375rem",
                    backgroundImage: `url(${imageTemplate})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    borderRadius: "20px",
                    mr: 1,
                  }}
                ></Box> */}
                <Box
                  sx={{
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  <Typography>
                    <strong>{capitalize(template.ruleType)}</strong>
                  </Typography>
                  <Typography>{template.shortDescription}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {rowsData?.length <= 0 ? (
        <Typography variant="h5" sx={{ mt: 7, mb: 2 }}>
          <strong>Preliminary Check Rules</strong>
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ mt: 7, mb: 2 }}>
            <strong>Preliminary Check Rules</strong>
          </Typography>
          <Button
            variant="contained"
            onClick={onCreateNewRuleClick}
            disabled={isMerchant && !enableMerchantRules}
            startIcon={<AddIcon />}
            sx={{ mt: 4 }}
          >
            Create New Preliminary Check Rule
          </Button>
        </Box>
      )}

      {rowsData?.length <= 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 5.5 }}>
          <Box sx={{ textAlign: "center" }}>
            <AdvertisingIcon
              sx={{ width: "2.5627.375rem", height: "2.5627.375rem" }}
            />
            <Typography sx={{ mt: 2.466 }}>
              <strong>No Preliminary check rules found</strong>
            </Typography>
            <Typography sx={{ color: "#667085" }}>
              Get some data here by pressing add rules button
            </Typography>

            <Button
              variant="contained"
              onClick={onCreateNewRuleClick}
              disabled={isMerchant && !enableMerchantRules}
              startIcon={<AddIcon />}
              sx={{ mt: 4 }}
            >
              Create New Preliminary Check Rule
            </Button>
          </Box>
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <DataGrid
              columns={ruleColumns}
              rows={rowsData}
              autoHeight
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              }}
              hideFooter
            />
          </Grid>
        </Grid>
      )}

      <Modal
        open={openRuleModal}
        onClose={onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openRuleModal}>
          <Box
            sx={{
              ...modalStyles,
              height: formik.values.ruleType ? "90%" : "auto",
              width: "60%",
              overflowY: "auto",
            }}
          >
            <form onSubmit={formik.handleSubmit} noValidate>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                <strong>Create New Preliminary Check Rule</strong>
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  mt: 3.71,
                }}
              >
                {Object.values(ruleTemplates).map((template: RuleTemplate) => (
                  <Button
                    key={template.ruleType}
                    variant={
                      formik.values.ruleType === template.ruleType
                        ? "contained"
                        : "outlined"
                    }
                    startIcon={
                      formik.values.ruleType === template.ruleType && (
                        <CheckIcon />
                      )
                    }
                    onClick={() => onRuleTypeClick(template)}
                    disabled={
                      template.ruleType === ruleTemplates.bannedIp.ruleType ||
                      template.ruleType ===
                        ruleTemplates.bannedAddress.ruleType ||
                      (template.ruleType === ruleTemplates.state.ruleType &&
                        rowsData.filter(
                          (row) => row.ruleType === ruleTemplates.state.ruleType
                        ).length > 0)
                    }
                    sx={{ mt: 1 }}
                  >
                    {capitalize(template.ruleType)}
                  </Button>
                ))}
              </Box>

              {formik.values.ruleType ? (
                <Box sx={{ ml: 4 }}>
                  <Box sx={{ mt: 7 }}>
                    <Typography variant="h6">
                      <strong>{capitalize(formik.values.ruleType)}</strong>
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      my: 4,
                    }}
                  >
                    <TextField
                      sx={{ mr: 2 }}
                      variant="outlined"
                      label="NAME"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{
                        maxLength: 100,
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      fullWidth
                      required
                    />
                    <FormControl fullWidth required>
                      <InputLabel id="fail-action">FAIL ACTION</InputLabel>
                      <Select
                        labelId="fail-action"
                        label="FAIL ACTION"
                        name="failAction"
                        value={formik.values.failAction}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.failAction &&
                          Boolean(formik.errors.failAction)
                        }
                      >
                        <MenuItem value="manual review">Manual Review</MenuItem>
                        <MenuItem value="decline">Decline</MenuItem>
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {formik.touched.failAction && formik.errors.failAction}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  {formik.values.displayTimeframe && (
                    <>
                      <hr style={{ color: "rgba(0, 0, 0, 0.12)" }} />
                      <Box sx={{ mt: 2.5 }}>
                        <Typography variant="h6">
                          <strong>Rules Timeframe</strong>
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <Typography sx={{ mr: 5.25 }}>Period Type</Typography>
                          {formik.values.ruleType ===
                          ruleTemplates.income.ruleType ? (
                            <>
                              <Button
                                variant={
                                  formik.values.incomePeriodType === "monthly"
                                    ? "contained"
                                    : "outlined"
                                }
                                startIcon={
                                  formik.values.incomePeriodType ===
                                    "monthly" && <CheckIcon />
                                }
                                onClick={() =>
                                  onIncomeTimeframeClick("monthly")
                                }
                                sx={{ mr: 2 }}
                              >
                                Monthly
                              </Button>
                              <Button
                                variant={
                                  formik.values.incomePeriodType === "annual"
                                    ? "contained"
                                    : "outlined"
                                }
                                startIcon={
                                  formik.values.incomePeriodType ===
                                    "annual" && <CheckIcon />
                                }
                                onClick={() => onIncomeTimeframeClick("annual")}
                              >
                                Annual
                              </Button>
                            </>
                          ) : (
                            <>
                              {(formik.values.ruleType === "reapply limit" ||
                                formik.values.ruleType ===
                                  "duplicate application") && (
                                <Button
                                  variant={
                                    formik.values.periodType === "days"
                                      ? "contained"
                                      : "outlined"
                                  }
                                  startIcon={
                                    formik.values.periodType === "days" && (
                                      <CheckIcon />
                                    )
                                  }
                                  onClick={() => onRuleTimeframeClick("days")}
                                  sx={{ mr: 2 }}
                                >
                                  Days
                                </Button>
                              )}
                              <Button
                                variant={
                                  formik.values.periodType === "months"
                                    ? "contained"
                                    : "outlined"
                                }
                                startIcon={
                                  formik.values.periodType === "months" && (
                                    <CheckIcon />
                                  )
                                }
                                onClick={() => onRuleTimeframeClick("months")}
                              >
                                Months
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>

                      {formik.values.ruleType !==
                        ruleTemplates.income.ruleType && (
                        <Box
                          sx={{ display: "flex", alignItems: "center", my: 4 }}
                        >
                          <Typography sx={{ minWidth: "120px", mr: 2 }}>
                            Period Selection
                          </Typography>
                          <Slider
                            valueLabelDisplay="on"
                            name="periodSelection"
                            min={1}
                            max={
                              formik.values.periodType === "months" ? 84 : 365
                            }
                            marks={[
                              {
                                value: 1,
                                label:
                                  formik.values.periodType === "months"
                                    ? "1 month"
                                    : "1 day",
                              },
                              {
                                value:
                                  formik.values.periodType === "months"
                                    ? 84
                                    : 365,
                                label:
                                  formik.values.periodType === "months"
                                    ? "84 months"
                                    : "365 days",
                              },
                            ]}
                            value={formik.values.periodSelection}
                            onChange={formik.handleChange}
                            sx={{ mt: 1 }}
                          />
                        </Box>
                      )}

                      <Typography
                        sx={{ color: "#d32f2f", textAlign: "center", my: 2 }}
                      >
                        {formik.touched.periodType && formik.errors.periodType}
                      </Typography>
                    </>
                  )}

                  <hr style={{ color: "rgba(0, 0, 0, 0.12)" }} />

                  <Box sx={{ mt: 2.5 }}>
                    <Typography variant="h6">
                      <strong>
                        {(formik.values.ruleType === "income" ||
                          formik.values.ruleType === "duplicate application" ||
                          formik.values.ruleType === "age check" ||
                          formik.values.ruleType === "reapply limit") &&
                          "Rules Threshold"}
                        {(formik.values.ruleType === "banned ip" ||
                          formik.values.ruleType === "banned address") &&
                          "File Upload"}
                        {formik.values.ruleType === "state" &&
                          "State Selection"}
                      </strong>
                    </Typography>

                    {formik.values.ruleType === "duplicate application" && (
                      <FormGroup>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="ip"
                                checked={formik.values.ip}
                                onChange={formik.handleChange}
                              />
                            }
                            label="IP"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="ssn"
                                checked={formik.values.ssn}
                                onChange={formik.handleChange}
                              />
                            }
                            label="SSN"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="address"
                                checked={formik.values.address}
                                onChange={formik.handleChange}
                              />
                            }
                            label="Address"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="email"
                                checked={formik.values.email}
                                onChange={formik.handleChange}
                              />
                            }
                            label="Email"
                          />
                        </Box>
                      </FormGroup>
                    )}

                    {(formik.values.ruleType === "income" ||
                      formik.values.ruleType === "age check" ||
                      formik.values.ruleType === "reapply limit") && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography sx={{ mr: 5.25, mt: 2 }}>
                          Fail If
                        </Typography>
                        <Button
                          variant={
                            formik.values.failIf === "less than"
                              ? "contained"
                              : "outlined"
                          }
                          startIcon={
                            formik.values.failIf === "less than" && (
                              <CheckIcon />
                            )
                          }
                          onClick={() => onFailIfClick("less than")}
                          disabled={
                            formik.values.enabledOperators.indexOf(
                              "less than"
                            ) === -1
                          }
                          sx={{ mr: 2, mt: 2 }}
                        >
                          Less Than
                        </Button>
                        <Button
                          variant={
                            formik.values.failIf === "less than or equal to"
                              ? "contained"
                              : "outlined"
                          }
                          startIcon={
                            formik.values.failIf ===
                              "less than or equal to" && <CheckIcon />
                          }
                          onClick={() => onFailIfClick("less than or equal to")}
                          disabled={
                            formik.values.enabledOperators.indexOf(
                              "less than or equal to"
                            ) === -1
                          }
                          sx={{ mr: 2, mt: 2 }}
                        >
                          Less Than Or Equal To
                        </Button>
                        <Button
                          variant={
                            formik.values.failIf === "greater than"
                              ? "contained"
                              : "outlined"
                          }
                          startIcon={
                            formik.values.failIf === "greater than" && (
                              <CheckIcon />
                            )
                          }
                          onClick={() => onFailIfClick("greater than")}
                          disabled={
                            formik.values.enabledOperators.indexOf(
                              "greater than"
                            ) === -1
                          }
                          sx={{ mr: 2, mt: 2 }}
                        >
                          Greater Than
                        </Button>
                        <Button
                          variant={
                            formik.values.failIf === "greater than or equal to"
                              ? "contained"
                              : "outlined"
                          }
                          startIcon={
                            formik.values.failIf ===
                              "greater than or equal to" && <CheckIcon />
                          }
                          onClick={() =>
                            onFailIfClick("greater than or equal to")
                          }
                          disabled={
                            formik.values.enabledOperators.indexOf(
                              "greater than or equal to"
                            ) === -1
                          }
                          sx={{ mr: 2, mt: 2 }}
                        >
                          Greater Than Or Equal To
                        </Button>
                      </Box>
                    )}

                    {(formik.values.ruleType === "income" ||
                      formik.values.ruleType === "age check" ||
                      formik.values.ruleType === "reapply limit") && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", my: 4 }}
                      >
                        <Typography sx={{ minWidth: "80px", mr: 2 }}>
                          Threshold
                        </Typography>
                        <Slider
                          valueLabelDisplay="on"
                          name="threshold"
                          min={formik.values.thresholdMinValue}
                          max={formik.values.thresholdMaxValue}
                          step={formik.values.thresholdStep}
                          marks={formik.values.thresholdMarks}
                          value={formik.values.threshold}
                          onChange={formik.handleChange}
                          sx={{ mt: 1 }}
                        />
                      </Box>
                    )}

                    {(formik.values.ruleType === "banned ip" ||
                      formik.values.ruleType === "banned address") && (
                      <Box sx={{ mt: 2, mb: 3 }}>
                        <FileUpload
                          accept=".csv"
                          value={files}
                          onChange={setFiles}
                        />
                      </Box>
                    )}

                    {formik.values.ruleType === "state" && (
                      <Box>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={`states.0.stateCode`}
                                checked={formik.values.states[0].checked}
                                onChange={(event) =>
                                  onStateSelectionChange(
                                    event.target.checked,
                                    0
                                  )
                                }
                              />
                            }
                            label="ALL STATES"
                          />
                          <Grid container>
                            {stateSelection.map((state, index: number) => (
                              <Grid
                                item
                                xs={3}
                                md={1.5}
                                lg={1}
                                xl={0.8}
                                key={state.stateCode}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={`states.${index + 1}.stateCode`}
                                      checked={
                                        formik.values.states[index + 1].checked
                                      }
                                      onChange={(event) =>
                                        onStateSelectionChange(
                                          event.target.checked,
                                          index + 1
                                        )
                                      }
                                    />
                                  }
                                  label={state.stateCode}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </FormGroup>
                      </Box>
                    )}
                  </Box>

                  <Typography
                    sx={{ color: "#d32f2f", textAlign: "center", mb: 2 }}
                  >
                    {formik.touched.failIf && formik.errors.failIf}
                    {formik.touched.states && formik.errors.states}
                    {((formik.touched.ip && formik.errors.ip) ||
                      (formik.touched.ssn && formik.errors.ssn) ||
                      (formik.touched.address && formik.errors.address) ||
                      (formik.touched.email && formik.errors.email)) &&
                      "At least one rules threshold must be selected"}
                  </Typography>

                  <hr style={{ color: "rgba(0, 0, 0, 0.12)" }} />

                  <Box>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      <strong>Rules Outcome</strong>
                    </Typography>
                    <FormGroup sx={{ mt: 1.5 }}>
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              name="active"
                              onChange={formik.handleChange}
                              checked={formik.values.active}
                              disabled={formik.values.ruleType === "state"}
                            />
                          }
                          label="Active"
                          sx={{ mr: 1 }}
                        />
                        <Tooltip title="Active">
                          <IconButton size="small">
                            <HelpOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              name="bypass"
                              onChange={formik.handleChange}
                              checked={formik.values.bypass}
                            />
                          }
                          label="Bypass"
                          sx={{ mr: 1 }}
                        />
                        <Tooltip title="Bypassed rule will not stop the application, it will trigger a manual review at the end of the application">
                          <IconButton size="small">
                            <HelpOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </FormGroup>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 6,
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={onModalClose}
                      sx={{ mr: 4 }}
                    >
                      Go Back
                    </Button>
                    <Button type="submit" variant="contained">
                      {formik.values.ruleId ? "Update Rule" : "Save New Rule"}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
                  <Button
                    variant="outlined"
                    onClick={onModalClose}
                    sx={{ mr: 4 }}
                  >
                    Go Back
                  </Button>
                </Box>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>

      {openDeleteRuleModal && (
        <Modal
          open={openDeleteRuleModal}
          onClose={onDeleteRuleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDeleteRuleModal}>
            <Box sx={{ ...modalStyles }}>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Are you sure you want to delete the rule "
                {capitalize(formik.values.name)}"?
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
                <Button
                  variant="outlined"
                  onClick={onDeleteRuleClose}
                  sx={{ mr: 4 }}
                >
                  Cancel
                </Button>
                <Button variant="contained" onClick={onDeleteRuleConfirm}>
                  Confirm
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}

      {viwAllStates && (
        <Modal
          open={viwAllStates}
          onClose={onDeleteRuleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={viwAllStates}>
            <Box sx={{ ...modalStyles }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                <strong>State Selection</strong>
              </Typography>
              <Box sx={{ mt: 4, justifyContent: "center", flexWrap: "wrap" }}>
                {states.map((state) => (
                  <Chip
                    key={state.stateCode}
                    label={state.stateCode}
                    sx={{ mr: 1, mt: 1 }}
                  />
                ))}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
                <Button variant="contained" onClick={onViewAllStatesClose}>
                  Close
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
    </Box>
  );

  return render;
};

export default PreliminaryCheckRules;
