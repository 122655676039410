import { useState, useEffect, ChangeEvent, SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Switch,
  Modal,
  Fade,
  Backdrop,
  Tab,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import handleError from "../../../global-helpers/handleError";
import capitalize from "../../../global-helpers/capitalize";
import { setIsLoading } from "../../../global-store/loadingSlice";
import { showNotification } from "../../../global-store/notificationSlice";
import { useAppDispatch } from "../../../global-store/hooks";
import {
  changeActive,
  changeDefault,
  getAllProducts,
  deleteProduct,
  cloneProduct,
} from "../api";
import getAdminJWTData from "../../../global-helpers/getAdminJWTData";
import { getPlatFormById } from "../../../global-api";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Rules from "../../settings/pages/Rules";
import modalStyles from "../../../global-helpers/modalStyles";
import Confirm from "../../../global-components/modals/confirm/confirm";
import SearchField from "../../../global-components/inputs/SearchField";
import Information from "../components/Information";
import IdentityKyc from "../components/IdentityKyc";
import { OfferEngineTabs } from "../../settings/components/tabs/OfferEngineTabs";
import PaymentSettings from "../components/PaymentSettings";

const ManageProducts = () => {
  const [rowsData, setRowsData] = useState<{
    products: Record<string, any>[];
    total: number;
  }>({ products: [], total: 0 });
  const [page, setPage] = useState(0);
  const [searchedWord, setSearchedWord] = useState<string>("");
  const adminData = getAdminJWTData();
  const [openModal, setOpenModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmClone, setConfirmClone] = useState(false);
  const [productId, setProductId] = useState("");
  const [deEnabled, setDeEnabled] = useState(false);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const [activeTab, setActiveTab] = useState("basic-information");

  const onTabChange = (_event: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };
  const onModalClose = async () => {
    setOpenModal(false);
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Action",
      width: 350,
      renderCell: ({ formattedValue, row }) => (
        <Box sx={{ px: 0.75 }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setProductId(formattedValue);
              setConfirmClone(true);
            }}
            startIcon={<ContentCopyIcon />}
            sx={{ mr: 1 }}
          >
            Clone
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenModal(true);
              setProductId(formattedValue);
            }}
            startIcon={<EditIcon />}
            sx={{ mr: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            disabled={
              rowsData.products.filter(
                (prod) => prod.productType === row.productType
              ).length === 1
            }
            onClick={() => {
              setProductId(formattedValue);
              setConfirmDelete(true);
            }}
            startIcon={<DeleteIcon />}
            sx={{ mr: 1 }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
    {
      field: "default",
      headerName: "Default",
      width: 100,
      renderCell: ({ formattedValue, id }) => (
        <>
          <Switch
            disabled={adminData.role !== "Super Admin"}
            onChange={() => changeDefaultProduct(id, formattedValue)}
            checked={formattedValue}
          />
        </>
      ),
    },
    {
      field: "active",
      headerName: "Status",
      width: 100,
      renderCell: ({ formattedValue, id, row }) => (
        <>
          <Switch
            disabled={
              adminData.role !== "Super Admin" ||
              rowsData.products.filter(
                (prod) => prod.productType === row.productType
              ).length === 1
            }
            onChange={() => changeActiveProduct(id, formattedValue)}
            checked={formattedValue}
          />
        </>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    {
      field: "productType",
      headerName: "Product Type",
      width: 200,
      renderCell: ({ formattedValue }) => capitalize(formattedValue),
    },
    // {
    //   field: "productCategory",
    //   headerName: "Product Category",
    //   width: 200,
    //   renderCell: ({ formattedValue }) => capitalize(formattedValue),
    // },
  ];
  const deleteProductProfile = async () => {
    let deleteResult = await deleteProduct(productId);
    if (deleteResult.status === 204) {
      dispatch(
        showNotification({
          message: `Product profile deleted successfully`,
          severity: "success",
          shouldNotify: true,
        })
      );
    } else {
      dispatch(
        showNotification({
          message: `Error deleting Product Profile, try again later`,
          severity: "error",
          shouldNotify: true,
        })
      );
    }
    setConfirmDelete(false);
    onSearchSubmit();
  };
  const handleCloneProduct = async () => {
    try {
      dispatch(setIsLoading(true));
      await cloneProduct(productId);
      dispatch(
        showNotification({
          message: `Product Profile cloned successfully`,
          severity: "success",
          shouldNotify: true,
        })
      );
    } catch (error) {
      const errorMessage = handleError(error, navigate, true);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }
    } finally {
      setConfirmClone(false);
      await onSearchSubmit();
      dispatch(setIsLoading(false));
    }
  };
  const onSearchChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setSearchedWord(event.target.value);
  };
  const onSearchSubmit = async (): Promise<void> => {
    try {
      dispatch(setIsLoading(true));

      const requestBody = {
        page: page + 1,
        perPage: 20,
        searchTerms: searchedWord,
      };
      const { data: products } = await getAllProducts(requestBody);
      setRowsData(products);

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = handleError(error, navigate, true);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }

      dispatch(setIsLoading(false));
    }
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const changeActiveProduct = async (productId: any, value: any) => {
    try {
      await changeActive(productId);
      const requestBody = {
        page: page + 1,
        perPage: 20,
      };
      const { data: products } = await getAllProducts(requestBody);
      setRowsData(products);
    } catch (error) {
      const errorMessage = handleError(error, navigate, true);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }
    }
  };

  const changeDefaultProduct = async (productId: any, value: any) => {
    try {
      await changeDefault(productId);
      const requestBody = {
        page: page + 1,
        perPage: 20,
      };
      const { data: products } = await getAllProducts(requestBody);
      setRowsData(products);
    } catch (error) {
      const errorMessage = handleError(error, navigate, true);
      if (errorMessage) {
        dispatch(
          showNotification({
            message: errorMessage,
            severity: "error",
            shouldNotify: true,
          })
        );
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(setIsLoading(true));

        const requestBody = {
          page: page + 1,
          perPage: 20,
        };

        const { data: platform } = await getPlatFormById(adminData.platform);
        setDeEnabled(platform.decisionEngine);

        const { data: products } = await getAllProducts(requestBody);
        setRowsData(products);

        dispatch(setIsLoading(false));
      } catch (error) {
        const errorMessage = handleError(error, navigate, true);
        if (errorMessage) {
          dispatch(
            showNotification({
              message: errorMessage,
              severity: "error",
              shouldNotify: true,
            })
          );
        }

        dispatch(setIsLoading(false));
      }
    })();
  }, [dispatch, navigate, page]);

  const render = (
    <>
      <Box sx={{ mt: 6, mx: 4, mb: 4 }}>
        <Typography variant="h4">
          <strong>Manage Products</strong>
        </Typography>
        <Paper sx={{ mt: 6 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mx: 2,
                  mt: 1,
                }}
              >
                <Box>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenModal(true);
                      setProductId(null);
                      setActiveTab("basic-information");
                    }}
                    startIcon={<AddIcon />}
                  >
                    Add Product
                  </Button>
                </Box>
                <SearchField
                  onChange={onSearchChange}
                  onSubmit={onSearchSubmit}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                columns={columns}
                rows={rowsData.products}
                rowCount={rowsData.total}
                pageSize={20}
                rowsPerPageOptions={[20]}
                onPageChange={(newPage: number) => setPage(newPage)}
                autoHeight
                pagination
                paginationMode="server"
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Confirm
        open={confirmDelete}
        title="Delete Product Profile"
        description="Are you sure?"
        onConfirm={deleteProductProfile}
        onCancel={() => setConfirmDelete(false)}
      />

      <Confirm
        open={confirmClone}
        title="Clone Product Profile"
        description="Are you sure?"
        onConfirm={handleCloneProduct}
        onCancel={() => setConfirmClone(false)}
      />

      <Modal
        open={openModal}
        onClose={onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              ...modalStyles,
              height: "90%",
              width: "80%",
              overflowY: "auto",
            }}
          >
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={onTabChange}
                  variant={isLargeScreen ? "standard" : "scrollable"}
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Basic information" value="basic-information" />
                  {!!productId && (
                    <Tab
                      disabled={!deEnabled}
                      label="Decision Engine"
                      value="decision-engine"
                    />
                  )}
                  {!!productId && (
                    <Tab label="Offer Engine" value="offer-engine" />
                  )}
                  {!!productId && (
                    <Tab label="Identity / KYC" value="identity-kyc" />
                  )}
                  {!!productId && (
                    <Tab label="Payments" value="payment-settings" />
                  )}
                </TabList>
              </Box>
              <TabPanel value="basic-information">
                <Information
                  onGoBack={() => setOpenModal(false)}
                  refreshList={() => onSearchSubmit()}
                  productId={productId}
                />
              </TabPanel>
              {!!productId && (
                <>
                  <TabPanel value="decision-engine">
                    {deEnabled ? (
                      <Rules productId={productId} />
                    ) : (
                      <Typography style={{ textAlign: "center" }}>
                        Decision Engine Disabled{" "}
                      </Typography>
                    )}
                  </TabPanel>
                  <TabPanel value="offer-engine">
                    <OfferEngineTabs productId={productId} />
                  </TabPanel>
                  <TabPanel value="identity-kyc">
                    <IdentityKyc
                      productId={productId}
                      onGoBack={() => {
                        setOpenModal(false);
                      }}
                    />
                  </TabPanel>
                  <TabPanel value="payment-settings">
                    <PaymentSettings
                      productId={productId}
                      onGoBack={() => {
                        setOpenModal(false);
                      }}
                    />
                  </TabPanel>
                </>
              )}
            </TabContext>
          </Box>
        </Fade>
      </Modal>
    </>
  );

  return render;
};

export default ManageProducts;
